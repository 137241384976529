import { Grid } from "@mui/material";
import {
  INewSupplierAccount,
  ISupplierRules,
} from "../../interfaces/ISuppliersProfile";
import { Colors } from "../../themes";
import AvailableDays from "./AvailableDays";

function SupplierRulesGrid(props: {
  rules: ISupplierRules;
  supplier: INewSupplierAccount;
}) {
  return (
    <Grid item container xs={12} columnGap={1} rowGap={0} sx={styles.grid}>
      <AvailableDays rules={props.rules} />      
    </Grid>
  );
}

const styles = {
  grid: {
    backgroundColor: Colors.GRAY6,
    p: 1,
    pb: "29vh",
    minHeight: "70vh",
  },
};

export default SupplierRulesGrid;

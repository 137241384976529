import { Box } from "@mui/material";
import { LottieAnimation } from "../../components/atoms/wrappers/LottieAnimation";

type Props = {
  loading: boolean;
  orderTotal: number;
};

export const OrderTotalCost = (props: Props) => {
  const { loading, orderTotal } = props;

  const orderTotalText = `Subtotal: $ ${orderTotal.toFixed(2)} MXN`;

  if (loading) {
    return (
      <Box sx={styles.lottieContainer}>
        <LottieAnimation animationname={"Circles"} />
      </Box>
    );
  }

  return <Box sx={styles.cartTotalText}>{orderTotalText}</Box>;
};

const styles = {
  cartTotalText: {
    fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
    width: { xs: "40%", sm: "50%" },
    fontWeight: 600,
  },
  lottieContainer: {
    height: "3rem",
    width: { xs: "40%", sm: "50%" },
  },
};

import { Box, TextField } from "@mui/material";
import { ErrorMessage } from "./ErrorMessage";
import { Colors } from "../../themes";

export function SimpleTextInput(props: {
  title: string;
  value: string;
  setValue: (value: string) => void;
  isError?: boolean;
  errorMessage?: string;
  placeholder?: string;
}) {
  const { title, value, isError, errorMessage, placeholder, setValue } = props;

  const handleChange = (e: { target: { value: string } }) => {
    setValue(e.target.value);
  };

  return (
    <Box sx={styles.selectContainer}>
      <Box sx={styles.inputLabel}>{title}</Box>

      <TextField
        id={props.value}
        variant="outlined"
        value={value}
        sx={styles.inputStyle}
        onChange={handleChange}
        placeholder={placeholder}
      />
      <ErrorMessage isError={isError} message={errorMessage} />
    </Box>
  );
}

const styles = {
  selectContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    maxWidth: "100%",
  },
  inputStyle: {
    display: "flex",
    flex: 1,
    backgroundColor: Colors.GRAY6,
    borderRadius: 2,
    fontFamily: "Montserrat",
    fontSize: "0.9rem",
    fontWeight: 500,
    paddingLeft: "1rem",
    border: `1px solid ${Colors.P_DARK}`,
    lineHeight: "none",
    minHeight: "0",
    height: "3rem",
    maxHeight: "3rem",
    justifyContent: "center",
    "& input:-webkit-autofill": {
      WebkitBoxShadow: "0 transparent",
      boxShadow: "0 transparent",
      transition: "background-color 5000s ease-in-out 0s",
    },
  },

  inputLabel: {
    fontSize: {
      xs: "0.9rem",
      sm: "1rem",
    },
    marginBottom: "0.3rem",
  },
};

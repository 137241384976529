import { getToken } from "./auth.service";
import { IHttpService } from "./ihttp-service";

export class HttpService implements IHttpService {
  async get<TItem>(
    url: string,
    creator: () => TItem,
    name: string
  ): Promise<TItem> {
    const empty = creator();

    return fetch(url, {
      headers: {
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((res) => this.processResponse(res, empty, name))
      .then((res: TItem) => {
        return this.checkExistance(res, empty, name);
      })
      .catch(() => this.consoleError(name, empty));
  }

  async getWithToken<TItem>(
    url: string,
    creator: () => TItem,
    name: string
  ): Promise<TItem> {
    const empty = creator();
    const token = await getToken();

    if (token) {
      return fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "get",
      })
        .then((res) => this.processResponse(res, empty, name))
        .then((res: TItem) => this.checkExistance(res, empty, name))
        .catch(() => this.consoleError(name, empty));
    } else {
      console.error(name + " Token");
      return empty;
    }
  }

  createOptions = (method: string, requestBody: string) => {
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: requestBody,
      method: method,
    };
  };

  async post<TItem>(
    url: string,
    body: unknown,
    creator: () => TItem,
    name: string
  ): Promise<TItem> {
    const empty = creator();
    const requestBody = JSON.stringify(body);
    const options = () => {
      return {
        headers: {
          Accept: "*/*",
        },
        body: requestBody,
        method: "POST",
      };
    };

    return fetch(url, options())
      .then((res) => this.processResponse(res, empty, name))
      .then((res: TItem) => this.checkExistance(res, empty, name))
      .catch(() => this.consoleError(name, empty));
  }

  processResponse<TItem>(
    response: Response,
    error: TItem,
    name: string
  ): Promise<TItem> {
    if (response.status === 200 || response.status === 204) {
      return response.json() as Promise<TItem>;
    } else {
      console.error(`${name} : ${response.status}`);
      return Promise.resolve(error);
    }
  }

  private checkExistance<TItem>(
    response: TItem,
    empty: TItem,
    name: string
  ): TItem {
    if (response) {
      return response;
    }
    console.error(name + " Empty");
    console.error(JSON.stringify(response, null, 2));
    return empty;
  }

  private consoleError<TItem>(name: string, empty: TItem) {
    console.error(name + " Catch");
    return empty;
  }
}

import { Box, Button, Icon } from "@mui/material";
import { Colors } from "../../themes";
import styled from "@emotion/styled";

type Props = {
  handleOnAdd: () => void;
};

const ProductButtons = (props: Props) => {
  const { handleOnAdd } = props;

  const onClick = () => handleOnAdd();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        maxWidth: "100%",
      }}
    >
      <CustomAdd onClick={onClick}>
        <Icon fontSize="inherit">add_circle</Icon>
      </CustomAdd>
    </Box>
  );
};

const CustomAdd = styled(Button)(({ theme, color = "primary" }) => ({
  "&.MuiButton-root": {
    fontSize: 36,
    color: Colors.PRIMARYUSER,
  },
}));

export default ProductButtons;

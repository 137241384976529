import { geocodeByPlaceId } from "react-google-places-autocomplete";
import {
  IProductComplementItemsRes,
  IFormatedComplementItem,
  IServiceDate,
  IServiceDayConfigurationResponse,
  IServiceDayPeriod,
} from "../interfaces/IProducts";
import {
  ICartProductComplements,
  ICartProductComplementItem,
} from "../interfaces/IShoppingCart";
import {
  emptyDeliveryAddress,
  IDeliveryAddress,
} from "../interfaces/ICustomer";
import { format, isValid, isToday, isTomorrow, addDays } from "date-fns";
import { es } from "date-fns/locale";

export function formatProductComplements(complements: {
  [key: string]: IProductComplementItemsRes[];
}): ICartProductComplements[] {
  const configs: ICartProductComplements[] = [];

  Object.keys(complements).forEach((key) => {
    const comps: ICartProductComplementItem[] = complements[key].map((e) => {
      const newComp: ICartProductComplementItem = {
        id: e.id,
        complementItemName: e.name,
        itemPrice: e.price,
        currency: e.currency,
      };
      return newComp;
    });
    const newComplement: ICartProductComplements = {
      complementName: key,
      complementItems: comps,
    };
    configs.push(newComplement);
  });

  return configs;
}

export function formatComplementItemsList(
  items: ICartProductComplementItem[]
): IFormatedComplementItem[] {
  let formatedItems: IFormatedComplementItem[] = [];

  items.forEach((item) => {
    let qty = 1;
    const foundItem = formatedItems.find((e) => e.id === item.id);
    if (foundItem) {
      qty = foundItem.quantity + 1;
      formatedItems = formatedItems.filter((e) => e.id !== item.id);
    }
    const newItem: IFormatedComplementItem = {
      id: item.id,
      name: item.complementItemName,
      currency: item.currency,
      price: item.itemPrice,
      quantity: qty,
      total: item.itemPrice * qty,
    };
    formatedItems.push(newItem);
  });
  return formatedItems;
}

export function extractComplementItems(
  complementsGroup: ICartProductComplements[]
) {
  const complementItems: ICartProductComplementItem[] = [];

  complementsGroup.forEach((group) => {
    complementItems.push(...group.complementItems);
  });

  return complementItems;
}

export const getLatLonFromPlaceId = async (
  placeId: string
): Promise<IDeliveryAddress> => {
  try {
    const results = await geocodeByPlaceId(placeId);
    const address = results[0].formatted_address;
    const location = results[0].geometry.location;

    return {
      formatAddress: address,
      latitude: location.lat(),
      longitude: location.lng(),
    };
  } catch (error) {
    console.error(error);
    return emptyDeliveryAddress();
  }
};

export function distance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }

  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344;

  return Number.parseFloat(dist.toFixed(2));
}

export const searchLocation = (latitude: number, longitude: number) => {
  const searchUrl = `https://www.google.com/maps/search/?api=1&query=${latitude}
  ${longitude}`;

  window.open(searchUrl, "_blank", "noreferrer");
};

export const sendQuoteMessage = (supplierAlternativePhone: string) => {
  const message =
    "Hola, vi tu servicio en Fuddis y me gustaría hacer una cotización";

  const url = "https://wa.me/" + supplierAlternativePhone + "?text=" + message;

  window.open(url, "_blank", "noreferrer");
};

export const getReservationDates = (
  res: IServiceDayConfigurationResponse
): IServiceDate[] => {
  const today = new Date();
  const availableDays = res.periodsList
    .filter((e) => e.startHour !== e.endHour)
    .map((e) => e.day)
    .sort();

  const reservationDays: IServiceDate[] = [];

  for (let i = 0; i < 15; i++) {
    const dayDate = addDays(today, i);
    const dayIndex = dayDate.getDay();

    const serviceDay = res.periodsList.find((e) => e.day === dayIndex);

    if (availableDays.includes(dayIndex) && serviceDay) {
      const reservationDate = generateReservationDate(
        serviceDay,
        dayDate,
        dayIndex
      );

      reservationDays.push(reservationDate);
    }
  }

  return reservationDays;
};

const generateReservationDate = (
  serviceDay: IServiceDayPeriod,
  dayDate: Date,
  dayIndex: number
) => {
  const displayDate = formatStringDate(dayDate);
  const availableHours = generateAvailableHours(
    serviceDay.startHour,
    serviceDay.endHour
  );

  const newDate: IServiceDate = {
    anticipation: serviceDay.anticipation,
    day: dayIndex,
    displayDate: displayDate,
    endHour: serviceDay.endHour,
    startHour: serviceDay.startHour,
    availableHours,
  };

  if (isToday(dayDate)) {
    return generateNewDate(newDate, `Hoy, ${displayDate}`);
  } else if (isTomorrow(dayDate)) {
    return generateNewDate(newDate, `Mañana, ${displayDate}`);
  }

  return newDate;
};

export function formatStringDate(date: Date): string {
  let formattedDate = "";

  if (isValid(date)) {
    formattedDate = format(date, "PPPP", { locale: es });
  }

  return formattedDate;
}

const generateAvailableHours = (startHour: number, endHour: number) => {
  const numberOfHours = endHour - startHour;

  return Array.from({ length: numberOfHours }, (_, index) =>
    formatHourToAMPM(startHour + index)
  );
};

const generateNewDate = (date: IServiceDate, displayDate: string) => {
  return {
    ...date,
    displayDate,
  };
};

const formatHourToAMPM = (hour: number) => {
  const date = new Date();
  date.setHours(hour, 0);

  return format(date, "p aaa", { locale: es });
};

export function reservationMessage(params: {
  date: string;
  time: string;
  numberOfPeople: string;
  phoneNumber: string;
  supplierName: string;
  customerName: string;
}) {
  const {
    date,
    time,
    numberOfPeople,
    phoneNumber,
    supplierName,
    customerName,
  } = params;

  const helloText = `¡Hola *${supplierName}*! *Fuddis*`;
  const reservationText = `Quiero hacer una reservación contigo con los siguientes datos:`;
  const dateText = `*Fecha*: ${date}`;
  const timeText = `*Hora*: ${time}`;
  const numOfPeopleText = `*Número de personas*: ${numberOfPeople}`;
  const phoneNumText = `*Teléfono*: ${phoneNumber}`;
  const customerText = `*Nombre para la reservación*: ${customerName}`;

  const message = `${helloText}\n${reservationText}\n
  ${dateText}
  ${timeText}
  ${numOfPeopleText}
  ${phoneNumText}
  ${customerText}
  `;

  return message;
}

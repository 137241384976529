import { Colors } from "../../themes";
import { IFormatedRule } from "../../interfaces/ISuppliersProfile";
import { RiTimeLine } from "@remixicon/react";
import Status from "../SupplierRulesGrid/Status";

type Props = {
  rules: IFormatedRule[];
};

export function StatusInfo(props: Readonly<Props>) {
  return (
    <div className="flex items-center gap-2">
      <RiTimeLine size={24} color={Colors.TEXT} />
      <Status formattedRule={props.rules} />
    </div>
  );
}

import Lottie, { LottieComponentProps } from "lottie-react";
import Circles from "../../../assets/lottie/Circles.json";

type BaseProps = Pick<
  LottieComponentProps,
  Exclude<keyof LottieComponentProps, "animationData">
>;

export interface LottieAnimationProps extends BaseProps {
  source?: string;
  animationname: "Circles";
}

export function LottieAnimation(props: LottieAnimationProps) {
  const animations: { [key: string]: any } = {
    Circles,
  };

  return (
    <Lottie
      {...props}
      autoPlay={props.autoPlay ? props.autoPlay : true}
      loop={props.loop ? props.loop : true}
      animationData={animations[props.animationname]}
      style={styles.container}
    />
  );
}

const styles = {
  container: {
    height: "100%",
  },
};

const packageId = process.env.REACT_APP_ANDROID_PACKAGE;
const appId = process.env.REACT_APP_IOS_ID;

const playStoreLink = `https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=${packageId}`;
const appStoreLink = `https://itunes.apple.com/app/${appId}`;

const ua = navigator.userAgent || navigator.vendor;

const isIOSRegex = /iPad|iPhone|iPod/i;
const androidRegex = /android/i;

export const getCurrentURL = () => {
  return window.location.href;
};

export const getSupplierId = (pathname: string) => {
  if (pathname !== "") {
    const splittedPath = pathname.trim().split("/");
    const supplierId = splittedPath[1];
    return supplierId;
  }
  return "";
};

export const isIOSDevice = () => {
  return (
    isIOSRegex.test(ua) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  );
};

export const isAndroidDevice = () => {
  return androidRegex.test(ua);
};

export const isMobileDevice = () => isIOSDevice() || isAndroidDevice();

export const androidFormatUrl = (supplierId: string) => {
  const domain = process.env.REACT_APP_DEEPLINK_DOMAIN;
  const baseUrl = `${domain}/.well-known/assetlinks.json/share`;

  if (supplierId) {
    return `${baseUrl}?type=supplier&id=${supplierId}`;
  }
  return baseUrl;
};

export const iOSFormatUrl = (supplierId: string) => {
  const domain = process.env.REACT_APP_DEEPLINK_DOMAIN;
  const baseUrl = `${domain}/.well-known/apple-app-site-association/share`;

  if (supplierId) {
    return `${baseUrl}?type=supplier&id=${supplierId}`;
  }
  return baseUrl;
};

export const redirectToApp = () => {
  const currentUrl = new URL(getCurrentURL());
  const supplierId = getSupplierId(currentUrl.pathname);

  if (isAndroidDevice()) {
    const url = androidFormatUrl(supplierId);
    const playStoreUrl = playStoreLink;
    redirect(url, playStoreUrl);
  }
  if (isIOSDevice()) {
    const url = iOSFormatUrl(supplierId);
    const appStoreUrl = appStoreLink;
    redirect(url, appStoreUrl);
  }
};

const redirect = (appUrl: string, fallbackUrl: string) => {
  window.location.href = appUrl;
  setTimeout(() => {
    window.location.href = fallbackUrl;
  }, 150);
};

import { HttpService as _httpService } from "./http-service";
import { SupplierService as _supplierService } from "./supplier-service";
import { ProductService as _productService } from "./product-service";
import { GeoLocationServicesService as _geoLocationServicesService } from "./geoLocationServices-service";
import { RetoolService as _retoolService } from "./retool.service";
import { MediaService as _mediaService } from "./media.service";
import { SocialService as _socialService } from "./social.service";

export const HttpService = new _httpService();
export const SupplierService = new _supplierService(HttpService);
export const ProductService = new _productService(HttpService);
export const GeoLocationServicesService = new _geoLocationServicesService(
  HttpService
);
export const RetoolService = new _retoolService(HttpService);
export const MediaService = new _mediaService();
export const SocialService = new _socialService(HttpService);

import { Box, Button, ButtonProps, styled } from "@mui/material";
import { Colors } from "../../themes";

const NewButton = styled(Button)<ButtonProps>(
  ({ theme, color = "primary" }) => ({
    borderRadius: 8,
    textTransform: "none",
    color: Colors.WHITE,
    width: "100%",

    "&.MuiButton-containedPrimary": {
      borderRadius: 8,
      textTransform: "none",
      color: Colors.WHITE,
      backgroundColor: Colors.PRIMARYSUPPLIER,
      width: "100%",
    },
    "&.MuiButton-root": {
      borderRadius: 8,
      textTransform: "none",
      color: color === "primary" ? Colors.WHITE : Colors.PRIMARYSUPPLIER,
      width: "100%",
      backgroundColor: Colors.PRIMARYSUPPLIER,
      fontFamily: "Montserrat",
      fontSize: "1.2rem",
      fontWeight: 500,
    },
  })
);

function CustomSmallButton(props: {
  text: string;
  bgColor: string;
  color:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  onClick?: () => void;
  disabled?: boolean;
}) {
  const buttonColor = props.disabled ? Colors.GRAY6 : props.bgColor;
  return (
    <Box sx={styles.boxContainer}>
      <NewButton
        variant="contained"
        color={props.color}
        onClick={props.onClick}
        sx={{ backgroundColor: `${buttonColor} !important` }}
        style={styles.container}
      >
        <p className="TextStyles-H2" color={props.bgColor}>
          {props.text}
        </p>
      </NewButton>
    </Box>
  );
}

const styles = {
  container: {
    border: "2px solid",
    borderColor: Colors.PRIMARYSUPPLIER,
  },
  boxContainer: {
    display: "flex",
    flex: 1,
    maxHeight: { xs: "50%", md: "80%" },
    maxWidth: "100%",
  },
};

export default CustomSmallButton;

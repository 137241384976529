import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Colors } from "../../themes";
import { ModalFooter } from "./complements/ModalFooter";
import { ProductService } from "../../services";
import {
  ICost,
  IProductComplementItemsRes,
  IStoreProduct,
  emptyConfigRes,
} from "../../interfaces/IProducts";
import Paper from "@mui/material/Paper";
import { Icon } from "@mui/material";
import { ProductConfigurations } from "./complements/ProductConfigurations";

type Props = {
  modalName: string;
  product: IStoreProduct;
  setModalName: (value: string) => void;
};

export function ProductComplementsModal(props: Readonly<Props>) {
  const { modalName, setModalName, product } = props;
  const [complements, setComplements] = useState<{
    [key: string]: IProductComplementItemsRes[];
  }>({});
  const [configurations, setConfigurations] = useState(emptyConfigRes());
  const setComplementsSection = (
    options: IProductComplementItemsRes[],
    section: string
  ) => {
    complements[section] = options;
    setComplements(complements);
  };
  const [errorIndexes, setErrorIndexes] = useState<number[]>([]);
  const [cost, setCost] = useState<ICost>({
    ammount: 0,
    currency: "MXN",
  });
  const [quantity, setQuantity] = useState(1);

  const handleClose = () => {
    setModalName("");
  };

  useEffect(() => {
    const getComplements = async () => {
      const res = await ProductService.getProductComplements(product.id);
      if (res.complementsList.length > 0) {
        setConfigurations(res);
      }
    };
    if (product.id) {
      getComplements();
    }
  }, [product.id]);

  const HeaderBar = () => {
    return (
      <Box sx={styles.headerBar} onClick={handleClose}>
        <Box sx={styles.modalHeader}>{"Elige tus complementos"}</Box>
        <Icon>close</Icon>
      </Box>
    );
  };

  const ProductDescription = () => {
    return (
      <Box sx={styles.descriptionContainer}>
        <Box sx={styles.productImageContainer}>
          <img
            src={product.images.regular}
            alt="product"
            className="modal-product-image"
          />
        </Box>
        <Box sx={styles.productDescription}>
          <Box sx={styles.productName}>{product.name}</Box>
          <Box sx={styles.productDesc}>{product.description}</Box>
        </Box>
      </Box>
    );
  };

  const Complements = () => {
    return (
      <Box sx={styles.complementsContainer}>
        <Box sx={styles.complementsInnerContainer}>
          <ProductConfigurations
            configurations={configurations.complementsList}
            cost={cost}
            setCost={setCost}
            setComplementsSection={setComplementsSection}
            localComplements={complements}
            errorIndexes={errorIndexes}
            setErrorIndexes={setErrorIndexes}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Modal
      open={modalName === "productComplements"}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={styles.modalStyles}
    >
      <Paper elevation={0} square sx={styles.paper}>
        <Box sx={styles.modalInnerContainer} style={{ position: "relative" }}>
          <Box style={styles.scrollDiv} className="scrollDiv">
            <HeaderBar />
            <Box sx={styles.modalMainContainer}>
              <ProductDescription />
              <Complements />
            </Box>
            <ModalFooter
              cost={cost}
              product={product}
              configurations={configurations.complementsList}
              complements={complements}
              quantity={quantity}
              errorIndexes={errorIndexes}
              setErrorIndexes={setErrorIndexes}
              setQuantity={setQuantity}
              handleClose={handleClose}
            />
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}

const styles = {
  modalStyles: { display: "flex", justifyContent: "center" },
  modalInnerContainer: {
    background: Colors.WHITE,
    height: { xs: "100%", sm: "100%" },
    width: { xs: "100vw", sm: "90vw" },
    alignSelf: "center",
  },
  paper: {
    marginTop: { xs: "0", sm: "10vh" },
    marginBottom: { xs: 0, sm: "10vh" },
  },
  modalMainContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    padding: { xs: "0", md: "5%" },
    paddingTop: { xs: "0", md: "0" },
    paddingBottom: { xs: "40%", md: "8%" },
    gap: "5%",
  },
  productImageContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    width: "100%",
    height: {
      xs: "25vh",
      sm: "30vh",
      md: "40vh",
    },
  },
  modalHeader: {
    paddingLeft: "4%",
    fontWeight: 600,
    fontSize: {
      xs: "1.2rem",
      lg: "1.3rem",
    },
  },
  headerBar: {
    position: "sticky",
    top: 0,
    height: "8%",
    cursor: "pointer",
    backgroundColor: Colors.WHITE,
    display: "flex",
    alignItems: "center",
    paddingX: "1%",
    paddingY: { xs: "0%", md: "1%" },
    justifyContent: "space-between",
    zIndex: 9999,
  },
  descriptionContainer: {
    width: { xs: "100%", md: "50%" },
  },
  productDescription: { pt: "3%", p: { xs: "3%", md: 0 } },
  productName: {
    fontSize: { xs: "1.2rem", sm: "1.3rem", lg: "1.4rem" },
    fontWeight: 600,
    marginTop: { xs: "1%", md: "5%" },
    marginY: "1%",
  },
  productDesc: {
    fontSize: { xs: "1rem", sm: "1.2rem", lg: "1.3rem" },
    fontWeight: 500,
    marginY: "2%",
  },
  complementsContainer: { width: { xs: "100%", md: "50%" } },
  complementsInnerContainer: { p: { xs: "3%", md: 0 } },
  scrollDiv: { height: "100%", overflow: "scroll" },
};

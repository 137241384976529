import { BucketIds } from "../constants/Buckets";
import { createCustomer, getCustomer, getSub } from "../services/auth.service";
import { createDefaultDisplayName } from "./SocialFunction";
import { IPostCustomer } from "../interfaces/ICustomer";
import { IPostMenuKey } from "../interfaces/IRetool";
import { MediaService, RetoolService } from "../services";

export const sendSupplierMenu = async (
  file: FileList | null,
  onboardingId: number
) => {
  if (!file) {
    throw new Error("No se han seleccionado archivos");
  }

  const postedMenuToS3 = await uploadFileToS3(file);

  if (
    !postedMenuToS3?.successful ||
    !postedMenuToS3?.keyfile ||
    !onboardingId
  ) {
    throw new Error("Error al subir el archivo");
  }

  const body: IPostMenuKey = {
    onboardingId: onboardingId,
    keyId: `${postedMenuToS3.keyfile}.jpg`,
    bucketId: BucketIds.MAIN_BUCKET_1,
  };

  const postedMenuKey = await RetoolService.postMenuKey(body);

  if (!postedMenuKey) {
    throw new Error("Error al enviar el archivo");
  }
};

const uploadFileToS3 = async (file: FileList) => {
  const FILE_TYPE = "image/jpg";
  const user = await getCustomer();

  if (!user.id) {
    console.error("El usuario no existe");
    return null;
  }

  const uploadImageRes = await MediaService.postImage(
    user.id,
    file[0],
    file[0].name,
    FILE_TYPE,
    BucketIds.LIBERET_MAIN,
    "onboarding"
  );

  return uploadImageRes.successful ? uploadImageRes : null;
};

export const getCustomerId = async (phone: string) => {
  const customer = await getCustomer();
  let customerId = customer.id;

  if (!customerId) {
    customerId = await createNewCustomer(phone);
  }

  return customerId;
};

export const createNewCustomer = async (phone: string) => {
  const sub = await getSub();
  const defaultDisplayName = await createDefaultDisplayName(phone);

  const body: IPostCustomer = {
    id: sub,
    locale: `${52} MX`,
    name: `${phone}`,
    phone: "+" + 52 + phone,
    birthday: "01/01/1990",
    defaultAddress: "0",
    amityDisplayName: defaultDisplayName,
  };

  const customer = await createCustomer(body);

  return customer.id ?? "";
};

export interface IMediaResponse {
  supplierid: string;
  filename: string;
  urlfile: string;
  keyfile: string;
  bucketname: string;
  successful: string;
}

export function emptyMediaResponse() {
  return {
    supplierid: "",
    filename: "",
    urlfile: "",
    keyfile: "",
    bucketname: "",
    successful: "",
  };
}

import { IHttpService } from "./ihttp-service";
import {
  emptyProductResponse,
  emptyProductSuppResponse,
  emptyServiceConfigResponse,
  emptySupplierSectionProducts,
  IProduct,
  IProductResponse,
  IProductSuppResponse,
  IServiceDayConfigurationResponse,
  ISupplierSectionProducts,
} from "../interfaces/IProducts";
import {
  AffiliateBody,
  createEmptyINewSupplierAccount,
  emptyAffiliateResponse,
  emptyISubscribe,
  emptyISupplierRules,
  INewSupplierAccount,
  IPostAffiliateResponse,
  IPostSubscribeBody,
  ISubscribe,
  ISupplierRules,
} from "../interfaces/ISuppliersProfile";
import { emptyISupplierMediaResponse, IMediaRes } from "../interfaces/IPosts";

const supplierUrl = process.env.REACT_APP_BASE_URL_SUPPLIERS_WEB;
const affiliateUrl = process.env.REACT_APP_AFFILIATE;

export class SupplierService {
  constructor(private httpService: IHttpService) {}

  async getProfile(id: string): Promise<INewSupplierAccount> {
    const url = `${supplierUrl}/suppliers/${id}`;
    console.log("Url de getProfile: ", id);
    return this.httpService.get(
      url,
      createEmptyINewSupplierAccount,
      "getSupplierProfile"
    );
  }

  async getSupplierRules(id: string): Promise<ISupplierRules> {
    const url = `${supplierUrl}/supplier/${id}/rules`;
    return this.httpService.get(url, emptyISupplierRules, "getSupplierRules");
  }

  async postSubscribe(
    id: string,
    body: IPostSubscribeBody
  ): Promise<ISubscribe> {
    const url = `${supplierUrl}/suppliers/${id}/subscribes`;
    return this.httpService.post(url, body, emptyISubscribe, "postSubscribe");
  }

  async getSupplierProducts(id: string): Promise<IProductResponse> {
    const url = `${supplierUrl}/${id}/products`;
    return fetch(url, {
      headers: {
        Accept: "application/json",
      },
      method: "get",
    })
      .then((response) =>
        this.httpService.processResponse(
          response,
          emptyProductSuppResponse(),
          "getSupplierProducts"
        )
      )
      .then((response: IProductSuppResponse) => {
        if (response) {
          const formattedRes: IProductResponse = {
            tags: response.tagsList || ([] as string[]),
            categories: response.categoriesList || ([] as string[]),
            subcategories: [] as string[],
            products: response.productsList || ([] as IProduct[]),
            groups: [] as string[],
          };
          return formattedRes;
        }
        return emptyProductResponse();
      })
      .catch((error) => {
        console.error(error);
        return emptyProductResponse();
      });
  }

  async getProducts(id: string): Promise<ISupplierSectionProducts> {
    const url = `${supplierUrl}/suppliers/${id}/products`;
    return fetch(url, {
      headers: {
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((response) =>
        this.httpService.processResponse(
          response,
          emptySupplierSectionProducts(),
          "getSupplierProducts"
        )
      )
      .then((response: ISupplierSectionProducts) => {
        if (!response) throw new Error("No response");

        return response;
      })
      .catch((error) => {
        console.error(error);
        return emptySupplierSectionProducts();
      });
  }

  async postAffiliate(body: AffiliateBody): Promise<IPostAffiliateResponse> {
    const url = `${affiliateUrl}`;

    return this.httpService.post(
      url,
      body,
      emptyAffiliateResponse,
      "postAffiliate"
    );
  }

  async getSupplierPosts(id: string): Promise<IMediaRes> {
    const url = `${supplierUrl}/suppliers/${id}/posts`;
    return this.httpService.get(url, emptyISupplierMediaResponse, "getPosts");
  }

  async getProductDays(id: string): Promise<IServiceDayConfigurationResponse> {
    const url = `${supplierUrl}/suppliers/${id}/serviceDays`;
    return this.httpService.get(
      url,
      emptyServiceConfigResponse,
      "getSupplierServiceDays"
    );
  }
}

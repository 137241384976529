import {
  IProductComplementItemsRes,
  IStoreProduct,
} from "../interfaces/IProducts";
import {
  ICartProductComplements,
  ICart,
  ICartItem,
} from "../interfaces/IShoppingCart";

export function createCartItem(
  id: string,
  cartProducts: ICart,
  product: IStoreProduct,
  complements: ICartProductComplements[],
  complementsForEdition: { [key: string]: IProductComplementItemsRes[] },
  quantity: number
) {
  const cartCopy = { ...cartProducts };

  const newProduct: ICartItem = {
    product: product,
    quantity: quantity,
    itemId: id,
    productComplements: complements,
    complementsForEdition: complementsForEdition,
  };

  cartCopy[id] = newProduct;

  return cartCopy;
}

export function createCartItemId(product: IStoreProduct) {
  const productId = product.id;
  const timeStamp = new Date().getTime();

  return productId + "-" + timeStamp;
}

export function getTotalCartProductPrice(cartItem: ICartItem) {
  let total = cartItem.product.cost.ammount;

  cartItem.productComplements.map((complement) => {
    return complement.complementItems.map((item) => {
      return (total += item.itemPrice);
    });
  });

  return total * cartItem.quantity;
}

export function getProductPrice(cartItem: ICartItem) {
  return cartItem.product.cost.ammount * cartItem.quantity;
}

export function getTotalCart(cartItems: ICartItem[]) {
  let total = 0;

  cartItems.forEach((item) => {
    total += getProductPrice(item);
  });

  return total;
}

export function getCartTotal(cartState: ICart) {
  let total = 0;
  const productsKeys = Object.keys(cartState);

  productsKeys.forEach((key) => {
    total += getTotalCartProductPrice(cartState[key]);
  });

  return total;
}

export function deleteFromCart(cartState: ICart, cartItemId: string) {
  const cartStateCopy = { ...cartState };

  delete cartStateCopy[cartItemId];

  return cartStateCopy;
}

import { createContext, Dispatch, useReducer } from "react";
import { ICart } from "../interfaces/IShoppingCart";
import {
  createEmptyINewSupplierAccount,
  INewSupplierAccount,
} from "../interfaces/ISuppliersProfile";
import {
  CartProductsActions,
  cartProductsReducer,
  LocationActions,
  locationReducer,
  SupplierAccountActions,
  supplierAccountReducer,
} from "./Reducers";
import { ILocation } from "../interfaces/ILocation";

type InitialStateType = {
  cartProducts: ICart;
  supplierAccount: INewSupplierAccount;
  location: ILocation;
};

const initSupplierAccount = createEmptyINewSupplierAccount();

const initialState: InitialStateType = {
  cartProducts: {},
  supplierAccount: initSupplierAccount,
  location: {
    latitude: 0,
    longitude: 0,
  },
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<
    SupplierAccountActions | CartProductsActions | LocationActions
  >;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = (
  state: InitialStateType,
  action: SupplierAccountActions | CartProductsActions | LocationActions
) => {
  const reducer = {
    cartProducts: cartProductsReducer(state.cartProducts, action),
    supplierAccount: supplierAccountReducer(state.supplierAccount, action),
    location: locationReducer(state.location, action),
  };

  return reducer;
};

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };

import React, { useState } from "react";
import {
  Button,
  Typography,
  Container,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  createTheme,
  ThemeProvider,
  LinearProgress,
  Box,
} from "@mui/material";
import {
  ArrowBack,
  CheckCircleOutline,
  ErrorOutline,
} from "@mui/icons-material";
import {
  answerCustomChallenge,
  checkIsActiveSession,
  handleSignOut,
  requestAccess,
} from "../../services/auth.service";
import { RetoolService } from "../../services";
import { emptyIPostSocialMediaUrlResponse } from "../../interfaces/IRetool";
import {
  getCustomerId,
  sendSupplierMenu,
} from "../../utils/OnboardingAIFunctions";

const steps = ["Paso 1", "Paso 2", "Paso 3"];

const OnboardingAIForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [linkType, setLinkType] = useState("google");
  const [linkValue, setLinkValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [linkError, setLinkError] = useState(true);
  const [fileValid, setFileValid] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [smsSent, setSmsSent] = useState(false);
  const [codeSMS, setCodeSMS] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<FileList | null>(null);

  const [postSocialMediaResponse, setPostSocialMediaResponse] = useState(() =>
    emptyIPostSocialMediaUrlResponse()
  );

  const handleNext = () => {
    processSelector();
  };

  const goToCompletedPage = async () => {
    await handleSignOut();
    setLoading(false);

    window.location.href = "/onboarding/ai/completed";
  };

  const handleBack = async () => {
    if (activeStep === 2) {
      setFile(null);
      setFileValid(false);
      setSuccessText("");
      setErrorText("");
      setVerificationCode("");
      setLoading(false);

      const isActiveSession = await checkIsActiveSession();

      if (isActiveSession) {
        return setActiveStep((prevActiveStep) => prevActiveStep - 2);
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReturnToWelcome = () => {
    window.location.href = "/onboarding/ai";
    window.location.reload();
  };

  const handleLinkTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLinkType(event.target.value);
    setLinkValue("");
    setLinkError(true);
  };

  const handleLinkChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const urlPattern = /^(https:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    const isValidUrl = urlPattern.test(event.target.value.toString());
    setLinkValue(event.target.value);
    setLinkError(!isValidUrl);

    setNextButtonDisabled(!isValidUrl);
  };

  const handleSendSMS = async () => {
    const phoneNumberPattern = /^\d{10}$/;
    const isValidPhoneNumber = phoneNumberPattern.test(phoneNumber);
    const fullMobileNumber = `+52${phoneNumber}`;

    if (isValidPhoneNumber) {
      setSmsSent(true);

      await handleSignOut();
      const isLoggedIn = await requestAccess(fullMobileNumber);

      if (isLoggedIn) {
        return handleNext();
      }

      setSuccessText("SMS enviado");
      setErrorText("");
    } else {
      setErrorText("Error: El número de teléfono no es válido.");
    }
  };

  const checkChallengeCode = async (code: string) => {
    const success = await answerCustomChallenge(code);

    if (success) {
      setNextButtonDisabled(false);
      setSuccessText("Código válido");
    } else {
      setNextButtonDisabled(true);
      setErrorText("Error: Código de verificación incorrecto");
    }
  };

  const handleSendCodeSMS = () => {
    const verificationCodePattern = /^\d{6}$/; // 6 dígitos
    const isValidVerificationCode =
      verificationCodePattern.test(verificationCode);
    if (isValidVerificationCode) {
      setCodeSMS(true);
      checkChallengeCode(verificationCode);
    } else {
      setErrorText("Error: El código de verificación no es válido.");
    }
  };

  const handleFileUpload = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      const allowedExtensions = [".jpg"];
      const fileExtension = file.name
        .substring(file.name.lastIndexOf("."))
        .toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        setFileValid(true);
        setNextButtonDisabled(false);
        setFile(files);
        console.log("Archivo válido:", file);
      } else {
        setFileValid(false);
        console.log("Error: Archivo no permitido.");
      }
    }
  };

  const processSelector = async () => {
    try {
      if (activeStep === 0) {
        await handleSignOut();
      }

      if (activeStep === 1) {
        sendSocialMediaUrl();
      }

      if (activeStep === 2) {
        await sendSupplierMenuProcess();
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setNextButtonDisabled(true);
    } catch (e) {
      errorHandler(e ?? "Error inesperado, intente más tarde");
      setLoading(false);
    }
  };

  const sendSocialMediaUrl = async () => {
    const id = await getCustomerId(phoneNumber);

    const postUrl = await RetoolService.postSocialMediaUrl(id, linkValue);

    if (postUrl.onboardingId === undefined) {
      errorHandler("Error al enviar URL de red social");
    }

    setPostSocialMediaResponse(postUrl);
  };

  const sendSupplierMenuProcess = async () => {
    setLoading(true);
    await sendSupplierMenu(file, postSocialMediaResponse.onboardingId);

    goToCompletedPage();
  };

  const theme = createTheme({
    typography: {
      fontSize: 14,
    },
  });

  const errorHandler = (message: unknown) => {
    setNextButtonDisabled(true);
    alert(message);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <LinearProgress
          variant="determinate"
          value={(activeStep + 1) * (100 / steps.length)}
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          minHeight="100vh"
        >
          {activeStep === 0 && (
            <div>
              <Typography variant="h5" gutterBottom>
                Elige una de las opciones
              </Typography>
              <Typography variant="subtitle2" gutterBottom marginBottom={4}>
                Deberas ingresar el enlace de tu negocio en Google o Facebook
              </Typography>
              <FormControl
                component="fieldset"
                style={{ marginBottom: "10px" }}
              >
                <RadioGroup
                  aria-label="tipo-de-enlace"
                  name="tipo-de-enlace"
                  value={linkType}
                  onChange={handleLinkTypeChange}
                >
                  <FormControlLabel
                    value="google"
                    control={<Radio />}
                    label="Enlace de Google"
                  />
                  <FormControlLabel
                    value="facebook"
                    control={<Radio />}
                    label="Enlace de Facebook"
                  />
                </RadioGroup>
              </FormControl>
              {linkType && (
                <TextField
                  label={`Enlace de ${
                    linkType === "google" ? "Google" : "Facebook"
                  }`}
                  variant="outlined"
                  fullWidth
                  value={linkValue}
                  onChange={handleLinkChange}
                  error={linkError}
                  helperText={
                    linkError
                      ? "Por favor, introduce una URL válida (Ej. https://fuddis.com ó https://www.facebook.com/sushirollmx)"
                      : ""
                  }
                />
              )}
            </div>
          )}
          {activeStep === 1 && (
            <form>
              <Typography variant="h6" gutterBottom>
                Ingresa tu número de teléfono
              </Typography>
              <Typography variant="caption" gutterBottom>
                Para unirte a Fuddis necesitamos verificar tu número de
                teléfono.
              </Typography>
              <TextField
                label="Número de teléfono"
                variant="outlined"
                fullWidth
                value={phoneNumber}
                onChange={(e) => {
                  // Limitar la entrada del usuario a 10 dígitos
                  if (e.target.value.length <= 10) {
                    setPhoneNumber(e.target.value);
                  }
                }}
                inputProps={{ maxLength: 10 }}
                style={{ marginBottom: "10px", marginTop: "24px" }}
              />
              <Button
                variant="contained"
                onClick={handleSendSMS}
                style={{ marginBottom: "10px" }}
              >
                Enviar SMS
              </Button>
              <TextField
                label="Código de verificación (6 dígitos)"
                variant="outlined"
                fullWidth
                value={verificationCode}
                onChange={(e) => {
                  if (e.target.value.length <= 6) {
                    setVerificationCode(e.target.value);
                    setCodeSMS(e.target.value.length === 6);
                  }
                }}
                style={{ marginBottom: "10px", marginTop: "14px" }}
                disabled={!smsSent}
              />
              <Button
                variant="contained"
                onClick={handleSendCodeSMS}
                style={{ marginBottom: "10px" }}
                disabled={!codeSMS}
              >
                Verificar
              </Button>
              {errorText && (
                <Typography variant="body2" color="error">
                  {errorText}
                </Typography>
              )}
              {successText && (
                <Typography variant="body2" color="green">
                  {successText}
                </Typography>
              )}
            </form>
          )}
          {activeStep === 2 && (
            <div style={{ textAlign: "center", marginBottom: "40px" }}>
              <Typography variant="h5" gutterBottom marginBottom={7}>
                Sube tu menu y relájate:
              </Typography>
              <input
                accept=".jpg"
                id="file-upload"
                type="file"
                onChange={(e) => handleFileUpload(e.target.files)}
                style={{ display: "none" }}
              />
              <label htmlFor="file-upload">
                <Button variant="contained" component="span">
                  Subir archivo (PDF, CSV, PNG)
                </Button>
              </label>
              {fileValid !== null && (
                <>
                  {fileValid ? (
                    <CheckCircleOutline sx={{ color: "green", ml: 1 }} />
                  ) : (
                    <ErrorOutline sx={{ color: "red", ml: 1 }} />
                  )}
                </>
              )}
            </div>
          )}
          <div>
            <Button
              onClick={handleReturnToWelcome}
              sx={{ mt: 2, ml: 2 }}
              startIcon={<ArrowBack />}
            >
              Inicio
            </Button>
            {activeStep !== 0 && (
              <Button onClick={handleBack} sx={{ mt: 2 }}>
                Atrás
              </Button>
            )}

            <Button
              onClick={handleNext}
              disabled={nextButtonDisabled || loading}
              sx={{ mt: 2, ml: 2 }}
            >
              {activeStep === steps.length - 1 ? "Enviar" : "Siguiente"}
            </Button>
          </div>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            style={{ marginTop: "20px", fontSize: "0.6rem" }}
          >
            Al hacer clic en Continuar aceptas el{" "}
            <a href="#" style={{ color: "blue" }}>
              Aviso de privacidad
            </a>
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default OnboardingAIForm;

import { Button, ButtonProps, styled } from "@mui/material";
import { Colors } from "../../themes";

const NewButton = styled(Button)<ButtonProps>(
  ({ theme, color = "primary" }) => ({
    "&.MuiButton-root": {
      borderRadius: 8,
      textTransform: "none",
      fontFamily: "Montserrat",
      fontSize: "1.2rem",
      fontWeight: 500,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      padding: 0,
    },
  })
);

type Props = {
  bgColor?: string;
  color: string;
  border: "primary" | "error";
  children: JSX.Element;
  onClick: () => void;
};

export function IconButton(props: Props) {
  const { bgColor, color, border, onClick, children } = props;

  const btnColor = bgColor ? bgColor : "transparent";

  const getBorder = () => {
    switch (border) {
      case "primary":
        return styles.primaryBorder;
      case "error":
        return styles.errorBorder;
      default:
        return styles.primaryBorder;
    }
  };

  return (
    <NewButton
      sx={{
        background: `${btnColor} !important`,
        border: getBorder(),
        color: color,
        ...styles.buttonStyles,
      }}
      onClick={onClick}
      variant={"contained"}
    >
      {children}
    </NewButton>
  );
}

const styles = {
  buttonStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icons: { fontSize: { xs: "1.2rem", md: "1.2rem" } },
  errorBorder: {
    borderWidth: 1,
    borderColor: Colors.ERROR,
    borderStyle: "solid",
  },
  primaryBorder: {
    borderWidth: 0,
  },
};

import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { AppContext } from "../../../context/AppContext";
import { Colors } from "../../../themes";
import { ProductService } from "../../../services";
import { IProductMenu, emptyIProductMenu } from "../../../interfaces/IProducts";
import { PlusButton } from "../../Buttons/PlusButton";
import { ModalWrapper } from "../../atoms/wrappers/ModalWrapper";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  modalName: string;
  productId: string;
  closeModal: () => void;
  addLinkedProductToCart: (product: IProductMenu) => void;
};

export function LinkedProductModal(props: Props) {
  const { modalName, closeModal, addLinkedProductToCart, productId } = props;
  const { state } = useContext(AppContext);
  const [product, setProduct] = useState(emptyIProductMenu());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getProduct = async () => {
      const date = new Date();
      setLoading(true);
      const res = await ProductService.getProductMenu(
        productId,
        date.toISOString(),
        state.location.latitude,
        state.location.longitude
      );
      setLoading(false);
      if (res.id) {
        setProduct(res);
      }
    };

    void getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const formatCost = () => {
    const currency = product.cost.currency.toUpperCase();
    const cost = product.cost.ammount;
    const formattedCost = `$ ${cost} ${currency}`;

    return formattedCost;
  };

  const handleClose = () => {
    closeModal();
  };

  const handleAddToCart = () => {
    addLinkedProductToCart(product);
  };

  const Title = () => {
    return (
      <Box sx={styles.title}>
        <Box sx={styles.titleText}>{"Productos relacionados"}</Box>
      </Box>
    );
  };

  const ProductInformation = () => {
    return (
      <Box>
        <Box sx={styles.productNameText}>{product.name}</Box>
        <Box sx={styles.costText}>{formatCost()}</Box>
      </Box>
    );
  };

  const AddButton = () => {
    return (
      <Box sx={styles.addButtonContainer}>
        <PlusButton
          bgColor={Colors.PRIMARYUSER}
          onClick={handleAddToCart}
          color={Colors.WHITE}
          text={"Añadir al carrito"}
        />
      </Box>
    );
  };

  const Body = () => {
    return (
      <Box sx={styles.body}>
        <Box sx={styles.imageContainer}>
          <img
            src={product.images.regular}
            alt={product.description}
            className="modal-product-image"
          />
        </Box>
        <Box sx={styles.productInfo}>
          <ProductInformation />
          <AddButton />
        </Box>
      </Box>
    );
  };

  const Loader = () => {
    return (
      <Box style={styles.loader}>
        <CircularProgress color="primary" />
      </Box>
    );
  };

  const MainContent = () => {
    if (loading) {
      return <Loader />;
    }
    return (
      <>
        <Title />
        <Body />
      </>
    );
  };

  return (
    <ModalWrapper
      modalVisible={modalName === "linkedProductModal"}
      onClose={handleClose}
    >
      <Box sx={styles.modalMainContainer}>
        <MainContent />
      </Box>
    </ModalWrapper>
  );
}

const styles = {
  modalMainContainer: {
    display: "flex",
    backgroundColor: Colors.WHITE,
    flexDirection: { xs: "column" },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: { xs: "5%", md: "4%" },
  },
  titleText: {
    color: Colors.BLACK,
    fontSize: "1.5rem",
    fontWeight: 600,
    textAlign: "center",
  },
  body: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "8%",
  },
  bodyText: {
    color: Colors.BLACK,
    textAlign: "center",
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  buttonsContainer: {
    width: "100%",
    display: "flex",
    height: "100%",
    justifyContent: "space-between",
    gap: "3%",
  },
  buttonContainer: {
    width: "50%",
  },
  imageContainer: {
    width: { xs: "40%", sm: "50%" },
  },
  productInfo: {
    width: { xs: "60%", sm: "50%" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingLeft: "1rem",
  },
  productNameText: {
    color: Colors.BLACK,
    fontSize: { xs: "1rem", sm: "1.2rem" },
    textAlign: "left",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 5,
    overflow: "hidden",
    maxheight: "70%",
  },
  costText: {
    marginTop: "4%",
  },
  addButtonContainer: {
    marginTop: "3rem",
    height: "2rem",
  },
  loader: {
    width: "100%",
    minHeight: "15rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

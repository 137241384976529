import { IFormatedRule } from "../../interfaces/ISuppliersProfile";

const RuleMessage = ({ rule }: { rule: IFormatedRule }) => {
  const formatHour = (hour: string) => {
    let numberHour = parseInt(hour);
    if (numberHour > 12) {
      numberHour = numberHour - 12;
      return `${numberHour}:00 pm`;
    }
    return `${numberHour}:00 am`;
  };

  const { startHour, endHour } = rule;
  let message = "";

  if (startHour !== "" && endHour !== "") {
    if (startHour === endHour) {
      message = "Cerrado";
    } else {
      message = formatHour(startHour) + " - " + formatHour(endHour);
    }
  }

  return <p className="TextStyles-S3">{message}</p>;
};

export default RuleMessage;

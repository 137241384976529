import { Dispatch, SetStateAction } from "react";
import { ErrorMessage } from "../ErrorMessage";

type Props = {
  value: string;
  required: boolean;
  label: string;
  placeholder: string;
  isError: boolean;
  textError: string;
  handleChange: Dispatch<SetStateAction<string>>;
};

export function TextInput(props: Readonly<Props>) {
  const { value, handleChange } = props;

  const handleChangeText = (e: { target: { value: string } }) => {
    handleChange(e.target.value);
  };

  return (
    <div className="w-full">
      <label
        htmlFor="first_name"
        className="block mb-2 text-md text-gray-900 dark:text-dark"
      >
        {props.label}
      </label>

      <input
        type="text"
        className={`appearance-none block w-full bg-gray6 text-gray-700 border border-gray-300 rounded-lg py-3 px-4  focus:outline-none focus:bg-white focus:border-gray-300`}
        placeholder={props.placeholder}
        required={props.required}
        value={value}
        onChange={handleChangeText}
      />

      <ErrorMessage isError={props.isError} message={props.textError} />
    </div>
  );
}

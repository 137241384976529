import CircularProgress from "@mui/material/CircularProgress";
import { PostCard } from "./PostCard";
import { IMedia } from "../../../interfaces/IPosts";
import { Box } from "@mui/material";

type Props = {
  loading: boolean;
  posts: IMedia[];
  setModalName: (modalName: string) => void;
  showLinkedProduct: (productId: string) => void;
};

export function PostList(props: Props) {
  const { posts, loading, setModalName, showLinkedProduct } = props;

  if (loading) {
    return (
      <Box style={styles.loader}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <>
      {posts.map((post) => (
        <PostCard
          key={post.id}
          post={post}
          setModalName={setModalName}
          showLinkedProduct={showLinkedProduct}
        />
      ))}
    </>
  );
}

const styles = {
  loader: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

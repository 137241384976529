import { IFormatedRule } from "../interfaces/ISuppliersProfile";
import { DeliveryTypesText } from "./Delivery";

export const DaysDictionary: { [key: number]: string } = {
  0: "Domingo",
  1: "Lunes",
  2: "Martes",
  3: "Miércoles",
  4: "Jueves",
  5: "Viernes",
  6: "Sábado",
};

function getDayNumber(day: string) {
  switch (day) {
    case "Domingo":
      return 0;
    case "Lunes":
      return 1;
    case "Martes":
      return 2;
    case "Miercoles":
      return 3;
    case "Jueves":
      return 4;
    case "Viernes":
      return 5;
    case "Sabado":
      return 6;
    default:
      return 7;
  }
}

export function getDaysNumberList(days: string[]) {
  return days.map((day) => getDayNumber(day));
}

export function calculateAnticipation(minutes: number) {
  return minutes / 60;
}

export function formatToTwelve(hours: number) {
  let newHours = hours;
  const ampm = hours >= 12 ? "pm" : "am";
  newHours = hours % 12;
  const y =
    ampm === "pm" && newHours === 0
      ? "12:00 pm"
      : ampm === "am" && newHours === 0
      ? "00:00 am"
      : "";
  const strTime = newHours + ":00 " + ampm;
  const returnTime = y !== "" ? y : strTime;
  return returnTime;
}

export function calculatePeriods(
  formattedRulesList: IFormatedRule[],
  day: number | undefined
) {
  const periods = [] as string[];
  let thisDay = formattedRulesList.find((e) => getDayNumber(e.day) === day);
  if (thisDay === undefined) thisDay = formattedRulesList[0];
  const startHour = parseInt(thisDay.startHour);

  let endHour;

  const anticipation = thisDay.anticipationInMinutes / 60;
  if (anticipation < 6) {
    endHour = parseInt(thisDay.endHour);
  } else {
    endHour = 21;
  }
  for (let i = startHour; i < endHour; i++) {
    if (i + anticipation <= endHour) {
      const firstHour = formatToTwelve(i);
      const lastHour = formatToTwelve(i + 1);
      const period = `${firstHour} - ${lastHour}`;
      periods.push(period);
    }
  }
  return periods;
}

export function calculateDeliveryTypes(types: string[]) {
  return types;
}

export const deliveryType = (types: string[]) => {
  return types.map((type) => {
    switch (type) {
      case "TO_USER_ADDRESS":
        return DeliveryTypesText.toUserAddress;
      case "PICKUP_IN_SUPPLIER":
        return DeliveryTypesText.pickupInSupplier;
      case "ONLINE":
        return DeliveryTypesText.online;
      case "CONSUME_IN_SUPPLIER":
        return DeliveryTypesText.consumeInSupplier;
      case "SHIPPING":
        return DeliveryTypesText.shipping;
      default:
        return "";
    }
  });
};

export const MonthsDictionary: { [key: number]: string } = {
  0: "Enero",
  1: "Febrero",
  2: "Marzo",
  3: "Abril",
  4: "Mayo",
  5: "Junio",
  6: "Julio",
  7: "Agosto",
  8: "Septiembre",
  9: "Octubre",
  10: "Noviembre",
  11: "Diciembre",
};

import React, { useState } from "react";
import {
  IProductComplementItemsRes,
  ICost,
} from "../../../interfaces/IProducts";
import { Colors } from "../../../themes";
import { Icon } from "@mui/material";
import { SpinnerButton } from "../../Buttons/SpinnerButton";
import { Box } from "@mui/material";
import { IconButton } from "../../Buttons/IconButton";

type Props = {
  complementItem: IProductComplementItemsRes;
  selectedCount: number;
  setSelectedCount: (value: number) => void;
  isArrayFull: boolean;
  setComplementsSection: (
    options: IProductComplementItemsRes[],
    section: string
  ) => void;
  localOptions: IProductComplementItemsRes[];
  listIndex: number;
  errorIndexes: number[];
  setErrorIndexes: (value: number[]) => void;
  cost: ICost;
  setCost: (value: ICost) => void;
  sectionName: string;
  count: number;
};

export function PlusConfigurationItem(props: Props) {
  const {
    complementItem,
    selectedCount,
    errorIndexes,
    listIndex,
    isArrayFull,
    localOptions,
    cost,
    sectionName,
    setErrorIndexes,
    setCost,
    setSelectedCount,
    setComplementsSection,
  } = props;
  const [count, setCount] = useState(props.count);
  const isError = errorIndexes.includes(listIndex);
  const item = complementItem;

  const onPress = () => {
    let currentOptions: IProductComplementItemsRes[] = [];

    if (errorIndexes.includes(listIndex)) {
      setErrorIndexes(errorIndexes.filter((e) => e !== listIndex));
    }
    if (!isArrayFull) {
      setCost({
        ammount: cost.ammount + item.price,
        currency: "MXN",
      });

      currentOptions = [...localOptions, item];
      setCount(count + 1);

      setSelectedCount(selectedCount + 1);
      setComplementsSection(currentOptions, sectionName);
    }
  };

  const handleIncrement = () => {
    let currentOptions = [] as IProductComplementItemsRes[];

    if (!isArrayFull) {
      setCost({
        ammount: cost.ammount + item.price,
        currency: "MXN",
      });

      currentOptions = [...localOptions, item];
      setCount(count + 1);

      setSelectedCount(selectedCount + 1);
      setComplementsSection(currentOptions, sectionName);
    }
  };

  const handleDecrement = () => {
    const findItem = localOptions.find((e) => e.id === item.id) ?? item;
    const removeItemIndex = localOptions.indexOf(findItem);

    const currentOptions = localOptions.filter(
      (option, index) => index !== removeItemIndex
    );

    setCost({
      ammount: cost.ammount - item.price,
      currency: "MXN",
    });

    setCount(count - 1);

    setSelectedCount(selectedCount - 1);
    setComplementsSection(currentOptions, sectionName);
  };

  const Spinner = () => {
    return (
      <Box
        sx={{
          ...styles.spinnerContainer,
        }}
      >
        <SpinnerButton
          handleDecrease={handleDecrement}
          handleIncrease={handleIncrement}
          counter={count}
          bgColor={Colors.GRAY6}
          color={Colors.BLACK}
          border={isError ? "error" : "primary"}
        />
      </Box>
    );
  };

  const AddButton = () => {
    return (
      <Box
        sx={{
          height: "30px",
        }}
      >
        <IconButton
          onClick={onPress}
          bgColor={Colors.GRAY6}
          color={Colors.BLACK}
          border={isError ? "error" : "primary"}
        >
          <Icon>add</Icon>
        </IconButton>
      </Box>
    );
  };

  function AddOrSpinner() {
    if (count > 0) {
      return <Spinner />;
    }
    return <AddButton />;
  }

  return (
    <Box sx={styles.itemContainer}>
      <Box sx={styles.nameAndPrice}>
        <Box sx={styles.itemName}>{item.name}</Box>
        <Box sx={styles.itemPrice}>{`$${item.price.toFixed(2)}`}</Box>
      </Box>
      <AddOrSpinner />
    </Box>
  );
}

const styles = {
  itemContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: { xs: "2%", md: "3%" },
  },
  nameAndPrice: {
    display: "flex",
    width: {
      xs: "60%",
      md: "70%",
    },
    justifyContent: "space-between",
    alignItems: "center",
  },
  itemName: {
    width: { xs: "60%" },
    fontSize: {
      xs: "0.9rem",
      sm: "1rem",
      md: "1rem",
      lg: "1.2rem",
    },
    wordWrap: "break-word",
  },
  itemPrice: {
    fontSize: {
      xs: "0.9rem",
      sm: "1rem",
      md: "1rem",
      lg: "1.2rem",
    },
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  spinnerContainer: {
    width: "100px",
    height: "30px",
  },
};

import { IHttpService } from "./ihttp-service";
import {
  IPostMenuKey,
  IPostMenuKeyResponse,
  IPostSocialMediaUrlBody,
  IPostSocialMediaUrlResponse,
  emptyIPostMenuKeyResponse,
  emptyIPostSocialMediaUrlResponse,
} from "../interfaces/IRetool";

const retoolWorkflows = process.env.REACT_APP_RETOOL_WORKFLOWS;

export class RetoolService {
  constructor(private httpService: IHttpService) {}

  async postSocialMediaUrl(
    userId: string,
    socialMedialUrl: string
  ): Promise<IPostSocialMediaUrlResponse> {
    const workflow = process.env.REACT_APP_WORKFLOW_API_KEY_START_ONBOARDING;

    const body: IPostSocialMediaUrlBody = {
      userId,
      url: socialMedialUrl,
    };

    const url = `${retoolWorkflows}${workflow}`;
    return this.httpService.post(
      url,
      body,
      emptyIPostSocialMediaUrlResponse,
      "postSocialMediaUrl"
    );
  }

  async postMenuKey(body: IPostMenuKey): Promise<IPostMenuKeyResponse> {
    const workflow = process.env.REACT_APP_WORKFLOW_API_KEY_UPLOAD_MENU;

    const url = `${retoolWorkflows}${workflow}`;
    return this.httpService.post(
      url,
      body,
      emptyIPostMenuKeyResponse,
      "postMenuKey"
    );
  }
}

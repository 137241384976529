import {
  IProductComplementItemsRes,
  IProductConfigRes,
} from "../interfaces/IProducts";

export function getComplementsDescription(configuration: IProductConfigRes) {
  const description = configuration.description;
  const customDescription = `Elige hasta ${configuration.maxquantity}`;

  return description !== "" ? description : customDescription;
}

export function isItemSelected(
  localOptions: IProductComplementItemsRes[],
  itemId: string
) {
  return localOptions.some((option) => option.id === itemId);
}

export function removedItem(
  localOptions: IProductComplementItemsRes[],
  itemId: string
) {
  return localOptions.filter((option) => option.id !== itemId);
}

import {
  createEmptyIProduct,
  IProductComplementItemsRes,
  IStoreProduct,
} from "./IProducts";

export interface ICartItem {
  product: IStoreProduct;
  quantity: number;
  itemId: string;
  productComplements: ICartProductComplements[];
  complementsForEdition: { [key: string]: IProductComplementItemsRes[] };
}

export function createEmptyCartItem(): ICartItem {
  return {
    product: createEmptyIProduct(),
    quantity: 0,
    itemId: "",
    productComplements: [],
    complementsForEdition: {},
  };
}

export interface ICartProductComplements {
  complementName: string;
  complementItems: ICartProductComplementItem[];
}

export interface ICartProductComplementItem {
  id: string;
  complementItemName: string;
  itemPrice: number;
  currency: string;
}

export interface ICart {
  [key: string]: ICartItem;
}

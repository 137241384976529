import Box from "@mui/material/Box";
import { Colors } from "../../../themes";
import congratulations from "../../../assets/images/congratulations.png";
import { ReservationInfo } from "../../../interfaces/IPosts";
import { SimpleButton } from "../../Buttons/SimpleButton";
import { ModalWrapper } from "../../atoms/wrappers/ModalWrapper";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";

type Props = {
  modalName: string;
  reservationInfo: ReservationInfo;
  setModaName: (val: string) => void;
};

export function ReservationFeedbackModal(props: Props) {
  const { state } = useContext(AppContext);
  const { modalName, reservationInfo, setModaName } = props;
  const { date, numberOfPeople, time } = reservationInfo;

  const infoText = `${date} - ${time} -  ${numberOfPeople}`;
  const supplierName = state.supplierAccount.contactName ?? "";
  const fuddisPhone = process.env.REACT_SALES_SUPPORT_PHONE ?? "";

  const handleClose = () => {
    setModaName("");
  };

  const Title = () => {
    return (
      <Box sx={styles.title}>
        <Box sx={styles.titleText}>{"Reservación"}</Box>
      </Box>
    );
  };

  const Subtitle = () => {
    return (
      <Box sx={styles.subtitleContainer}>
        <Box sx={styles.subtitleText}>{"¡Todo listo!"}</Box>
      </Box>
    );
  };

  const TopText = () => {
    return (
      <Box sx={styles.textContainer}>
        <Box sx={styles.bodyText}>{`Datos de la reservación: `}</Box>
        <Box sx={styles.bodyText}>{infoText}</Box>
      </Box>
    );
  };

  const MiddleText = () => {
    return (
      <Box sx={styles.textContainer}>
        <Box sx={styles.bodyText}>
          {`Te comunicamos directamente con nuestro embajador ${supplierName}.`}
        </Box>
      </Box>
    );
  };

  const BottomText = () => {
    return (
      <Box sx={styles.textContainer}>
        <Box sx={styles.bodyText}>
          {`Si tienes alguna duda comunícate con nosotros ${fuddisPhone}`}
        </Box>
      </Box>
    );
  };

  const AgreeButton = () => {
    return (
      <Box sx={styles.addButtonContainer}>
        <SimpleButton
          bgColor={Colors.PRIMARYSUPPLIER}
          onClick={handleClose}
          color={Colors.WHITE}
          text={"Aceptar"}
        />
      </Box>
    );
  };

  const Body = () => {
    return (
      <Box sx={styles.body}>
        <Subtitle />
        <TopText />
        <MiddleText />
        <BottomText />
        <Box sx={styles.imageContainer}>
          <img
            src={congratulations}
            alt={"Congratulations"}
            className="modal-product-image"
          />
        </Box>
        <AgreeButton />
      </Box>
    );
  };

  return (
    <ModalWrapper
      modalVisible={modalName === "reservationFeedbackModal"}
      onClose={handleClose}
    >
      <Box sx={styles.modalMainContainer}>
        <Title />
        <Body />
      </Box>
    </ModalWrapper>
  );
}

const styles = {
  modalMainContainer: {
    display: "flex",
    flexDirection: { xs: "column" },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: { xs: "3%", md: "2%" },
  },
  titleText: {
    color: Colors.BLACK,
    fontSize: "1.5rem",
    fontWeight: 600,
    textAlign: "center",
  },
  subtitleContainer: {
    marginTop: "1rem",
    marginBottom: "2rem",
  },
  subtitleText: {
    color: Colors.BLACK,
    textAlign: "center",
    fontSize: {
      xs: "1rem",
      sm: "1.2rem",
    },
    fontWeight: 600,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "5%",
    px: { xs: "1%" },
  },
  bodyText: {
    color: Colors.BLACK,
    textAlign: "center",
    fontSize: {
      xs: "1rem",
      sm: "1.2rem",
    },
  },
  textContainer: {
    marginBottom: "1rem",
  },
  buttonContainer: {
    width: "50%",
  },
  imageContainer: {
    width: { xs: "5rem", sm: "10rem" },
    alignSelf: "center",
  },
  addButtonContainer: {
    marginTop: "3rem",
    width: "80%",
    alignSelf: "center",
  },
};

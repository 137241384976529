import { Colors } from "../themes";
import { ISupplierSectionProducts } from "../interfaces/IProducts";
import { styled } from "@mui/system";
import { Tabs, Tab, Grid } from "@mui/material";
import ProductsGrid from "./ProductsGrid/ProductsGrid";
import React, { useState } from "react";
import SupplierRulesGrid from "./SupplierRulesGrid/SupplierRulesGrid";
import Footer from "./Footer/Footer";
import {
  INewSupplierAccount,
  ISupplierRules,
} from "../interfaces/ISuppliersProfile";
import { News } from "./SupplierSections/News/News";

type Props = {
  products: ISupplierSectionProducts;
  rules: ISupplierRules;
  supplier: INewSupplierAccount;
  modalName: string;
  setModalName: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
};

function SectionTabs(props: Readonly<Props>) {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const SectionPicker = () => {
    switch (value) {
      case 0:
        return (
          <ProductsGrid
            products={props.products}
            modalName={props.modalName}
            setModalName={props.setModalName}
            handleClose={props.handleClose}
          />
        );
      case 1:
        return (
          <SupplierRulesGrid rules={props.rules} supplier={props.supplier} />
        );
      case 2:
        return <News />;
      default:
        return null;
    }
  };

  return (
    <Grid item container xs={12}>
      <Grid xs={12} item>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          TabIndicatorProps={{
            style: {
              backgroundColor: Colors.PRIMARYSUPPLIER,
            },
          }}
        >
          <CustomTab label="Productos" />
          <CustomTab label="Información" />
        </Tabs>
        <SectionPicker />
        <Footer />
      </Grid>
    </Grid>
  );
}

const CustomTab = styled(Tab)(() => ({
  textTransform: "none",
  fontFamily: "Montserrat",
  fontSize: 20,
  "&.MuiTab-textColorPrimary": {
    color: Colors.BLACK,
  },
  "&.Mui-selected": {
    color: Colors.PRIMARYSUPPLIER,
  },
}));

export default SectionTabs;

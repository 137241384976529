import { AppUsers } from "../constants/AppUsers";

export interface IDeliveryAddress {
  formatAddress: string;
  latitude: number;
  longitude: number;
}

export interface IDeliveryInfo {
  available: boolean;
  cost: number;
  checked: boolean;
}

export function emptyDeliveryAddress(): IDeliveryAddress {
  return {
    formatAddress: "",
    latitude: 0,
    longitude: 0,
  };
}

export interface ICredits {
  amount: number;
}

export interface ICustomer {
  id: string;
  userType: string;
  locale: string;
  name: string;
  phone: string;
  birthday: string;
  email: string;
  defaultaddress: string;
  stripeid: string;
  credits: ICredits;
  code: number;
  details: string;
  ambassadorTC: boolean;
  referencedBy: string;
  wasCodeUsed: boolean;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
  amityDisplayName: string;
  latitude: number;
  longitude: number;
  images: {
    profile: IImage;
    cover: IImage;
  };
}

export interface IImage {
  original: string;
  thumbnail: string;
  regular: string;
  squared: string;
}

export const createEmptyCustomer = (): ICustomer => {
  return {
    id: "",
    userType: AppUsers.CUSTOMER,
    name: "",
    email: "",
    phone: "",
    locale: "",
    birthday: "",
    defaultaddress: "",
    stripeid: "",
    credits: {
      amount: 0,
    },
    code: 0,
    details: "",
    ambassadorTC: false,
    referencedBy: "a",
    wasCodeUsed: false,
    deletedAt: "",
    createdAt: "",
    updatedAt: "",
    amityDisplayName: "",
    latitude: 0,
    longitude: 0,
    images: {
      profile: {
        original: "",
        thumbnail: "",
        regular: "",
        squared: "",
      },
      cover: {
        original: "",
        thumbnail: "",
        regular: "",
        squared: "",
      },
    },
  };
};

export interface IPostCustomer {
  id: string;
  locale: string;
  name: string;
  amityDisplayName: string;
  phone: string;
  defaultAddress: string;
  latitude?: number;
  longitude?: number;
  birthday?: string;
  email?: string;
}

export function emptyPostCustomer(): IPostCustomer {
  return {
    id: "",
    locale: "",
    name: "",
    amityDisplayName: "",
    phone: "",
    defaultAddress: "",
    latitude: 0,
    longitude: 0,
    birthday: "",
    email: "",
  };
}

export interface IDisplayNameRecommendations {
  isvalid: boolean;
  recommendationsList: string[];
}

export function emptyIDisplayNameRecommendations(): IDisplayNameRecommendations {
  return {
    isvalid: false,
    recommendationsList: [],
  };
}

type Props = {
  value: string;
  htmlFor?: string;
};

export function Label(props: Readonly<Props>) {
  const { value, htmlFor = "" } = props;

  return (
    <label
      htmlFor={htmlFor}
      className="block mb-2 text-md text-gray-900 dark:text-dark"
    >
      {value}
    </label>
  );
}

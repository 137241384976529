import { AppContext } from "../../context/AppContext";
import { Box } from "@material-ui/core";
import { getTotalCartProductPrice } from "../../utils/CartFunctions";
import { Link } from "react-router-dom";
import { useContext } from "react";
import CustomOrderButton from "../Buttons/CustomOrderButton";
import Grid from "@mui/material/Grid";

type Props = {
  show?: boolean;
};

export function OrderButton(props: Readonly<Props>) {
  const { state } = useContext(AppContext);
  const { cartProducts } = state;

  const productsKeys = {
    keys: Object.keys(cartProducts),
    products: Object.values(cartProducts),
  };

  const getTotal = () => {
    let cartProductsCost = 0;

    productsKeys.keys.forEach((key) => {
      cartProductsCost += getTotalCartProductPrice(cartProducts[key]);
    });

    return cartProductsCost;
  };

  if (!props.show && productsKeys.keys.length === 0) return null;

  return (
    <Box width={"100%"} display={"flex"} justifyContent="flex-end">
      <Grid item container xs={12} md={6} lg={6} xl={4} sx={{ p: 1 }}>
        <Link
          to={`complete`}
          style={{ width: "100%", textDecoration: "none", height: "3rem" }}
        >
          <CustomOrderButton
            products={productsKeys.keys.length}
            total={getTotal().toFixed(2)}
            text="Enviar orden"
            onClick={undefined}
          />
        </Link>
      </Grid>
    </Box>
  );
}

import { ILocation } from "../interfaces/ILocation";
import { ICart } from "../interfaces/IShoppingCart";
import { INewSupplierAccount } from "../interfaces/ISuppliersProfile";

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  SetSupplierAccount = "SET_SUPPLIER_ACCOUNT",
  SetCartProducts = "SET_CART_PRODUCTS",
  SetLocation = "SET_LOCATION",
}

// Supplier Account

type SupplierAccountPayload = {
  [Types.SetSupplierAccount]: {
    supplierAccount: INewSupplierAccount;
  };
};

export type SupplierAccountActions =
  ActionMap<SupplierAccountPayload>[keyof ActionMap<SupplierAccountPayload>];

export const supplierAccountReducer = (
  state: INewSupplierAccount,
  action: SupplierAccountActions | CartProductsActions | LocationActions
) => {
  switch (action.type) {
    case Types.SetSupplierAccount:
      return action.payload.supplierAccount;
    default:
      return state;
  }
};

// Cart Products

type CartProductsPayload = {
  [Types.SetCartProducts]: {
    cartProducts: ICart;
  };
};

export type CartProductsActions =
  ActionMap<CartProductsPayload>[keyof ActionMap<CartProductsPayload>];

export const cartProductsReducer = (
  state: ICart,
  action: SupplierAccountActions | CartProductsActions | LocationActions
) => {
  switch (action.type) {
    case Types.SetCartProducts:
      return action.payload.cartProducts;
    default:
      return state;
  }
};

// Location

type LocationPayload = {
  [Types.SetLocation]: {
    location: ILocation;
  };
};

export type LocationActions =
  ActionMap<LocationPayload>[keyof ActionMap<LocationPayload>];

export const locationReducer = (
  state: ILocation,
  action: SupplierAccountActions | CartProductsActions | LocationActions
) => {
  switch (action.type) {
    case Types.SetLocation:
      return action.payload.location;
    default:
      return state;
  }
};

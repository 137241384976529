import { defaultPaymentGeoService } from "../constants/PaymentGeoService";
import { IDeliveryAddress } from "../interfaces/ICustomer";
import { IPaymentGeoService } from "../interfaces/IGeoLocationServices";
import { ISupplierProfileAddress } from "../interfaces/ISuppliersProfile";
import { distance } from "./Functions";

export const calculateDeliveryCost = (
  deliveryAddress: IDeliveryAddress,
  deliveryPayment: IPaymentGeoService,
  supplierAddress: ISupplierProfileAddress
) => {
  let deliveryCost = 0;

  if (deliveryAddress.latitude && deliveryAddress.longitude) {
    const distanceInKm = distance(
      supplierAddress.latitude,
      supplierAddress.longitude,
      deliveryAddress.latitude,
      deliveryAddress.longitude
    );

    if (deliveryPayment.deliveryBaseCost && deliveryPayment.deliveryExtraCost) {
      deliveryCost =
        distanceInKm <= 3
          ? deliveryPayment.deliveryBaseCost
          : getExtraCost(deliveryPayment, distanceInKm);
    } else {
      deliveryCost =
        distanceInKm <= 3
          ? defaultPaymentGeoService.deliveryBaseCost
          : getExtraCost(defaultPaymentGeoService, distanceInKm);
    }
  }

  return deliveryCost;
};

const getExtraCost = (
  deliveryPayment: IPaymentGeoService,
  distanceInKm: number
) => {
  return (
    deliveryPayment.deliveryBaseCost +
    deliveryPayment.deliveryExtraCost * (distanceInKm - 3)
  );
};

import { emptyMediaResponse, IMediaResponse } from "../interfaces/IMedia";
import { getToken } from "./auth.service";
import { IMediaService } from "./imedia.service";

const suppliersUrl = process.env.REACT_APP_BASE_URL;

export class MediaService implements IMediaService {
  async postImage(
    supplierId: string,
    file: File,
    fileName: string,
    fileType: string,
    bucketName: string,
    processType: string
  ): Promise<IMediaResponse> {
    const url = `${suppliersUrl}/images`;
    const token = await getToken();

    const formData = new FormData();

    formData.append("file", file, fileName);
    formData.append("supplierId", supplierId);
    formData.append("fileType", fileType);
    formData.append("bucketName", bucketName);
    formData.append("type", processType);

    return fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        return emptyMediaResponse();
      })
      .then((response: IMediaResponse) => {
        if (response) {
          return response;
        }
        return emptyMediaResponse();
      })
      .catch((error) => {
        console.error(error);
        return emptyMediaResponse();
      });
  }
}

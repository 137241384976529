import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { Colors } from "../../../themes";
import { SpinnerButton } from "../../Buttons/SpinnerButton";
import { ICartItem } from "../../../interfaces/IShoppingCart";
import { getTotalCartProductPrice } from "../../../utils/CartFunctions";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { Types } from "../../../context/Reducers";

type Props = {
  cartProduct: ICartItem;
};

export function OrderCardFooter(props: Props) {
  const { cartProduct } = props;
  const { state, dispatch } = useContext(AppContext);
  const { cartProducts } = state;
  const productQuantity = cartProduct.quantity;

  const handleDecrease = () => {
    const cartStateCopy = { ...cartProducts };
    const cartItemId = cartProduct.itemId;
    const cartItem = cartStateCopy[cartItemId];

    if (cartItem.quantity > 1) {
      cartItem.quantity -= 1;
      cartStateCopy[cartItemId] = cartItem;

      if (Object.keys(cartStateCopy).length > 0) {
        dispatch({
          type: Types.SetCartProducts,
          payload: {
            cartProducts: cartStateCopy,
          },
        });
      }
    }
  };

  const handleIncrease = () => {
    const cartStateCopy = { ...cartProducts };
    const cartItemId = cartProduct.itemId;
    const cartItem = cartStateCopy[cartItemId];

    cartItem.quantity += 1;
    cartStateCopy[cartItemId] = cartItem;

    if (Object.keys(cartStateCopy).length > 0) {
      dispatch({
        type: Types.SetCartProducts,
        payload: {
          cartProducts: cartStateCopy,
        },
      });
    }
  };

  const SubTotalText = () => {
    const subtotal = getTotalCartProductPrice(cartProduct);
    const subtotalText = `Subtotal: $ ${subtotal.toFixed(2)} MXN`;

    return <Box sx={styles.subTotal}>{subtotalText}</Box>;
  };

  return (
    <Grid container sx={styles.footerContainer}>
      <Grid item xs={5} md={6}>
        <Box sx={styles.spinnerContainer}>
          <SpinnerButton
            counter={productQuantity}
            handleDecrease={handleDecrease}
            handleIncrease={handleIncrease}
            bgColor={Colors.GRAY6}
            color={Colors.BLACK}
            border={"primary"}
          />
        </Box>
      </Grid>
      <Grid item xs={7} md={6} sx={styles.subTotalContainer}>
        <SubTotalText />
      </Grid>
    </Grid>
  );
}

const styles = {
  footerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  spinnerContainer: {
    width: "100%",
    height: "5vh",
  },
  subTotalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  subTotal: {
    marginLeft: "5%",
    fontSize: {
      xs: "1rem",
      sm: "1.1rem",
      md: "1.2rem",
      lg: "1.3rem",
    },
    fontWeight: 600,
  },
};

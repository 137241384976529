import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { ICartItem } from "../../../interfaces/IShoppingCart";
import { ComplementsTable } from "./ComplementsTable";

type Props = {
  cartProduct: ICartItem;
};

export function OrderCardBody(props: Props) {
  const { cartProduct } = props;
  const imageUrl = cartProduct.product.images.original ?? "";

  const Description = () => {
    const description = cartProduct.product.description ?? "";
    const price = cartProduct.product.cost.ammount ?? 0;
    const priceText = `${price.toFixed(2)} MXN`;

    const ProductPrice = () => {
      return (
        <Box sx={styles.productPriceContainer}>
          <Box sx={styles.priceText}>{`Producto: $${priceText}`}</Box>
        </Box>
      );
    };

    return (
      <Grid container>
        <Grid item xs={3}>
          <Box sx={styles.imgOuterContainer}>
            <Box sx={styles.imgContainer}>
              <img src={imageUrl} alt="product" className="order-image" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={9} sx={styles.descriptionTextContainer}>
          <Box sx={styles.description}>{description}</Box>
          <ProductPrice />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box sx={styles.body}>
      <Description />
      <ComplementsTable cartProduct={cartProduct} />
    </Box>
  );
}

const styles = {
  body: {
    width: "100%",
  },
  imgOuterContainer: {
    display: "flex",
    flexDirection: "center",
    alignItems: "center",
  },
  imgContainer: {
    width: "100%",
    height: "100%",
  },
  descriptionTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: "3%",
    overflow: "hidden",
  },
  description: {
    fontSize: {
      xs: "0.9rem",
      sm: "1rem",
      md: "1.1rem",
    },

    width: "100%",
    wordWrap: "break-word",
  },
  productPriceContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: { xs: "5%", sm: "3%" },
  },
  priceText: {
    fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
    fontWeight: 600,
  },
};

export interface IProductResponse {
  tags: string[];
  categories: string[];
  subcategories: string[];
  products: IProduct[];
  groups: string[];
}

export interface IProductSuppResponse {
  tagsList: string[];
  categoriesList: string[];
  subcategoriesList: string[];
  productsList: IProduct[];
  sectionList: ISection[];
}

export interface IProductsParams {
  time: string;
  lat: number;
  long: number;
  size?: number;
  page?: number;
}

export interface IProductMenu {
  id: string;
  name: string;
  description: string;
  details: string;
  cost: ICost;
  groupId: string;
  shipments: number;
  measurementUnits: string;
  moq: number;
  imageUrl: string;
  images: IProductsImages;
  status: string;
  tagsList: string[];
  categoriesList: string[];
  subcategoriesList: string[];
  supplier: ISupplier;
}

export interface ISection {
  id: string;
  name: string;
  productsList: IProduct[];
}

export const emptyIProductMenu = (): IProductMenu => {
  return {
    id: "",
    name: "",
    description: "",
    details: "",
    cost: {
      ammount: 0,
      currency: "",
    },
    groupId: "",
    shipments: 0,
    measurementUnits: "",
    moq: 0,
    imageUrl: "",
    images: {
      original: "",
      regular: "",
      thumbnail: "",
      squared: "",
    },
    status: "",
    tagsList: [] as string[],
    categoriesList: [] as string[],
    subcategoriesList: [] as string[],
    supplier: emptySupplierIProduct(),
  };
};

export interface IProductRulesMenu {
  deliveryDays: IDeliveryDays;
  deliveryOptionsList: string[];
  deliveryPeriodsList: IDeliveryPeriod[];
  availableDates: IAvailableDates;
  anticipation: IAnticipation;
  nextAvailableDate: string;
  available: boolean;
}

export const emptyIProductRulesMenu = (): IProductRulesMenu => {
  return {
    deliveryOptionsList: [],
    deliveryPeriodsList: [],
    available: false,
    deliveryDays: emptyIDeliveryDays(),
    availableDates: {
      startDate: "",
      endDate: "",
    },
    nextAvailableDate: "",
    anticipation: emptyIAnticipation(),
  };
};

export interface ISuperProductMenu {
  id: string;
  name: string;
  description: string;
  details: string;
  cost: ICost;
  groupId: string;
  shipments: number;
  measurementUnits: string;
  moq: number;
  imageUrl: string;
  images: IProductsImages;
  status: string;
  tagsList: string[];
  categoriesList: string[];
  subcategoriesList: string[];
  supplier: ISupplier;
  //
  deliveryDays: IDeliveryDays;
  deliveryOptionsList: string[];
  deliveryPeriodsList: IDeliveryPeriod[];
  availableDates: IAvailableDates;
  anticipation: IAnticipation;
  nextAvailableDate: string;
  available: boolean;
}

export const emptyISuperProductMenu = (): ISuperProductMenu => {
  return { ...emptyIProductMenu(), ...emptyIProductRulesMenu() };
};

export interface IProduct {
  id: string;
  name: string;
  description: string;
  detail: string;
  cost: ICost;
  groupId: string;
  shipments: number;
  active: boolean;
  measurementUnits: string;
  moq: number;
  imageUrl: string;
  images: IProductsImages;
  status: string;
  tagsList: string[];
  categoriesList: string[];
  subcategoriesList: string[];
  supplier: ISupplier;
  //
  deliveryDays: IDeliveryDays;
  deliveryOptionsList: string[];
  deliveryPeriodsList: IDeliveryPeriod[];
  availableDates: IAvailableDates;
  anticipation: IAnticipation;
  nextAvailableDate: string;
  available: boolean;
}

export interface IProductsImages {
  original: string;
  thumbnail: string;
  regular: string;
  squared: string;
}

export function emptyIProductsImages(): IProductsImages {
  return {
    original: "",
    thumbnail: "",
    regular: "",
    squared: "",
  };
}

export interface ISupplier {
  id: string;
  name: string;
  imageUrl: string;
  contactName: string;
  contactPhone: string;
  email: string;
  images: IProductsImages;
  address: {
    name: string;
    latitude: number;
    longitude: number;
  };
  active: boolean;
  rfc: string;
  status: string;
  categoryId: string;
  locale: string;
  countryCode: string;
  commission: number;
  userEstablishmentId: string;
  customerId: string;
  coverUrl: string;
}

export const emptySupplierIProduct = (): ISupplier => {
  return {
    id: "",
    name: "",
    imageUrl: "",
    contactName: "",
    contactPhone: "",
    email: "",
    images: {
      original: "",
      thumbnail: "",
      regular: "",
      squared: "",
    },
    address: {
      name: "",
      latitude: 0,
      longitude: 0,
    },
    active: false,
    rfc: "",
    status: "",
    categoryId: "",
    locale: "",
    countryCode: "",
    commission: 0,
    userEstablishmentId: "",
    customerId: "",
    coverUrl: "",
  };
};

export interface ICost {
  currency: string;
  ammount: number;
}

export interface IDeliveryPeriod {
  startHour: string;
  anticipationInMinutes: number;
  duration: number;
}

export interface IDeliveryDays {
  dayList: string[];
  type: string;
}

export function emptyIDeliveryDays(): IDeliveryDays {
  return {
    dayList: [],
    type: "",
  };
}

export interface IAvailableDates {
  startDate: string;
  endDate: string;
}

export interface IAnticipation {
  anticipationExpanded: IAnticipationExpanded;
  anticipationInMinutes: number;
}

export function emptyIAnticipation(): IAnticipation {
  return {
    anticipationExpanded: emptyIAnticipationExpanded(),
    anticipationInMinutes: 0,
  };
}

export interface IAnticipationExpanded {
  days: number;
  hours: number;
  minutes: number;
}

export function emptyIAnticipationExpanded(): IAnticipationExpanded {
  return {
    days: 0,
    hours: 0,
    minutes: 0,
  };
}

export const emptyProductResponse = (): IProductResponse => {
  return {
    tags: [] as string[],
    categories: [] as string[],
    subcategories: [] as string[],
    products: [] as IProduct[],
    groups: [] as string[],
  };
};

export const emptyProductSuppResponse = (): IProductSuppResponse => {
  return {
    tagsList: [] as string[],
    categoriesList: [] as string[],
    subcategoriesList: [] as string[],
    productsList: [] as IProduct[],
    sectionList: [] as ISection[],
  };
};

export interface ICategories {
  categories: ICategory[];
  groups: IGroups;
}

export interface ICategory {
  name: string;
  images: IProductsImages;
  subcategoryList: string[];
  groupsList: string[];
}

interface IGroups {
  nameList: string[];
}
export interface IZone {
  name: string;
}

export interface IZones {
  zonesList: IZone[];
}

export function emptyIZones(): IZones {
  return { zonesList: [] };
}

export const createEmptyIProduct = (): IProduct => {
  return {
    id: "",
    name: "",
    shipments: 0,
    description: "",
    detail: "",
    imageUrl: "",
    active: false,
    supplier: emptySupplierIProduct(),
    cost: {
      currency: "",
      ammount: 0,
    },
    images: {
      regular: "",
      thumbnail: "",
      original: "",
      squared: "",
    },
    groupId: "",
    tagsList: [],
    deliveryOptionsList: [],
    deliveryPeriodsList: [],
    categoriesList: [],
    available: false,
    deliveryDays: emptyIDeliveryDays(),
    availableDates: {
      startDate: "",
      endDate: "",
    },
    status: "",
    measurementUnits: "",
    moq: 1,
    nextAvailableDate: "",
    anticipation: emptyIAnticipation(),
    subcategoriesList: [],
  };
};

export const createProductsInitialState = (): IProductResponse => {
  return {
    tags: ["Loading"],
    categories: [] as string[],
    subcategories: [] as string[],
    groups: [] as string[],
    products: [
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
      { ...createEmptyIProduct() },
    ],
  };
};

export const createEmptyCategoriesResponse = (): ICategories => {
  return {
    categories: [],
    groups: {
      nameList: [],
    },
  };
};

export interface ITimesForm {
  name: string;
  startHour: number;
  endHour: number;
}

export const createITimesFormList = (): ITimesForm[] => {
  return [
    {
      name: "Mon",
      startHour: 8,
      endHour: 20,
    },
    {
      name: "Tues",
      startHour: 8,
      endHour: 20,
    },
    {
      name: "Wed",
      startHour: 8,
      endHour: 20,
    },
    {
      name: "Thur",
      startHour: 8,
      endHour: 20,
    },
    {
      name: "Fri",
      startHour: 8,
      endHour: 20,
    },
    {
      name: "Sat",
      startHour: 8,
      endHour: 20,
    },
    {
      name: "Sun",
      startHour: 0,
      endHour: 0,
    },
  ];
};

export const createITimesFormListClosed = (): ITimesForm[] => {
  return [
    {
      name: "Mon",
      startHour: 0,
      endHour: 0,
    },
    {
      name: "Tues",
      startHour: 0,
      endHour: 0,
    },
    {
      name: "Wed",
      startHour: 0,
      endHour: 0,
    },
    {
      name: "Thur",
      startHour: 0,
      endHour: 0,
    },
    {
      name: "Fri",
      startHour: 0,
      endHour: 0,
    },
    {
      name: "Sat",
      startHour: 0,
      endHour: 0,
    },
    {
      name: "Sun",
      startHour: 0,
      endHour: 0,
    },
  ];
};

export interface AdvanceConfiguration {
  times: ITimesForm[];
  services: boolean[];
  antType: string;
  hoursDays: string;
}

export function emptyAdvanceConfiguration(): AdvanceConfiguration {
  return {
    times: createITimesFormList(),
    services: [true, false, false],
    antType: "Hours",
    hoursDays: "1",
  };
}

export interface IPostProduct {
  product: IPostProductInfo;
  advance: IAdvance;
}

export interface IPostMedia {
  title: string;
  description: string;
  imageUrl: string;
  productId?: string;
}

export interface ISupplierInfo {
  name: string;
  imageUrl: string;
  image: IProductsImages;
}

export interface ILinkedProduct {
  name: string;
  imageUrl: string;
  image: IProductsImages;
  cost: ICost;
}

export interface IPostProductInfo {
  name: string;
  description: string;
  currency: string;
  amount: number;
  imageUrl: string;
}

export interface IAdvance {
  rules: IRule[];
}

export interface IRule {
  type: string;
  configuration: string | IDaysRule;
}

export interface IDaysRule {
  available_period: IAvailablePeriod;
  delivery_period: IDeliveryPeriodRule;
}

export interface IAvailablePeriod {
  at: string[];
  type: string;
}

export interface IDeliveryPeriodRule {
  type: string;
  start_hour: number;
  end_hour: number;
  anticipation_in_minutes: number;
}

export interface IProductConfigurationResponse {
  complementsList: IProductConfigRes[];
}

export interface IProductConfigRes {
  id: string;
  description: string;
  title: string;
  isRequired: boolean;
  isUnique: boolean;
  minquantity: number;
  maxquantity: number;
  itemsList: IProductComplementItemsRes[];
  productId: string;
}

export function emptyConfigRes(): IProductConfigurationResponse {
  return {
    complementsList: [],
  };
}

export interface IProductComplementItemsRes {
  id: string;
  name: string;
  price: number;
  currency: string;
  complementId: string;
  parent: string;
}

export interface ICost {
  currency: string;
  ammount: number;
}

export interface IFormatedComplementItem {
  id: string;
  name: string;
  price: number;
  currency: string;
  quantity: number;
  total: number;
}

export interface IServiceDayConfigurationResponse {
  periodsList: IServiceDayPeriod[];
  supplierId: string;
  timeZone: string;
}

export interface IServiceDayPeriod {
  day: number;
  anticipation: number;
  startHour: number;
  startMinutes: number;
  endHour: number;
  endMinutes: number;
}

export function emptyServiceConfigResponse(): IServiceDayConfigurationResponse {
  return {
    periodsList: [],
    supplierId: "",
    timeZone: "America/Mexico_City",
  };
}

export interface IServiceDate {
  day: number;
  anticipation: number;
  startHour: number;
  endHour: number;
  displayDate: string;
  availableHours: string[];
}

export function emptyIServiceDate(): IServiceDate {
  return {
    day: 0,
    anticipation: 0,
    startHour: 0,
    endHour: 0,
    displayDate: "",
    availableHours: [],
  };
}

export interface IProductsParams {
  time: string;
  lat: number;
  long: number;
}

export interface ICategories {
  categories: ICategory[];
  groups: IGroups;
}

export function emptyCategories(): ICategories {
  return {
    categories: [],
    groups: {
      nameList: [],
    },
  };
}

export interface ISupplierSectionProducts {
  sectionList: ISupplierSectionProduct[];
}

export interface ISupplierSectionProduct {
  id: string;
  name: string;
  priority: number;
  productsList: IStoreProduct[];
}

export interface IStoreProduct {
  id: string;
  name: string;
  description: string;
  detail: string;
  cost: ICost;
  images: IProductsImages;
  active: boolean;
  status: string;
  available: boolean;
}

export function emptySupplierSectionProducts(): ISupplierSectionProducts {
  return {
    sectionList: [],
  };
}

export function emptyIStoreProduct(): IStoreProduct {
  return {
    id: "",
    name: "",
    description: "",
    detail: "",
    cost: {
      currency: "",
      ammount: 0,
    },
    images: emptyIProductsImages(),
    active: false,
    status: "",
    available: false,
  };
}

import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { ErrorMessage } from "../ErrorMessage";
import { Label } from "./Label";

type Props = {
  title: string;
  options: string[];
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  isError?: boolean;
  errorMessage?: string;
  placeholder?: string;
};

export function Select(props: Readonly<Props>) {
  const { title, value, isError, errorMessage, placeholder } = props;

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    props.setValue(e.target.value);
  };

  const textColor = props.options.includes(value)
    ? "text-gray-700"
    : "text-gray-400";

  return (
    <div className="w-full">
      <Label value={title} />

      <div className="relative">
        <select
          className={`appearance-none block w-full bg-gray6 ${textColor} border border-gray-300 rounded-lg py-3 px-4  focus:outline-none focus:bg-white focus:border-gray-300`}
          id="select"
          value={value}
          onChange={handleChange}
        >
          <option>{placeholder}</option>
          {props.options.map((option) => (
            <option key={option} value={option} className="text-gray-700">
              {option}
            </option>
          ))}
        </select>

        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>

      <ErrorMessage isError={isError} message={errorMessage} />
    </div>
  );
}

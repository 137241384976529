import { useLayoutEffect, useRef, useState } from "react";
import { SectionItem } from "./SectionItem";

export interface Section {
  id: string;
  name: string;
}

export interface RefElement {
  id: string;
  ref: React.RefObject<HTMLButtonElement> | React.RefObject<HTMLHeadingElement>;
}

type Props = {
  selectedId: string;
  sections: Section[];
  onSelectSection: (id: string) => void;
};

export function SectionsRow(props: Readonly<Props>) {
  const ref = useRef<HTMLDivElement>(null);
  const [sticky, setSticky] = useState(false);

  const position = sticky ? "sticky top-0" : "relative";

  useLayoutEffect(() => {
    const divTop = ref?.current?.getBoundingClientRect().top;

    const onScroll = () => {
      const shouldBeSticky = Boolean(divTop && divTop < window.scrollY);
      setSticky(shouldBeSticky);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div
      ref={ref}
      className={`flex w-full ${position} px-2 z-50 gap-x-2 py-4 gap-y-2 md:gap-x-8 md:gap-y-4  bg-white overflow-auto scrollbar  scrollbar-thumb-gray-300 scrollbar-h-1`}
    >
      {props.sections.map((section) => (
        <SectionItem
          key={section.id}
          section={section}
          selectedId={props.selectedId}
          onSelect={props.onSelectSection}
        />
      ))}
    </div>
  );
}

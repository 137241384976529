import { useContext } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Colors } from "../../themes";
import Paper from "@mui/material/Paper";
import { Icon } from "@mui/material";
import { ICartItem } from "../../interfaces/IShoppingCart";
import { deleteFromCart } from "../../utils/CartFunctions";
import { useNavigate } from "react-router-dom";
import { INewSupplierAccount } from "../../interfaces/ISuppliersProfile";
import { SimpleButton } from "../Buttons/SimpleButton";
import { AppContext } from "../../context/AppContext";
import { Types } from "../../context/Reducers";

type Props = {
  modalName: string;
  cartItem: ICartItem;
  supplier: INewSupplierAccount;
  setModalName: (value: string) => void;
};

export function DeleteOrderModal(props: Props) {
  const { modalName, setModalName, cartItem, supplier } = props;
  const { state, dispatch } = useContext(AppContext);
  const { cartProducts } = state;
  const navigate = useNavigate();

  const handleClose = () => {
    setModalName("");
  };

  const handleDelete = () => {
    const cartItemId = cartItem.itemId;
    const supplierId = supplier.id;
    const updatedCartState = deleteFromCart(cartProducts, cartItemId);

    dispatch({
      type: Types.SetCartProducts,
      payload: {
        cartProducts: updatedCartState,
      },
    });

    if (Object.keys(state).length === 1) {
      navigate(`/${supplierId}/`);
    } else {
      handleClose();
    }
  };

  const handleBack = () => {
    handleClose();
  };

  const HeaderBar = () => {
    return (
      <Box sx={styles.headerBar} onClick={handleClose}>
        <Icon>close</Icon>
      </Box>
    );
  };

  const Title = () => {
    return (
      <Box sx={styles.title}>
        <Box sx={styles.titleText}>{"¿Eliminar del carrito?"}</Box>
      </Box>
    );
  };

  const Body = () => {
    return (
      <Box sx={styles.body}>
        <Box sx={styles.bodyText}>{"Se eliminará este producto"}</Box>
        <Box sx={styles.bodyText}>{"de tu carrito de compras."}</Box>
      </Box>
    );
  };

  const Footer = () => {
    return (
      <Box sx={styles.footer}>
        <Box sx={styles.buttonsContainer}>
          <Box sx={styles.buttonContainer}>
            <SimpleButton
              color={Colors.WHITE}
              bgColor={Colors.GRAY2}
              onClick={handleBack}
              text={"Regresar"}
            />
          </Box>
          <Box sx={styles.buttonContainer}>
            <SimpleButton
              color={Colors.WHITE}
              bgColor={Colors.PRIMARYSUPPLIER}
              onClick={handleDelete}
              text={"Eliminar"}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Modal
      open={modalName === "deleteOrderModal"}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={styles.modalStyles}
    >
      <Paper elevation={0} square sx={styles.paper}>
        <Box sx={styles.modalInnerContainer} style={{ position: "relative" }}>
          <HeaderBar />
          <Box sx={styles.modalMainContainer}>
            <Title />
            <Body />
          </Box>
          <Footer />
        </Box>
      </Paper>
    </Modal>
  );
}

const styles = {
  modalStyles: {
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    height: "auto",
    width: { xs: "80%", sm: "50%", md: "30%" },
    borderRadius: 4,
  },
  modalInnerContainer: {
    background: Colors.WHITE,
    alignSelf: "center",
    padding: { xs: "3%" },
    borderRadius: 4,
  },
  modalMainContainer: {
    display: "flex",
    flexDirection: { xs: "column" },
    marginBottom: "8%",
  },
  modalHeader: {
    paddingLeft: "4%",
    fontWeight: 600,
    fontSize: {
      xs: "1.2rem",
      lg: "1.3rem",
    },
    display: "flex",
    justifyContent: "flex-end",
  },
  headerBar: {
    position: "sticky",
    top: 0,
    height: "8%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    paddingX: "1%",
    paddingY: { xs: "0%", md: "1%" },
    justifyContent: "flex-end",
    zIndex: 9999,
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: { xs: "3%", md: "2%" },
  },
  titleText: {
    color: Colors.BLACK,
    fontSize: "1.5rem",
    fontWeight: 600,
    textAlign: "center",
  },
  body: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: "8%",
  },
  bodyText: {
    color: Colors.BLACK,
    textAlign: "center",
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  footer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonsContainer: {
    width: "100%",
    display: "flex",
    height: "100%",
    justifyContent: "space-between",
    gap: "3%",
  },
  buttonContainer: {
    width: "50%",
  },
};

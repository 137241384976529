import { AppBar, Button, Typography, styled } from "@mui/material";
import { Colors } from "../../themes";
import { Box } from "@material-ui/core";
import { OrderButton } from "./OrderButton";

type Props = {
  showCartOrder?: boolean;
};

function Footer(props: Readonly<Props>) {
  return (
    <CustomAppBar position="fixed" color="default">
      <OrderButton show={props.showCartOrder} />

      <Box
        onClick={() => window.open("https://onelink.to/v8crny")}
        textAlign="center"
        sx={{
          boxShadow: 2,
          py: 1,
          color: Colors.BLACK,
          ...{ backgroundColor: Colors.FOOTER_GREEN }, // Aplica color verde si es la última Box
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: 16 }}>
          ¡Descarga Fuddis y ahorra en el envío!
        </Typography>

        <Button
          variant="text"
          color="primary"
          sx={{
            fontSize: 13,
            textTransform: "initial",
            fontFamily: "Montserrat",
          }}
          onClick={() => window.open("https://onelink.to/v8crny")}
        >
          Ordena desde la app y paga menos por el envío.
        </Button>
      </Box>
    </CustomAppBar>
  );
}

const CustomAppBar = styled(AppBar)(({ theme, color = "primary" }) => ({
  backgroundColor: Colors.BACKGROUND,
  top: "auto",
  bottom: 0,
  zIndex: 20,
}));

export default Footer;

import { styled, TextField, TextFieldProps } from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../../themes";

const TextInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
  width: "100%",
  backgroundColor: Colors.GRAY6,
  borderRadius: 8,
  "& .Mui-focused": {
    backgroundColor: Colors.GRAY6,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: Colors.GRAY5,
    },
    "&:hover fieldset": {
      borderColor: Colors.GRAY5,
    },
    "&.Mui-focused fieldset": {
      borderColor: Colors.PRIMARYUSER,
    },
  },
}));

function CustomTextInput(props: {
  title: string;
  value: string;
  setValue: (value: string) => void;
}) {
  const { value, setValue } = props;

  const handleChange = (e: { target: { value: string } }) => {
    setValue(e.target.value);
  };

  return (
    <Box component="form" sx={styles.boxContainer}>
      <Box sx={styles.box}>{props.title}</Box>

      <TextInput
        id={props.value}
        variant="outlined"
        value={value}
        onChange={handleChange}
      />
    </Box>
  );
}

export default CustomTextInput;

const styles = {
  boxContainer: {
    width: "90%",
    paddingLeft: "5%",
  },
  box: {
    paddingTop: "2%",
    fontSize: {
      xs: "1rem",
      sm: "1rem",
      md: "1.1rem",
      lg: "1.2rem",
    },
    paddingBottom: "1%",
  },
};

import { Box } from "@mui/material";
import { Colors } from "../../../themes";
import { SpinnerButton } from "../../Buttons/SpinnerButton";
import { CartButton } from "./CartButton";

import {
  ICost,
  IProductComplementItemsRes,
  IProductConfigRes,
  IStoreProduct,
} from "../../../interfaces/IProducts";

type Props = {
  cost: ICost;
  product: IStoreProduct;
  complements: {
    [key: string]: IProductComplementItemsRes[];
  };
  quantity: number;
  configurations: IProductConfigRes[];
  errorIndexes: number[];
  setQuantity: (value: number) => void;
  setErrorIndexes: (value: number[]) => void;
  handleClose: () => void;
};

export function ModalFooter(props: Props) {
  const {
    cost,
    product,
    configurations,
    complements,
    quantity,
    errorIndexes,
    setErrorIndexes,
    setQuantity,
    handleClose,
  } = props;

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const Spinner = () => {
    return (
      <Box sx={styles.spinnerButtonContainer}>
        <SpinnerButton
          handleDecrease={handleDecrement}
          handleIncrease={handleIncrement}
          counter={quantity}
          color={Colors.WHITE}
          bgColor={"gradient"}
          border={"primary"}
        />
      </Box>
    );
  };

  const CartBtn = () => {
    if (quantity > 0) {
      return (
        <Box sx={styles.cartBtnContainer}>
          <CartButton
            quantity={quantity}
            configurations={configurations}
            complements={complements}
            product={product}
            setErrorIndexes={setErrorIndexes}
            cost={cost}
            handleClose={handleClose}
          />
        </Box>
      );
    }
    return null;
  };

  const RequiredComplements = () => {
    if (errorIndexes.length > 0) {
      return (
        <Box sx={styles.requiredComplementsContainer}>
          <p style={styles.requiredComplementsText}>
            {"Selecciona complementos obligatorios"}
          </p>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={styles.footerContainer} className="modal-footer">
      <RequiredComplements />
      <Box sx={styles.buttonsContainer}>
        <Spinner />
        <CartBtn />
      </Box>
    </Box>
  );
}

const styles = {
  footerContainer: {
    backgroundColor: { xs: Colors.WHITE, sm: Colors.GRAY6 },
    paddingX: {
      xs: "3%",
      md: "3%",
    },
    paddingY: {
      xs: "2%",
      md: "1%",
    },
  },
  requiredComplementsContainer: {
    display: "flex",
    justifyContent: { xs: "center", md: "flex-end" },
    marginBottom: {
      xs: "2%",
      md: "2%",
    },
  },
  requiredComplementsText: {
    margin: 0,
    color: Colors.ERROR,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    width: { xs: "100%" },
    justifyContent: { xs: "center", md: "flex-end" },
    alignItems: { xs: "center" },
  },
  spinnerButtonContainer: {
    display: "flex",
    maxHeight: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: "200px",
    marginBottom: {
      xs: "2%",
      md: "0%",
    },
  },
  cartBtnContainer: {
    width: { xs: "100%", md: "auto" },
    marginLeft: { xs: "0%", md: "3%" },
  },
};

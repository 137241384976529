import { ICartItem } from "../interfaces/IShoppingCart";
import { extractComplementItems, formatComplementItemsList } from "./Functions";

export const generateMessage = (
  products: string,
  supplierName: string,
  address: string,
  deliveryType: string,
  cost: string,
  customerName: string
) => {
  return `¡Hola ${supplierName}! Soy ${customerName}.
Vi tu tienda en línea en Fuddis.

*Tipo de servicio*: ${deliveryType}
*Dirección de entrega*: ${address}.

*Pedido*
${products}
${cost}`;
};

const generateComplementsMessage = (cartItem: ICartItem) => {
  let message = "\nComplementos\n";

  const complements = cartItem.productComplements;
  const complementItems = extractComplementItems(complements);
  const formattedComplements = formatComplementItemsList(complementItems);

  if (formattedComplements.length === 0) {
    return "\nSin Complementos\n";
  }

  formattedComplements.forEach((comp) => {
    const complementPrice = comp.price.toFixed(2);
    message += `--- Con ${comp.quantity} de ${comp.name} ($${complementPrice} MXN)\n`;
  });

  return message;
};

export const generateProductsMessage = (items: ICartItem[]) => {
  let message = "";

  items.forEach((item) => {
    const quantity = item.quantity;
    const productName = item.product.name;
    const productCost = item.product.cost.ammount.toFixed(2);
    message += `- Cantidad *${quantity}* de *${productName}* $${productCost} MXN`;
    message += generateComplementsMessage(item);
  });

  return message;
};

export const generateProductsCostMessage = (
  total: number,
  type: string,
  deliveryCost: number
) => {
  let message = "";
  const orderTotal = total + deliveryCost;
  const totalToPay = orderTotal.toFixed(2);

  message += `Gracias.`;
  return message;
};

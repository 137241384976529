import { Box } from "@mui/material";
import { DeliveryTypesText } from "../../constants/Delivery";
import { IDeliveryInfo } from "../../interfaces/ICustomer";
import { Colors } from "../../themes";

type Props = {
  type: string;
  deliveryInfo: IDeliveryInfo;
};

export const DeliveryInfo = (props: Props) => {
  const { type, deliveryInfo } = props;

  let text = "";
  let textStyles = styles.deliveryCostText;

  if (type === DeliveryTypesText.toUserAddress) {
    if (deliveryInfo.available) {
    } else if (deliveryInfo.checked) {
      textStyles = { ...textStyles, color: Colors.ERROR };
      text = "Envío a domicilio no está disponible en esta zona";
    }
  }
  return <Box sx={textStyles}>{text}</Box>;
};

const styles = {
  deliveryCostText: {
    fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
    fontWeight: 600,
    color: Colors.TEXT,
  },
};

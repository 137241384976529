import { useEffect, useContext, useState } from "react";
import { SupplierService } from "../../../services";
import { AppContext } from "../../../context/AppContext";
import { IMedia, ReservationInfo } from "../../../interfaces/IPosts";
import { Grid } from "@mui/material";
import { Colors } from "../../../themes";
import { LinkedProductModal } from "./LinkedProductModal";
import {
  IProductMenu,
  createEmptyIProduct,
} from "../../../interfaces/IProducts";
import { ProductComplementsModal } from "../../ProductsGrid/ProductComplementsModal";
import { ReservationModal } from "./ReservationModal";
import { ReservationFeedbackModal } from "./ReservationFeedbackModal";
import { PostList } from "./PostList";

const initProductState = createEmptyIProduct();

const initReservationInfo: ReservationInfo = {
  date: "",
  numberOfPeople: "",
  time: "",
};

export function News() {
  const { state } = useContext(AppContext);
  const [posts, setPosts] = useState<IMedia[]>([]);
  const [linkedProductId, setLinkedProductId] = useState("");
  const [LinkedProduct, setLinkedProduct] = useState(initProductState);
  const [reservationInfo, setReservationInfo] = useState(initReservationInfo);
  const [modalName, setModalName] = useState("");
  const [loading, setLoading] = useState(false);

  const supplierId = state.supplierAccount.id;

  useEffect(() => {
    const getPosts = async () => {
      setLoading(true);
      const res = await SupplierService.getSupplierPosts(supplierId);
      setLoading(false);

      if (res.postsList.length > 0) {
        setPosts(res.postsList);
      }
    };

    void getPosts();
  }, [supplierId]);

  const showLinkedProduct = (productId: string) => {
    setLinkedProductId(productId);
    setModalName("linkedProductModal");
  };

  const closeLinkedProductModal = () => {
    setLinkedProductId("");
    setModalName("");
  };

  const addLinkedProductToCart = (linkedProduct: IProductMenu) => {
    setLinkedProduct({ ...initProductState, ...linkedProduct });
    setModalName("productComplements");
  };

  const sendReservationInfo = (info: ReservationInfo) => {
    setModalName("reservationFeedbackModal");
    setReservationInfo(info);
  };

  const ModalFactory = () => {
    switch (modalName) {
      case "linkedProductModal":
        return (
          <LinkedProductModal
            modalName={modalName}
            productId={linkedProductId}
            addLinkedProductToCart={addLinkedProductToCart}
            closeModal={closeLinkedProductModal}
          />
        );
      case "productComplements":
        return (
          <ProductComplementsModal
            modalName={modalName}
            product={LinkedProduct}
            setModalName={setModalName}
          />
        );
      case "reservationModal":
        return (
          <ReservationModal
            modalName={modalName}
            closeModal={closeLinkedProductModal}
            sendReservationInfo={sendReservationInfo}
          />
        );
      case "reservationFeedbackModal":
        return (
          <ReservationFeedbackModal
            modalName={modalName}
            reservationInfo={reservationInfo}
            setModaName={setModalName}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid item container xs={12} sx={styles.newsGrid}>
      <PostList
        loading={loading}
        posts={posts}
        setModalName={setModalName}
        showLinkedProduct={showLinkedProduct}
      />
      <ModalFactory />
    </Grid>
  );
}

const styles = {
  newsGrid: {
    backgroundColor: Colors.GRAY6,
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    height: "100%",
    paddingBottom: "20vh",
  },
};

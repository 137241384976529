import {
  signUp,
  autoSignIn,
  signIn,
  signOut,
  confirmSignIn,
  fetchAuthSession,
  SignUpInput,
} from "aws-amplify/auth";
import {
  ICustomer,
  IPostCustomer,
  createEmptyCustomer,
} from "../interfaces/ICustomer";

const customerUrl = process.env.REACT_APP_BASE_URL;

type SignUpParameters = {
  username: string;
  password: string;
  email: string;
  phone_number: string;
};

export async function requestAccess(phoneNumber: string) {
  const params: SignUpInput = {
    username: phoneNumber,
    password: phoneNumber,
    options: {
      userAttributes: {
        phone_number: phoneNumber,
        locale: "52 MX",
        name: "",
      },
    },
  };

  try {
    const res = await signUp(params);

    if (res.nextStep.signUpStep === "DONE") {
      return handleSignIn(phoneNumber);
    }

    return false;
  } catch (error: any) {
    console.error(error);

    if (error.name === "UsernameExistsException") {
      return handleSignIn(phoneNumber);
    }

    return false;
  }
}

export async function handleSignUp({
  username,
  password,
  email,
  phone_number,
}: SignUpParameters) {
  try {
    const { userId } = await signUp({
      username,
      password,
      options: {
        userAttributes: {
          email,
          phone_number, // E.164 number convention
        },
        // optional
        autoSignIn: true, // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
      },
    });

    console.log(userId);
  } catch (error) {
    console.log("error signing up:", error);
  }
}

export async function handleAutoSignIn() {
  try {
    const signInOutput = await autoSignIn();

    console.log(signInOutput);

    // If the user is signed in, the signInOutput.isSignedIn property will be true
    // and the user's information will be available in signInOutput.user
  } catch (error) {
    console.log(error);
  }
}

export async function handleSignIn(phoneNumber: string): Promise<boolean> {
  try {
    // Inicia sesión con el número de teléfono del usuario
    const signInResponse = await signIn({
      username: phoneNumber,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
      },
    });

    // Si el inicio de sesión requiere confirmación con un código de verificación por SMS
    if (
      signInResponse.nextStep.signInStep ===
      "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
    ) {
      // Aquí puedes continuar con el proceso de confirmación del código de verificación por SMS
      console.log(
        "Se requiere confirmación con código de verificación por SMS."
      );
      // Puedes redirigir al usuario a una pantalla para ingresar el código de verificación
    } else if (signInResponse.nextStep.signInStep === "DONE") {
      // El inicio de sesión se ha completado exitosamente
      console.log("Inicio de sesión exitoso.");
    } else {
      // Otro tipo de siguiente paso que podría requerir manejo adicional
      console.log("Próximo paso:", signInResponse.nextStep);
    }

    return false;
  } catch (error: any) {
    if (error.name === "UserAlreadyAuthenticatedException") {
      console.log("El ya inicio sesión.");
      return true;
    } else if (error.name === "UserNotConfirmedException") {
      // El usuario no ha confirmado su cuenta
      console.log("El usuario no ha confirmado su cuenta.");
    }
    console.error("Error al iniciar sesión:", error);

    return false;
  }
}
export async function handleSignOut() {
  try {
    await signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

export async function answerCustomChallenge(challengeResponse: string) {
  try {
    const confirmSignInOutput = await confirmSignIn({
      challengeResponse,
    });

    return confirmSignInOutput.isSignedIn;
  } catch (error) {
    console.error("error answering custom challenge:", error);
    return false;
  }
}

export async function getToken(): Promise<string> {
  try {
    const { tokens } = await fetchAuthSession();

    return tokens?.accessToken?.toString() ?? "";
  } catch (err) {
    console.log(err);
    return "";
  }
}

export async function getCustomer(): Promise<ICustomer> {
  const token = await getToken();

  if (token) {
    return fetch(customerUrl + "/customer", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "get",
    })
      .then((res) => res.json())
      .then((res: ICustomer) => {
        return res;
      })
      .catch(() => {
        return createEmptyCustomer();
      });
  } else {
    return createEmptyCustomer();
  }
}

export async function getSub(): Promise<string> {
  try {
    const session = await fetchAuthSession();
    return session.userSub ?? "";
  } catch (e) {
    console.error(e);
    return "";
  }
}

export async function createCustomer(info: IPostCustomer): Promise<ICustomer> {
  const token = await getToken();
  const requestBody = JSON.stringify(info);

  return fetch(customerUrl + "/customer", {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    body: requestBody,
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }

      return createEmptyCustomer();
    })
    .then((response: ICustomer) => {
      if (response) {
        return response;
      }
      return createEmptyCustomer();
    })
    .catch((error) => {
      console.error(error);
      return createEmptyCustomer();
    });
}

export async function checkIsActiveSession() {
  try {
    const { tokens } = await fetchAuthSession();

    return tokens?.accessToken?.toString() !== "";
  } catch (e) {
    return false;
  }
}

import {
  IProductComplementItemsRes,
  IProductConfigRes,
  ICost,
} from "../../../interfaces/IProducts";
import { CheckOrRadioConfig } from "./CheckOrRadioConfig";
import { PlusConfiguration } from "./PlusConfiguration";

type Props = {
  config: IProductConfigRes;
  listIndex: number;
  cost: ICost;
  setCost: (val: ICost) => void;
  setComplementsSection: (
    options: IProductComplementItemsRes[],
    section: string
  ) => void;
  localComplements: {
    [key: string]: IProductComplementItemsRes[];
  };
  errorIndexes: number[];
  setErrorIndexes: (value: number[]) => void;
};

export function RenderConfig(props: Props) {
  const {
    config,
    listIndex,
    cost,
    localComplements,
    errorIndexes,
    setErrorIndexes,
    setCost,
    setComplementsSection,
  } = props;

  const localOptions = localComplements[config.title] ?? [];

  const isCheckConfiguration = (configuration: IProductConfigRes) => {
    return (
      configuration.isUnique &&
      configuration.maxquantity > configuration.minquantity
    );
  };

  const isRadioConfiguration = (configuration: IProductConfigRes) => {
    return (
      configuration.isUnique &&
      configuration.maxquantity === configuration.minquantity &&
      configuration.maxquantity === 1
    );
  };

  const isPlusConfiguration = (configuration: IProductConfigRes) => {
    return (
      !configuration.isUnique &&
      configuration.maxquantity >= configuration.minquantity
    );
  };

  if (isRadioConfiguration(config)) {
    return (
      <CheckOrRadioConfig
        configuration={config}
        listIndex={listIndex}
        borderRadius={50}
        localOptions={localOptions}
        cost={cost}
        setCost={setCost}
        setComplementsSection={setComplementsSection}
        errorIndexes={errorIndexes}
        setErrorIndexes={setErrorIndexes}
      />
    );
  } else if (isCheckConfiguration(config)) {
    return (
      <CheckOrRadioConfig
        configuration={config}
        listIndex={listIndex}
        borderRadius={10}
        localOptions={localOptions}
        cost={cost}
        setCost={setCost}
        setComplementsSection={setComplementsSection}
        errorIndexes={errorIndexes}
        setErrorIndexes={setErrorIndexes}
      />
    );
  } else if (isPlusConfiguration(config)) {
    return (
      <PlusConfiguration
        configuration={config}
        listIndex={listIndex}
        localOptions={localOptions}
        cost={cost}
        setCost={setCost}
        setComplementsSection={setComplementsSection}
        errorIndexes={errorIndexes}
        setErrorIndexes={setErrorIndexes}
      />
    );
  }
  return null;
}

import { Box, Button, ButtonProps, styled } from "@mui/material";
import React from "react";
import { Colors } from "../../themes";
import { useNavigate } from "react-router-dom";
const NewButton = styled(Button)<ButtonProps>(
  ({ theme, color = "primary" }) => ({
    borderRadius: 8,
    textTransform: "none",
    color: Colors.WHITE,
    width: "100%",

    "&.MuiButton-containedPrimary": {
      borderRadius: 8,
      textTransform: "none",
      color: Colors.PRIMARYSUPPLIER,
      width: "100%",
    },
    "&.MuiButton-root": {
      borderRadius: 8,
      textTransform: "none",
      color: Colors.WHITE,
      width: "100%",
      backgroundColor: Colors.PRIMARYSUPPLIER,
      fontFamily: "Montserrat",
      fontSize: "1.2rem",
      fontWeight: 500,
    },
  })
);

function CustomBack(props: { text: string }) {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(-1);
  };
  return (
    <Box sx={{ height: "100%", maxWidth: "100%" }}>
      <NewButton variant="contained" color="primary" onClick={onClick}>
        <p style={{ margin: 0 }}>{props.text}</p>
      </NewButton>
    </Box>
  );
}

export default CustomBack;

import { Grid } from "@mui/material";
import { IStoreProduct } from "../../../interfaces/IProducts";
import ProductGridItem from "../../../components/ProductsGrid/ProductGridItem";

type Props = {
  selectedId: string;
  products: IStoreProduct[];
  handleSelectProduct: (product: IStoreProduct) => void;
};

export function Products(props: Readonly<Props>) {
  return (
    <Grid item container xs={12} mb={20}>
      {props.products.map((product, index: number) => {
        return (
          <ProductGridItem
            key={product.id}
            product={product}
            handleSelectProduct={props.handleSelectProduct}
          />
        );
      })}
    </Grid>
  );
}

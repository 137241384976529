import { Select, MenuItem, SelectChangeEvent, Box } from "@mui/material";
import { Colors } from "../../../themes";
import { ErrorMessage } from "../../inputs/ErrorMessage";
import { IServiceDate } from "../../../interfaces/IProducts";

export function DatesSelectorInput(props: {
  title: string;
  options: IServiceDate[];
  value: IServiceDate;
  setValue: (value: IServiceDate) => void;
  isError?: boolean;
  errorMessage?: string;
  placeholder?: string;
}) {
  const {
    title,
    value,
    isError,
    errorMessage,
    placeholder,
    options,
    setValue,
  } = props;

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;

    const date = options.find((date) => date.displayDate === value);

    if (date) {
      setValue(date);
    }
  };

  const getPlaceholder = () => {
    return placeholder ?? "Selecciona una opción";
  };

  const getRenderValue = (value: string) => {
    if (value?.length) {
      if (Array.isArray(value)) {
        return value.join(", ");
      } else {
        return value;
      }
    }
    return getPlaceholder();
  };

  const textColor = () => {
    return props.value.displayDate === ""
      ? "select-grey-placeholder"
      : "select-black-placeholder";
  };

  return (
    <Box sx={styles.selectContainer}>
      <Box sx={styles.inputLabel}>{title}</Box>
      <Select
        labelId="custom-select-label"
        id="custom-select"
        value={value.displayDate}
        displayEmpty
        onChange={handleChange}
        sx={styles.selectStyle}
        renderValue={(value) => getRenderValue(value)}
        className={textColor()}
        MenuProps={{
          sx: {
            maxHeight: 200,
            width: "100%",
          },
        }}
      >
        {props.options.map((option, index) => (
          <MenuItem key={index} value={option.displayDate}>
            {option.displayDate}
          </MenuItem>
        ))}
      </Select>
      <ErrorMessage isError={isError} message={errorMessage} />
    </Box>
  );
}

const styles = {
  selectContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    maxWidth: "100%",
  },
  selectStyle: {
    display: "flex",
    flex: 1,
    "&.MuiInputBase-root": {
      backgroundColor: Colors.GRAY6,
      borderRadius: 2,
      fontFamily: "Montserrat",
      fontSize: "0.9rem",
      fontWeight: 500,
      paddingLeft: "1rem",
      border: `1px solid ${Colors.P_DARK}`,
      lineHeight: "none",
      minHeight: "0",
      height: "3rem",
      maxHeight: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  inputLabel: {
    fontSize: {
      xs: "0.9rem",
      sm: "1rem",
    },
    marginBottom: "0.3rem",
  },
};

import React, { useContext, useState } from "react";
import { Box, Grid } from "@mui/material";
import { ICartItem, createEmptyCartItem } from "../../interfaces/IShoppingCart";
import { ISupplierRules } from "../../interfaces/ISuppliersProfile";
import { Colors } from "../../themes";
import DetailsForm from "./DetailsForm";
import { INewSupplierAccount } from "../../interfaces/ISuppliersProfile";
import { CartOrderCard } from "../../components/CartOrder/CartOrderCard";
import { DeleteOrderModal } from "../../components/CartOrder/DeleteOrderModal";
import CustomBack from "../../components/Buttons/CustomBack";
import { AppContext } from "../../context/AppContext";

function CompleteOrder(props: {
  rules: ISupplierRules;
  supplier: INewSupplierAccount;
}) {
  const { state } = useContext(AppContext);
  const [modalName, setModalName] = useState("");
  const [cartItem, setCartItem] = useState(createEmptyCartItem());

  const { cartProducts } = state;

  const productsKeys = {
    keys: Object.keys(cartProducts),
    products: Object.values(cartProducts),
  };

  const handleSelectCartItem = (cartItem: ICartItem) => {
    setModalName("deleteOrderModal");
    setCartItem(cartItem);
  };

  const CartOrders = () => {
    return (
      <Box sx={styles.cartOrders}>
        {productsKeys.products.map((item, idx) => {
          return (
            <CartOrderCard
              cartProduct={item}
              key={item.itemId}
              handleSelectCartItem={handleSelectCartItem}
            />
          );
        })}
      </Box>
    );
  };

  const DeleteOrder = () => {
    if (modalName === "deleteOrderModal") {
      return (
        <DeleteOrderModal
          modalName={modalName}
          cartItem={cartItem}
          setModalName={setModalName}
          supplier={props.supplier}
        />
      );
    }
    return null;
  };

  const Title = () => {
    return <Box sx={styles.orderTitle}>{"Completa tu pedido"}</Box>;
  };

  return (
    <Grid container sx={{ height: "100%", mt: "2%", mb: "5rem" }}>
      <Grid item md={2} />
      <Grid xs={12} md={6} xl={4} item container sx={{ flex: 1 }}>
        <Box
          boxShadow={2}
          style={{
            flex: 1,
            backgroundColor: Colors.BACKGROUND,
            borderRadius: 16,
          }}
        >
          <Box sx={styles.backContainer}>
            <CustomBack text="Volver al menú" />
          </Box>

          <Title />

          <CartOrders />
          <DetailsForm rules={props.rules} supplier={props.supplier} />
          <DeleteOrder />
        </Box>
      </Grid>
    </Grid>
  );
}

export default CompleteOrder;

const styles = {
  cartOrders: {
    flex: 1,
    padding: "2%",
    display: "flex",
    flexDirection: "column",
  },
  orderTitle: {
    padding: "2%",
    paddingTop: "4%",
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  backContainer: {
    marginX: "2%",
    marginTop: "3%",
  },
};

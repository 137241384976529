import { styled, SvgIcon } from "@material-ui/core";
import { Colors } from "../../themes";

const SvgImage = styled(SvgIcon)(() => ({
  height: "20px",
  width: "20px",
}));

export function Cart() {
  return (
    <SvgImage
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.99996 4.914L0.756958 1.672L2.17196 0.257004L5.41396 3.5H20.656C20.8118 3.49999 20.9655 3.53642 21.1048 3.60637C21.2441 3.67632 21.3651 3.77787 21.4582 3.9029C21.5513 4.02793 21.6138 4.17299 21.6409 4.32649C21.6679 4.47999 21.6587 4.63769 21.614 4.787L19.214 12.787C19.1522 12.9931 19.0257 13.1738 18.8531 13.3022C18.6805 13.4307 18.4711 13.5 18.256 13.5H5.99996V15.5H17V17.5H4.99996C4.73474 17.5 4.48039 17.3946 4.29285 17.2071C4.10531 17.0196 3.99996 16.7652 3.99996 16.5V4.914ZM5.99996 5.5V11.5H17.512L19.312 5.5H5.99996ZM5.49996 21.5C5.10213 21.5 4.7206 21.342 4.4393 21.0607C4.15799 20.7794 3.99996 20.3978 3.99996 20C3.99996 19.6022 4.15799 19.2206 4.4393 18.9393C4.7206 18.658 5.10213 18.5 5.49996 18.5C5.89778 18.5 6.27931 18.658 6.56062 18.9393C6.84192 19.2206 6.99996 19.6022 6.99996 20C6.99996 20.3978 6.84192 20.7794 6.56062 21.0607C6.27931 21.342 5.89778 21.5 5.49996 21.5ZM17.5 21.5C17.1021 21.5 16.7206 21.342 16.4393 21.0607C16.158 20.7794 16 20.3978 16 20C16 19.6022 16.158 19.2206 16.4393 18.9393C16.7206 18.658 17.1021 18.5 17.5 18.5C17.8978 18.5 18.2793 18.658 18.5606 18.9393C18.8419 19.2206 19 19.6022 19 20C19 20.3978 18.8419 20.7794 18.5606 21.0607C18.2793 21.342 17.8978 21.5 17.5 21.5Z"
        fill={Colors.WHITE}
      />
    </SvgImage>
  );
}

import { PropsWithChildren } from "react";

type Props = {
  text: string;
  onClick: () => void;
};

export function CustomHeaderButton(props: Readonly<PropsWithChildren<Props>>) {
  return (
    <button
      className="bg-transparent border-purple-500 border-2 rounded-full py-1 px-3 gap-x-1 flex justify-center items-center"
      type="button"
      onClick={props.onClick}
    >
      {props.children}

      <p className="text-black text-sm md:text-base">{props.text}</p>
    </button>
  );
}

import React from "react";
import Grid from "@mui/material/Grid";
import { Colors } from "../themes";
import logo from "../assets/images/logo.png";
import { isMobileDevice, redirectToApp } from "../utils/RedirectFunctions";
import { SimpleButton } from "./Buttons/SimpleButton";
import { Box } from "@mui/system";

export function Header() {
  const isMobile = isMobileDevice();

  const onOpenApp = () => {
    redirectToApp();
  };

  if (!isMobile) {
    return null;
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{
        backgroundColor: Colors.GRAY6,
        padding: "2%",
      }}
    >
      <Grid xs={5} xl={3} item container justifyContent="flex-start">
        <img src={logo} alt="liberet-logo" width="100vw" />
      </Grid>
      <Grid xs={7} xl={4} item container justifyContent="flex-end">
        <Box sx={{ height: "100%" }}>
          <SimpleButton
            onClick={onOpenApp}
            bgColor={Colors.PRIMARYSUPPLIER}
            color={Colors.WHITE}
            text={"Abrir en app"}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

import { useContext } from "react";
import {
  ICost,
  IProductComplementItemsRes,
  IProductConfigRes,
  IStoreProduct,
} from "../../../interfaces/IProducts";
import { createCartItemId, createCartItem } from "../../../utils/CartFunctions";
import { formatProductComplements } from "../../../utils/Functions";
import { ICartProductComplements } from "../../../interfaces/IShoppingCart";
import { AddButton } from "../../Buttons/AddButton";
import { AppContext } from "../../../context/AppContext";
import { Types } from "../../../context/Reducers";

type Props = {
  configurations: IProductConfigRes[];
  complements: {
    [key: string]: IProductComplementItemsRes[];
  };
  product: IStoreProduct;
  quantity: number;
  setErrorIndexes: (value: number[]) => void;
  cost: ICost;
  handleClose: () => void;
};

export function CartButton(props: Props) {
  const {
    quantity,
    product,
    configurations,
    complements,
    cost,
    setErrorIndexes,
    handleClose,
  } = props;
  const { state, dispatch } = useContext(AppContext);
  const { cartProducts } = state;

  const getTotal = () => {
    return (product.cost.ammount + cost.ammount) * quantity;
  };

  const addProductToCart = () => {
    const id = createCartItemId(product);

    const productComplements: ICartProductComplements[] =
      formatProductComplements(complements);

    const newCartState = createCartItem(
      id,
      cartProducts,
      product,
      productComplements,
      complements,
      quantity
    );

    if (Object.keys(newCartState).length > 0) {
      dispatch({
        type: Types.SetCartProducts,
        payload: {
          cartProducts: newCartState,
        },
      });

      handleClose();
    }
  };

  const handleAddToCart = () => {
    const errors = [] as number[];

    configurations.forEach((config, index) => {
      const isRequired = config.isRequired;
      const isFilled = !props.complements[config.title]
        ? false
        : props.complements[config.title].length >= config.minquantity;
      if (isRequired && !isFilled) {
        errors.push(index);
      }
    });

    if (errors.length > 0) {
      setErrorIndexes(errors);
    } else {
      addProductToCart();
    }
  };

  const total = getTotal();

  const message = `Agregar $ ${total.toFixed(2)} ${cost.currency}`;

  if (quantity > 0) {
    return <AddButton handleClick={handleAddToCart} text={message} />;
  }

  return null;
}

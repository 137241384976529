import { Colors } from "../../../themes";
import { Box } from "@mui/material";

type Props = {
  isRequired: boolean;
  title: string;
  subtitle: string;
  isError: boolean;
};

export function HeaderComplements(props: Props) {
  const { isRequired, title, subtitle, isError } = props;

  const mandatory = isRequired ? " (Obligatorio)" : null;

  const color = isError ? Colors.ERROR : Colors.BLACK;

  return (
    <Box sx={styles.headerContainer}>
      <Box sx={{ ...styles.title, color: color }}>
        {title}
        {mandatory}
      </Box>
      <Box sx={styles.subtitle}>{subtitle}</Box>
    </Box>
  );
}

const styles = {
  headerContainer: {
    marginBottom: {
      xs: "1%",
      md: "3%",
    },
  },
  title: {
    margin: 0,
    marginBottom: "1%",
    fontSize: {
      xs: "1rem",
      sm: "1.1rem",
      md: "1.2rem",
      lg: "1.3rem",
    },
    fontWeight: 600,
  },
  subtitle: {
    margin: 0,
    fontSize: {
      xs: "0.9rem",
      sm: "1rem",
      md: "1.1rem",
      lg: "1.2rem",
    },
    fontWeight: 500,
    color: Colors.PRIMARYSUPPLIER,
  },
};

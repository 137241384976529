import {
  INewSupplierAccount,
  IFormatedRule,
} from "../interfaces/ISuppliersProfile";
import { CustomHeaderButton } from "./Buttons/CustomHeaderButton";
import { Header } from "../components/Header";
import Status from "../components/SupplierRulesGrid/Status";
import {
  RiWhatsappLine,
  RiMapPinLine,
  RiShareLine,
  RiTimeLine,
} from "@remixicon/react";
import { Colors } from "../themes";
import { StatusInfo } from "./molecules/StatusInfo";

type Props = {
  supplier: INewSupplierAccount;
  rules: IFormatedRule[];
};

export function SupplierInformation(props: Readonly<Props>) {
  const onShareClick = () => {
    const message = encodeURIComponent(
      `https://users.liberet.app/v1/share?type=supplier&id=${props.supplier.id}`
    );
    const link = `https://wa.me/?text=${message}`;
    window.open(link, "_blank");
  };

  const onAdressClick = () => {
    const link = `https://maps.google.com?q=${lat},${lon}`;
    window.open(link, "_blank");
  };

  const onReserveClick = () => {
    const message = `Hola ${props.supplier.name}, vi tu perfil en Fuddis y me gustaría saber más información acerca de tus productos`;
    const phoneNumber = props.supplier.contactPhone.replace("+", "");
    const url = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(url);
  };

  const lat = props.supplier.address.latitude;
  const lon = props.supplier.address.longitude;

  return (
    <header className="w-full">
      <Header />

      <section className="w-full flex flex-row pt-4 pb-8">
        <img
          src={props.supplier.images.regular}
          alt="supplier logo"
          className=" h-24 w-24  mx-2 object-cover rounded-full"
        />

        <div className="flex flex-row flex-wrap gap-x-16 gap-y-4 items-center px-4">
          <div className="flex flex-col">
            <h1 className="font-bold text-2xl md:text-3xl">
              {props.supplier.name}
            </h1>

            <p className="text-sm md:text-base my-2 max-w-lg">
              {props.supplier.description}
            </p>

            <StatusInfo rules={props.rules} />
          </div>

          <div className="flex flex-wrap gap-x-4 gap-y-2 justify-start ">
            <CustomHeaderButton text="Dirección" onClick={onAdressClick}>
              <RiMapPinLine size={20} color={Colors.TEXT} />
            </CustomHeaderButton>

            <CustomHeaderButton text="Compartir" onClick={onShareClick}>
              <RiShareLine size={20} color={Colors.TEXT} />
            </CustomHeaderButton>

            <CustomHeaderButton text="Contactar" onClick={onReserveClick}>
              <RiWhatsappLine size={20} color={Colors.TEXT} />
            </CustomHeaderButton>
          </div>
        </div>
      </section>
    </header>
  );
}

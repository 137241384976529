export const Colors = {
  WHITE: "#FFFFFF",
  GRAY6: "#F1F1F1",
  GRAY5: "#E0E0E0",
  GRAY4: "#BDBDBD",
  GRAY3: "#828282",
  GRAY2: "#4F4F4F",
  BLACK: "#2B2B2B",
  BLACKMODALS: "rgba(80, 192, 171, 0.2)",
  PRIMARYUSER: "#50C0AB",
  SECONDUSER: "#CDF2EE",
  PRIMARYSUPPLIER: "#9B51E0",
  SECONDARYSUPPLIER: "#EBDCF9",
  HYPERLINKS: "#2F80ED",
  SECONDARYBLUE: "#D5E6FB",
  ERROR: "#EB5757",
  ERRORBG: "#FBDDDD",
  GRIDLAYOUT: "rgba(255, 0, 0, 0.05)",

  BACKGROUND: "#FFFFFF",
  TEXT_ON_COLOR: "#FFFFFF",
  TEXT: "#2B2B2B",
  P_DARK: "#CCCCCC",

  PRIMARYUSER_BG: "rgba(80, 192, 171, 0.2)",
  COVERAGE_FILL: "rgba(155, 81, 224, 0.2)",
  COVERAGE_STROKE: "rgba(155, 81, 224, 0.005)",
  CYAN_BLUE: "rgba(46, 137, 255, 0.3)",
  LIGHT_BLUE: "#4990EF",
  LIGHT_GREEN: "#6FCF97",
  LIGHT_GRAY: "#D4D4D4",
  LIGHTER_GRAY: "#e5e5e5",
  FOOTER_GREEN: "#DCF2EE",

  P_DISABLED: "#F5F5F5",
  PRISTINE_GRAY: "#959595",
  SECONDARY_LIT: "rgba(252, 171, 63, 0.5)",
  SECONDARY_LITTER: "rgba(252, 171, 63, 0.3)",
  SECONDARY: "#FCAB3F",
  SECONDARY_BUTTON: "#EBAC2F",
  SECONDATY_LIGHT: "#FEEED9",
  SNOMO: "#DCF2EE",
  SUBTLE_GRAY: "#F2F2F2",
  TEXTSIX: "rgba(43, 43, 43, 0.6)",
  TRASNPARENT: "rgba(52, 52, 52, 0)",

  MESSENGER: "#BB6BD9",
  FACEBOOK: "#2D9CDB",
  INSTA: "#F24CB0",
  WHATSAPP: "#6FCF97",
};

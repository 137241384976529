import React, { useEffect, useState } from "react";
import { CircularProgress, Typography, Container, Button } from "@mui/material";

const ProcessingScreen = ({ onComplete }: { onComplete: () => void }) => {
  const [processCompleted, setProcessCompleted] = useState(false);

  useEffect(() => {
    // Agregar una clase al body al montar el componente
    document.body.classList.add("processing-screen-body");

    return () => {
      // Eliminar la clase del body al desmontar el componente
      document.body.classList.remove("processing-screen-body");
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setProcessCompleted(true); // Marca el proceso como completado después de 10 segundos
      onComplete(); // Llama a la función onComplete después de 10 segundos
    }, 10000); // 10000 milisegundos = 10 segundos

    return () => clearTimeout(timer); // Limpia el temporizador al desmontar el componente
  }, [onComplete]);

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      fontFamily: "Inter, Arial, sans-serif",
      color: "#f6f6f6",
    },
    content: {
      textAlign: "center" as const,
      padding: "20px",
      borderRadius: "10px",
      background:
        "linear-gradient(133.07deg, rgba(255, 255, 255, .2) 0%, rgba(255, 255, 255, 0) 50.52%, rgba(255, 255, 255, .2) 100%)",
      boxShadow: "0 0 20px rgba(0, 0, 0, 0.4)",
    },
    button: {
      padding: "10px 20px",
      fontSize: "18px",
      fontWeight: "bold",
      backgroundColor: "#9B51DF",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      marginTop: "20px",
    },
  };

  return (
    <Container
      maxWidth="sm"
      style={{ ...styles.container, background: "#121214" }}
    >
      <div style={styles.content}>
        {!processCompleted ? (
          <>
            <Typography variant="h4" gutterBottom>
              Procesando Información
            </Typography>
            <CircularProgress style={{ margin: "20px" }} />
            <Typography variant="body1">
              Por favor, espere mientras su información está siendo procesada...
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h4" gutterBottom>
              Proceso Terminado
            </Typography>
            <Typography variant="body1">
              El proceso ha terminado. Puede descargar la aplicación de Fuddis:
            </Typography>
            <Button
              variant="contained"
              style={styles.button}
              onClick={() => window.open("https://onelink.to/v8crny")}
            >
              Descargar Fuddis
            </Button>
          </>
        )}
      </div>
    </Container>
  );
};

export default ProcessingScreen;

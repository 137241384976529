import Modal from "@material-ui/core/Modal";
import { Box } from "@material-ui/core";
import { Colors } from "../../themes";
import checkMark from "../../assets/images/checkMark.png";
import CustomSmallButton from "../Buttons/CustomSmallButton";

const SuccessModal = (props: {
  modalName: string;
  phoneNumber: string;
  handleClose: () => void;
  setModalName: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const onClose = () => {
    return props.handleClose();
  };

  const Image = () => {
    return (
      <Box sx={styles.buttonsContainer}>
        <Box>
          <img src={checkMark} alt="" style={styles.image} />
        </Box>
      </Box>
    );
  };

  const Exit = () => {
    return (
      <div style={styles.exitContainer} onClick={onClose}>
        <div style={styles.exit}>x</div>
      </div>
    );
  };

  const Title = () => {
    return (
      <div style={styles.titleContainer}>
        <Box sx={styles.title}>{`¡Todo listo!`}</Box>
      </div>
    );
  };

  const Description = () => {
    return (
      <div
        style={{
          textAlign: "center",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        <Box sx={styles.description}>
          <p> {"Recibirás un SMS al:"}</p>
          <span style={styles.bold}>{`+52 ${props.phoneNumber}`}</span>
        </Box>
      </div>
    );
  };

  const Button = () => {
    return (
      <Box sx={styles.buttonsContainer}>
        <Box sx={styles.button}>
          <CustomSmallButton
            text="Regresar a menú"
            bgColor={Colors.PRIMARYSUPPLIER}
            color="primary"
            onClick={props.handleClose}
          />
        </Box>
      </Box>
    );
  };

  const TopBox = () => {
    return (
      <Box>
        <Exit />
        <Title />
        <Description />
        <Image />
      </Box>
    );
  };

  return (
    <Modal
      style={styles.modal}
      open={props.modalName === "AffiliateSuccess"}
      onClose={props.handleClose}
      closeAfterTransition
    >
      <>
        <Box
          sx={styles.boxModal}
          bgcolor={Colors.BACKGROUND}
          display={"flex"}
          flexDirection={"column"}
        >
          <div
            style={{
              justifyContent: "flex-end",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            <>
              <TopBox />
              <Button />
            </>
          </div>
        </Box>
      </>
    </Modal>
  );
};

export default SuccessModal;

const styles = {
  error: {
    paddingLeft: "5%",
    color: Colors.ERROR,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingTop: "15%",
    justifyContent: "center",
  },
  button: {
    width: "100%",
    paddingX: "6%",
  },
  exitContainer: {
    display: "flex",
    paddingRight: "3%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  exit: {
    cursor: "pointer",
    paddingTop: "1%",
    fontSize: 25,
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  title: {
    fontSize: {
      xs: "1.5rem",
      md: "32px",
    },
    fontWeight: "bold",
    lineHeight: "50px",
    color: Colors.PRIMARYSUPPLIER,
  },
  description: {
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingTop: {
      xs: "5%",
      md: "8%",
    },
    fontSize: {
      xs: "1.2rem",
      md: "1.5rem",
    },
    fontWeight: "500",
  },
  listContainer: {
    paddingBottom: "1%",
    paddingTop: "1%",
    paddingLeft: "5%",
    paddingRight: "5%",
    fontWeight: "500",
  },
  list: {
    paddingTop: "2%",
    fontSize: {
      xs: "1.2rem",
      md: "1.5rem",
    },
    textAlign: "center",
  },
  bold: {
    fontWeight: "600",
  },
  listItem: {
    padding: 0,
    listStyleType: "none",
  },
  descriptionBottom: {
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: {
      xs: "5%",
      md: "8%",
    },
    paddingBottom: "5%",
    fontSize: {
      xs: "1.2rem",
      md: "1.5rem",
    },
    fontWeight: "700",
  },
  bigBox: {
    display: "flex",
    flexDirection: "row",
  },
  modal: {
    display: "flex",
    justifyContent: "flex-end",
  },
  box: {
    width: "100%",
    paddingX: "12%",
  },
  boxModal: {
    width: {
      xs: "100%",
      md: "48%",
    },
  },
  image: {
    display: "flex",
  },
};

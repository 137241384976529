import React, { useState } from "react";
import {
  emptyIStoreProduct,
  IStoreProduct,
  ISupplierSectionProducts,
} from "../../interfaces/IProducts";
import { ProductComplementsModal } from "./ProductComplementsModal";
import { Section, SectionsRow } from "./sections/SectionsRow";
import { Products } from "../../features/categories/products/Products";

type Props = {
  products: ISupplierSectionProducts;
  modalName: string;
  setModalName: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
};

function ProductsGrid(props: Readonly<Props>) {
  const [modalName, setModalName] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(emptyIStoreProduct());

  const sections: Section[] = props.products.sectionList.map(
    ({ id, name }) => ({
      id,
      name,
    })
  );

  const [selectedSection, setSelectedSection] = useState(sections[0]?.id ?? "");

  const products = props.products.sectionList
    .filter(({ id }) => id === selectedSection)
    .flatMap((item) => item.productsList);

  const handleSelectProduct = (product: IStoreProduct) => {
    setModalName("productComplements");
    setSelectedProduct(product);
  };

  const onSelectSection = (id: string) => setSelectedSection(id);

  const ComplementsModal = () => {
    if (modalName === "productComplements") {
      return (
        <ProductComplementsModal
          modalName={modalName}
          setModalName={setModalName}
          product={selectedProduct}
        />
      );
    }
    return null;
  };

  return (
    <>
      <SectionsRow
        selectedId={selectedSection}
        sections={sections}
        onSelectSection={onSelectSection}
      />

      <Products
        products={products}
        handleSelectProduct={handleSelectProduct}
        selectedId={selectedSection}
      />

      <ComplementsModal />
    </>
  );
}

export default ProductsGrid;

import {
  IProductConfigRes,
  IProductComplementItemsRes,
  ICost,
} from "../../../interfaces/IProducts";
import { useState } from "react";
import { PlusConfigurationItem } from "./PlusConfigurationItem";
import { HeaderComplements } from "./HeaderComplements";
import { getComplementsDescription } from "../../../utils/ComplementsFunctions";
import { Box } from "@mui/material";

type Props = {
  configuration: IProductConfigRes;
  listIndex: number;
  localOptions: IProductComplementItemsRes[];
  cost: ICost;
  setCost: (value: ICost) => void;
  setComplementsSection: (
    options: IProductComplementItemsRes[],
    section: string
  ) => void;
  errorIndexes: number[];
  setErrorIndexes: (value: number[]) => void;
};

export function PlusConfiguration(props: Props) {
  const {
    configuration,
    cost,
    errorIndexes,
    listIndex,
    localOptions,
    setComplementsSection,
    setCost,
  } = props;
  const [selectedCount, setSelectedCount] = useState(localOptions.length);

  const isError = errorIndexes.includes(listIndex);
  const isRequired = configuration.isRequired ?? false;
  const description = getComplementsDescription(configuration);

  const getItemsCount = (item: IProductComplementItemsRes) => {
    return localOptions.filter((option) => option.id === item.id).length;
  };

  const isArrayFull = () => selectedCount === configuration.maxquantity;

  return (
    <>
      <HeaderComplements
        title={configuration.title}
        subtitle={description}
        isRequired={isRequired}
        isError={isError}
      />
      <Box sx={styles.sectionContainer}>
        {configuration.itemsList.map((item, index) => {
          return (
            <PlusConfigurationItem
              complementItem={item}
              key={item.id + index}
              listIndex={props.listIndex}
              selectedCount={selectedCount}
              setSelectedCount={setSelectedCount}
              isArrayFull={isArrayFull()}
              setComplementsSection={setComplementsSection}
              localOptions={localOptions}
              errorIndexes={props.errorIndexes}
              setErrorIndexes={props.setErrorIndexes}
              cost={cost}
              setCost={setCost}
              sectionName={configuration.title}
              count={getItemsCount(item)}
            />
          );
        })}
      </Box>
    </>
  );
}

const styles = {
  sectionContainer: {
    marginBottom: { xs: "5%", md: "3%" },
  },
};

import { useRef } from "react";
import { Section } from "./SectionsRow";

type Props = {
  selectedId: string;
  section: Section;
  onSelect: (id: string) => void;
};

export function SectionItem(props: Readonly<Props>) {
  const { id, name } = props.section;

  const ref = useRef<HTMLButtonElement>(null);

  const bgColor = props.selectedId === id ? "bg-purple-500" : "bg-white-500";
  const textColor = props.selectedId === id ? "text-white" : "text-gray-800";

  const onClick = () => props.onSelect(id);

  return (
    <button
      key={id}
      ref={ref}
      type="button"
      className={` flex items-center ${bgColor} h-10 border border-purple-500 ${textColor} py-1 md:py-2 px-2 rounded-full focus:outline-none focus:border focus:border-purple-500 font-medium`}
      onClick={onClick}
    >
      <p className="line-clamp-2 text-ellipsis overflow-hidden truncate text-center text-sm md:text-base">
        {name}
      </p>
    </button>
  );
}

import {
  emptyGeoLocationServices,
  IGeoLocationServices
} from '../interfaces/IGeoLocationServices'
import { IHttpService } from './ihttp-service'

const usersUrl = process.env.REACT_APP_BASE_URL

export class GeoLocationServicesService {
  constructor(private httpService: IHttpService) {}

  async getGeoLocationServices(
    latitude: number,
    longitude: number
  ): Promise<IGeoLocationServices> {
    const url = `${usersUrl}/h3/services?latitude=${latitude}&longitude=${longitude}`
    return this.httpService.get(url, emptyGeoLocationServices, 'getGeoServices')
  }
}

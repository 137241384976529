import { Box, Button, ButtonProps, styled } from "@mui/material";
import { Colors } from "../../themes";
import { Cart } from "../../assets/icons/Cart";

const NewButton = styled(Button)<ButtonProps>(
  ({ theme, color = "primary" }) => ({
    borderRadius: 8,
    textTransform: "none",
    color: Colors.WHITE,
    width: "100%",

    "&.MuiButton-containedPrimary": {
      borderRadius: 8,
      textTransform: "none",
      color: Colors.WHITE,
      width: "100%",
    },
    "&.MuiButton-root": {
      borderRadius: 8,
      textTransform: "none",
      color: Colors.WHITE,
      width: "100%",
      background: `linear-gradient(to right, ${Colors.PRIMARYSUPPLIER}, ${Colors.SECONDARY_BUTTON})`,
      fontFamily: "Montserrat",
      fontSize: "1.2rem",
      fontWeight: 500,
    },
  })
);

function CustomOrderButton(props: {
  text: string;
  products: number;
  total: string;
  onClick?: () => void;
  disabled?: boolean;
}) {
  const buttonColor = props.disabled ? Colors.GRAY6 : Colors.PRIMARYUSER;

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        maxHeight: "100%",
        maxWidth: "100%",
      }}
    >
      <NewButton
        variant="contained"
        color="primary"
        onClick={props.onClick}
        sx={{
          backgroundColor: `${buttonColor}`,
          ":disabled": {
            background: `${Colors.GRAY6} `,
          },
        }}
        disabled={props.disabled || props.products === 0}
      >
        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
          <Box>
            <Cart />
            <span className="TextStyles-H2"> {props.products}</span>
          </Box>
          <Box>
            <span className="TextStyles-H3">MXN ${props.total}</span>
          </Box>
          <Box>
            <span className="TextStyles-H2">{props.text}</span>
          </Box>
        </Box>
      </NewButton>
    </Box>
  );
}

export default CustomOrderButton;

import { Box, Grid } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { deliveryType } from "../../constants/DaysDictionary";
import {
  IUserAddress,
  ISupplierRules,
  emptyUserAddress,
  INewSupplierAccount,
} from "../../interfaces/ISuppliersProfile";
import { Select } from "../../components/inputs/standard/Select";
import {
  generateMessage,
  generateProductsCostMessage,
  generateProductsMessage,
} from "../../utils/CreateMessage";
import { getCartTotal } from "../../utils/CartFunctions";
import { Colors } from "../../themes";
import { SimpleButton } from "../../components/Buttons/SimpleButton";
import { emptyDeliveryAddress } from "../../interfaces/ICustomer";
import { getLatLonFromPlaceId } from "../../utils/Functions";
import { GeoLocationServicesService } from "../../services";
import { defaultPaymentGeoService } from "../../constants/PaymentGeoService";
import { IPaymentGeoService } from "../../interfaces/IGeoLocationServices";
import { AppContext } from "../../context/AppContext";
import { DeliveryTypesText } from "../../constants/Delivery";
import { calculateDeliveryCost } from "../../utils/DeliveryFunctions";
import { DeliveryInfo } from "./DeliveryInfo";
import { OrderTotalCost } from "./OrderTotalCost";
import { TextInput } from "../../components/inputs/standard/TextInput";
import { CustomTextField } from "../../components/inputs/CustomTextInput";

export const initDeliveryInfo = () => {
  return {
    available: false,
    cost: 0,
    checked: false,
  };
};

type Props = {
  rules: ISupplierRules;
  supplier: INewSupplierAccount;
};

function DetailsForm(props: Readonly<Props>) {
  const [address, setAddress] = useState<IUserAddress>(emptyUserAddress());
  const { state } = useContext(AppContext);
  const [type, setType] = useState("");
  const [validate, setValidate] = useState(false);
  const [fullName, setFullName] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState(
    emptyDeliveryAddress()
  );
  const [deliveryInfo, setDeliveryInfo] = useState(initDeliveryInfo());
  const [loading, setLoading] = useState(false);

  const types = deliveryType(props.rules.deliveryOptionsList);

  const cart = state.cartProducts;
  const supplierAddress = state.supplierAccount.address;

  const shareBaseUrl = process.env.REACT_APP_SHARE_BASE_URL;

  const cartProducts = Object.values(cart);
  const cartTotal = getCartTotal(cart);
  const orderTotal = cartTotal;

  const validName = fullName !== "";
  const validAddress = address.value.description.trim() !== "";
  const validType = type.trim() !== "" && types.includes(type);
  const validateFullName = fullName.trim() !== "";

  const isValidForm = () => {
    return validAddress && validType && validName;
  };

  const getDeliveryCost = (deliveryPayment: IPaymentGeoService) => {
    const deliveryCost = calculateDeliveryCost(
      deliveryAddress,
      deliveryPayment,
      supplierAddress
    );

    if (deliveryCost > 0) {
      setDeliveryInfo({
        available: true,
        cost: deliveryCost,
        checked: true,
      });
    }
  };

  useEffect(() => {
    const getLatLon = async () => {
      const addressInfo = await getLatLonFromPlaceId(address.value.place_id);
      const { formatAddress, latitude, longitude } = addressInfo;

      setDeliveryAddress({ formatAddress, latitude, longitude });
    };

    if (address.value.place_id) {
      void getLatLon();
    }
  }, [address.value.place_id]);

  useEffect(() => {
    const getGeoServices = async () => {
      setLoading(true);
      const res = await GeoLocationServicesService.getGeoLocationServices(
        deliveryAddress.latitude,
        deliveryAddress.longitude
      );
      setLoading(false);

      if (res.id) {
        getDeliveryCost(defaultPaymentGeoService);
      } else {
        setDeliveryInfo({
          available: false,
          cost: 0,
          checked: true,
        });
      }
    };

    setDeliveryInfo(initDeliveryInfo());

    if (
      type === DeliveryTypesText.toUserAddress &&
      deliveryAddress.latitude &&
      deliveryAddress.longitude
    ) {
      void getGeoServices();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, deliveryAddress.latitude, deliveryAddress.longitude]);

  const onMessageClick = () => {
    setValidate(true);

    if (isValidForm() && cartTotal > 0) {
      const products = generateProductsMessage(cartProducts);
      const cost = generateProductsCostMessage(
        cartTotal,
        type,
        deliveryInfo.cost
      );
      const phone = props.supplier.contactPhone.replace("+", "");
      const message = generateMessage(
        products,
        props.supplier.name,
        address.value.description,
        type,
        cost,
        fullName
      );
      const link = `https://wa.me/${phone}?text=${message}`;
      const openLink = encodeURI(link);
      window.open(openLink, "_blank");
    }
  };

  const isDisabledButton = () => {
    return (deliveryInfo.checked && !deliveryInfo.available) || loading;
  };

  return (
    <Grid item container xs={12} sx={styles.mainGrid} columnGap={"12.7%"}>
      <div className="mx-3 w-full mb-4">
        <TextInput
          label={"Nombre completo"}
          placeholder={"Escribe tu nombre completo"}
          value={fullName}
          handleChange={setFullName}
          isError={validate && !validateFullName}
          required
          textError={"El nombre completo es obligatorio"}
        />
      </div>

      <div className="mx-3 w-full mb-4">
        <CustomTextField
          title={"Ubicación"}
          placeholder={"Escribe tu ubicación"}
          value={address}
          setValue={setAddress}
          isError={validate && !validAddress}
          errorMessage={"Escribe tu ubicación"}
        />
      </div>

      <div className="mx-3 w-full mb-4">
        <Select
          title={"Tipo de envío"}
          placeholder={"Selecciona una opción"}
          options={types}
          value={type}
          setValue={setType}
          isError={validate && !validType}
          errorMessage={"Selecciona un tipo de envío"}
        />
      </div>

      <Grid item container xs={12} sx={styles.deliveryCost}>
        <DeliveryInfo type={type} deliveryInfo={deliveryInfo} />
      </Grid>

      <Grid item container xs={12} sx={styles.bottomGrid}>
        <Box sx={styles.bottomItemsContainer}>
          <OrderTotalCost loading={loading} orderTotal={orderTotal} />
          <Box sx={styles.sendButtonContainer}>
            <SimpleButton
              text={"Enviar orden"}
              bgColor={Colors.PRIMARYSUPPLIER}
              color={Colors.WHITE}
              onClick={onMessageClick}
              disabled={isDisabledButton()}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default DetailsForm;

const styles = {
  mainGrid: {
    display: "flex",
    flex: 1,
    marginTop: { xs: "2%", sm: "0%" },
  },
  addressGrid: {
    display: "flex",
    justifyContent: "center",
    marginBottom: { xs: "5%", md: "3%" },
  },
  deliveryDateGrid: {
    display: "flex",
    marginLeft: "2%",
    marginBottom: { xs: "5%", md: "3%" },
  },
  deliveryTimeGrid: {
    display: "flex",
    marginBottom: { xs: "5%", md: "3%" },
  },
  deliveryTypeGrid: {
    display: "flex",
    flex: 1,
    marginRight: "2%",
    marginLeft: "2%",
    height: "5.7rem",
    marginBottom: { xs: "5%", md: "3%" },
  },
  bottomGrid: {
    pl: "2%",
    pr: "2%",
    mt: "5%",
    mb: "4.5rem",
  },
  deliveryCost: {
    px: "2%",
    width: "100%",
  },
  bottomItemsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  deliveryCostText: {
    fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
    fontWeight: 600,
    color: Colors.TEXT,
  },
  sendButtonContainer: {
    height: "100%",
    width: { xs: "60%", sm: "50%" },
    display: "flex",
    alignItems: "center",
  },
  fullNameGrid: {
    display: "flex",
    justifyContent: "center",
    marginBottom: { xs: "5%", md: "3%" },
    marginRight: "2%",
  },
};

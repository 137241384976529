import { Colors } from "../../themes";
import { ErrorMessage } from "./ErrorMessage";
import { IUserAddress } from "../../interfaces/ISuppliersProfile";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Label } from "./standard/Label";

export function CustomTextField(props: {
  title: string;
  placeholder: string;
  value: IUserAddress | undefined;
  setValue: (value: IUserAddress) => void;
  isError?: boolean;
  errorMessage?: string;
}) {
  const { title, placeholder, value, setValue, isError, errorMessage } = props;

  const isEmptyValue = () => {
    return (
      value?.label === "" &&
      value.value.description === "" &&
      value.value.place_id === ""
    );
  };

  const getValue = () => (!isEmptyValue() ? value : undefined);

  const getPlaceholder = () => placeholder ?? "Ingresa un valor";

  return (
    <div className="w-full">
      <Label value={title} />

      <GooglePlacesAutocomplete
        selectProps={{
          value: getValue(),
          onChange: setValue,
          placeholder: getPlaceholder(),
          styles: {
            input: (provided: any, state: any) => ({
              ...provided,
              minHeight: "2.5rem",
              maxHeight: "2.5rem",
              display: "flex",
              alignItems: "center",
              width: "100%",
              color: "rgb(156 163 175)",
              fontFamily: "Montserrat",
              fontSize: "1rem",
            }),
            singleValue: (provided: any, state: any) => ({
              ...provided,
              color: Colors.BLACK,
              fontFamily: "Montserrat",
              fontSize: "1rem",
            }),
            placeholder: (provided: any, state: any) => ({
              ...provided,
              color: "rgb(156 163 175)",
              fontFamily: "Montserrat",
            }),
            control: (provided: any, state: any) => ({
              ...provided,
              backgroundColor: state.isFocused ? Colors.WHITE : Colors.GRAY6,
              borderColor: Colors.GRAY5,
              boxShadow: "none",
              borderRadius: 8,
              cursor: "pointer",
              ":hover": {
                borderColor: Colors.GRAY5,
              },
            }),
          },
        }}
      />

      <ErrorMessage isError={isError} message={errorMessage} />
    </div>
  );
}

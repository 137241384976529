import { IHttpService } from "./ihttp-service";
import {
  IProductConfigurationResponse,
  IProductMenu,
  emptyConfigRes,
  emptyIProductMenu,
} from "../interfaces/IProducts";

const supplierUrl = process.env.REACT_APP_BASE_URL_SUPPLIERS_WEB;

export class ProductService {
  constructor(private httpService: IHttpService) {}

  async getProductComplements(
    id: string
  ): Promise<IProductConfigurationResponse> {
    const url = `${supplierUrl}/products/${id}/complements`;
    return this.httpService.get(url, emptyConfigRes, "getComplements");
  }

  async getProductMenu(
    productId: string,
    time: string,
    lat: number,
    long: number
  ): Promise<IProductMenu> {
    const url = `${supplierUrl}/menu/products/${productId}?deliver_after=${time}&latitude=${lat}&longitude=${long}`;
    return this.httpService.get(url, emptyIProductMenu, "getProductMenu");
  }
}

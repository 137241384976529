import { SocialService } from "../services";

export const getDefaultDisplayName = (name: string) => {
  const formattedName = name + "_" + crypto.randomUUID();
  const fullName = formattedName.slice(0, 25).replace(/\s/g, "");
  return fullName;
};

export const createDefaultDisplayName = async (customerName: string) => {
  const fullName = customerName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]|\s+/g, "");

  let defaultDisplayName = getDefaultDisplayName(fullName);

  const res = await SocialService.getDisplayNameRecommendations(fullName);

  if (res?.recommendationsList?.length) {
    defaultDisplayName = res.recommendationsList[0];
  }

  return defaultDisplayName;
};

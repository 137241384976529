import { IPaymentGeoService } from "../interfaces/IGeoLocationServices";

export const defaultPaymentGeoService: IPaymentGeoService = {
  teamId: "1395411",
  ordersBaseCost: 36,
  deliveryBaseCost: 38,
  ordersExtraCost: 7,
  deliveryExtraCost: 7,
  paymentMethods: ["cash", "card"],
};

export interface IMedia {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  productId: string;
  supplierId: string;
  product: ILinkedProduct;
  supplier: ISupplierInfo;
  active: boolean;
  postCategoryId: string;
  tagsList: string[];
  image: IMediaImages;
  status: string;
  motive: string;
  type: string;
  alternativePhone: string;
  alternativeAddress: string;
  alternativeLatitude: number;
  alternativeLongitude: number;
}

export interface ILinkedProduct {
  name: string;
  imageUrl: string;
  image: IProductsImages;
  cost: ICost;
}

export interface ISupplierInfo {
  name: string;
  imageUrl: string;
  image: IProductsImages;
  phone: string;
}

export interface IMediaImages {
  thumbnail: string;
  regular: string;
  original: string;
  squared: string;
}

export interface IProductsImages {
  thumbnail: string;
  regular: string;
  original: string;
}

export interface ICost {
  currency: string;
  ammount: number;
}

export interface IMediaRes {
  postsList: IMedia[];
}
export function emptyISupplierMediaResponse(): IMediaRes {
  return {
    postsList: [],
  };
}

export function createEmptyPost(): IMedia {
  return {
    id: "",
    title: "",
    description: "",
    imageUrl: "",
    productId: "",
    supplierId: "@",
    product: {
      cost: {
        ammount: 0,
        currency: "",
      },
      image: {
        regular: "",
        thumbnail: "",
        original: "",
      },
      imageUrl: "",
      name: "",
    },
    active: true,
    image: {
      regular: "",
      thumbnail: "",
      original: "",
      squared: "",
    },
    motive: "",
    postCategoryId: "",
    status: "",
    tagsList: [],
    supplier: {
      image: {
        regular: "",
        thumbnail: "",
        original: "",
      },
      imageUrl: "",
      name: "",
      phone: "",
    },
    type: "",
    alternativePhone: "",
    alternativeAddress: "",
    alternativeLatitude: 0,
    alternativeLongitude: 0,
  };
}

export interface ReservationInfo {
  date: string;
  numberOfPeople: string;
  time: string;
}

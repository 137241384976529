import {
  IProductConfigRes,
  IProductComplementItemsRes,
  ICost,
} from "../../../interfaces/IProducts";
import { Box } from "@mui/material";
import { CustomCheck } from "./CustomCheck";
import { HeaderComplements } from "./HeaderComplements";
import {
  getComplementsDescription,
  isItemSelected,
  removedItem,
} from "../../../utils/ComplementsFunctions";

type Props = {
  configuration: IProductConfigRes;
  listIndex: number;
  borderRadius: number;
  localOptions: IProductComplementItemsRes[];
  cost: ICost;
  setCost: (value: ICost) => void;
  setComplementsSection: (
    options: IProductComplementItemsRes[],
    section: string
  ) => void;
  errorIndexes: number[];
  setErrorIndexes: (value: number[]) => void;
};

export function CheckOrRadioConfig(props: Props) {
  const {
    localOptions,
    configuration,
    errorIndexes,
    listIndex,
    cost,
    borderRadius,
    setCost,
    setErrorIndexes,
    setComplementsSection,
  } = props;

  const description = getComplementsDescription(configuration);

  const isArrayFull = localOptions.length === configuration.maxquantity;
  const isError = errorIndexes.includes(listIndex);
  const isRequired = configuration.isRequired ?? false;

  const onPress = (item: IProductComplementItemsRes) => {
    let updatedAmmount = cost.ammount;
    let currentOptions: IProductComplementItemsRes[] = [];
    const itemIsSelected = isItemSelected(localOptions, item.id);

    if (isError) {
      setErrorIndexes(errorIndexes.filter((e) => e !== listIndex));
    }
    if (isArrayFull && !itemIsSelected) {
      const removed = localOptions.shift();
      const removedPrice = removed ? removed.price : 0;
      updatedAmmount = cost.ammount - removedPrice + item.price;
      currentOptions = [...localOptions, item];
    } else if (itemIsSelected) {
      updatedAmmount = cost.ammount - item.price;
      currentOptions = removedItem(localOptions, item.id);
    } else if (!isArrayFull) {
      updatedAmmount = cost.ammount + item.price;
      currentOptions = [...localOptions, item];
    }

    updateCost(updatedAmmount);
    setComplementsSection(currentOptions, configuration.title);
  };

  const updateCost = (updatedAmmount: number) => {
    setCost({
      ammount: updatedAmmount,
      currency: "MXN",
    });
  };

  const ComplementsListItem = (props: { item: IProductComplementItemsRes }) => {
    const { item } = props;
    const isChecked = isItemSelected(localOptions, item.id);

    return (
      <Box sx={styles.itemContainer} onClick={() => onPress(item)}>
        <Box sx={styles.nameAndPrice}>
          <Box sx={styles.itemName}>{item.name}</Box>
          <Box sx={styles.itemPrice}>{`$${item.price.toFixed(2)}`}</Box>
        </Box>

        <CustomCheck
          checked={isChecked}
          borderRadius={borderRadius}
          bgColor={isChecked ? "checked" : "unchecked"}
          border={isError ? "error" : "primary"}
        />
      </Box>
    );
  };

  return (
    <>
      <HeaderComplements
        title={configuration.title}
        subtitle={description}
        isRequired={isRequired}
        isError={isError}
      />
      <Box sx={styles.sectionContainer}>
        {configuration.itemsList.map((item) => {
          return <ComplementsListItem item={item} key={item.id} />;
        })}
      </Box>
    </>
  );
}

const styles = {
  itemContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: { xs: "2%", md: "3%" },
  },
  nameAndPrice: {
    display: "flex",
    width: {
      xs: "60%",
      md: "70%",
    },
    justifyContent: "space-between",
    alignItems: "center",
  },
  itemName: {
    width: { xs: "60%" },
    fontSize: {
      xs: "0.9rem",
      sm: "1rem",
      md: "1rem",
      lg: "1.2rem",
    },
    wordWrap: "break-word",
  },
  itemPrice: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    fontSize: {
      xs: "0.9rem",
      sm: "1rem",
      md: "1rem",
      lg: "1.2rem",
    },
  },
  sectionContainer: {
    marginBottom: { xs: "5%", md: "3%" },
  },
};

import React from "react";
import Grid from "@mui/material/Grid";
import { Card, CardContent } from "@mui/material";
import { Box } from "@mui/system";
import { IStoreProduct } from "../../interfaces/IProducts";
import ProductButtons from "../Buttons/ProductButtons";

type Props = {
  handleSelectProduct: (product: IStoreProduct) => void;
  product: IStoreProduct;
};

function ProductGridItem(props: Props) {
  const { handleSelectProduct, product } = props;

  const handleOnAdd = () => {
    if (product.id) {
      handleSelectProduct(product);
    }
  };

  const ProductImage = () => {
    return (
      <Box sx={styles.imageContainer} onClick={handleOnAdd}>
        <img
          src={props.product.images.regular}
          alt=""
          className="product-image-container"
        />
      </Box>
    );
  };

  const ProductDescription = () => {
    return (
      <CardContent
        sx={{
          height: "80%",
          padding: "5%",
        }}
        onClick={handleOnAdd}
      >
        <Box sx={styles.productNameContainer}>
          <Box sx={styles.productName}>{props.product.name}</Box>
        </Box>
        <Box sx={styles.productDescriptionContainer}>
          <Box sx={styles.productDescription}>{props.product.description}</Box>
        </Box>
      </CardContent>
    );
  };

  const LeftContent = () => {
    return (
      <Box sx={styles.leftContent}>
        <ProductDescription />
        <Box sx={styles.bottomOptionsContainer}>
          <Box sx={styles.amount}>{`$${props.product.cost.ammount.toFixed(
            2
          )}`}</Box>
          <ProductButtons handleOnAdd={handleOnAdd} />
        </Box>
      </Box>
    );
  };

  return (
    <Grid item container sm={12} md={6} xl={4}>
      <Card sx={styles.cardContainer}>
        <LeftContent />
        <ProductImage />
      </Card>
    </Grid>
  );
}

const styles = {
  cardContainer: {
    display: "flex",
    m: 0.5,
    height: "24h",
    width: "100%",
  },
  leftContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "57%",
  },
  productNameContainer: {
    marginBottom: "3%",
  },
  productName: {
    display: "-webkit-box",
    fontSize: "1.3rem",
    fontWeight: 700,
  },
  productDescriptionContainer: {
    maxHeight: "70%",
    overflow: "hidden",
  },
  productDescription: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 4,
    overflow: "hidden",
    fontSize: "0.9rem",
    height: "100%",
  },
  bottomOptionsContainer: {
    paddingRight: "5%",
    paddingLeft: "5%",
    height: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  amount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  imageContainer: {
    display: "flex",
    width: "43%",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

export default ProductGridItem;

import { CardHeader } from "@mui/material";
import { Icon } from "@mui/material";
import { Box } from "@mui/system";
import { ICartItem } from "../../../interfaces/IShoppingCart";
import { Colors } from "../../../themes";
import IconButton from "@mui/material/IconButton";

type Props = {
  cartProduct: ICartItem;
  handleSelectCartItem: (cartItem: ICartItem) => void;
};

export function OrderCardHeader(props: Props) {
  const { cartProduct, handleSelectCartItem } = props;
  const productName = cartProduct.product.name ?? "";

  const Title = () => {
    return <Box sx={styles.cardTitle}>{productName}</Box>;
  };

  const handleClick = () => handleSelectCartItem(cartProduct);

  return (
    <CardHeader
      sx={styles.cardHeader}
      action={
        <IconButton onClick={handleClick}>
          <Icon sx={styles.icon}>delete</Icon>
        </IconButton>
      }
      title={<Title />}
    />
  );
}

const styles = {
  cardHeader: {
    padding: 0,
    backgroundColor: "grey",
    marginBottom: "2%",
  },
  cardTitle: {
    fontSize: { xs: "1.2rem", md: "1.2rem", lg: "1.3rem" },
    fontWeight: 600,
  },
  icon: {
    fontSize: { xs: "1.2rem", md: "1.2rem", lg: "1.3rem" },
    color: Colors.BLACK,
  },
};

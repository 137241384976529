type Props = {
  isError?: boolean;
  message?: string;
};

export function ErrorMessage(props: Props) {
  const { isError, message } = props;

  if (isError && message) {
    return <p className="text-error sm:mt-1 md:mt-2">{message}</p>;
  }

  return null;
}

// AvailableDays.tsx
import { Box, Grid, Icon, styled } from "@mui/material";
import { Colors } from "../../themes";
import { ISupplierRules } from "../../interfaces/ISuppliersProfile";
import { StatusInfo } from "../molecules/StatusInfo";
import RuleMessage from "./RuleMessage";

function AvailableDays(props: { rules: ISupplierRules }) {
  const deliveryType = (type: string) => {
    switch (type) {
      case "TO_USER_ADDRESS":
        return "Domicilio";
      case "PICKUP_IN_SUPPLIER":
        return "Pick up";
      case "ONLINE":
        return "En línea";
      case "CONSUME_IN_SUPPLIER":
        return "Reservación";
      case "SHIPPING":
        return "Envíos a todo el País";
      default:
        return "";
    }
  };

  const CustomCheckIcon = styled(Icon)({
    color: Colors.PRIMARYSUPPLIER,
    paddingRight: 1.5,
  });

  const CustomClockIcon = styled(Icon)({
    paddingRight: 8,
  });

  const styles = {
    listContainer: {
      mb: 1,
      pl: 1,
      pr: 1,
      borderRadius: 1,
      backgroundColor: Colors.WHITE,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    hourContainer: {
      backgroundColor: Colors.WHITE,
      borderRadius: 1,
      pl: 1,
      pr: 1,
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
    },
  };

  return (
    <Grid item container xs={12} md={4}>
      <Grid item xs={12} sx={styles.listContainer}>
        {props.rules.deliveryOptionsList.map((e, index) => (
          <Box display="flex" alignItems="center" key={index + 1}>
            <CustomCheckIcon key={index + 2} fontSize="small">
              check
            </CustomCheckIcon>
            <p key={index + 3} className="TextStyles-S3">
              {deliveryType(e)}
            </p>
          </Box>
        ))}
      </Grid>

      <div className="w-[100%] bg-white">
        <StatusInfo rules={props.rules.formattedRulesList} />
      </div>

      {props.rules.formattedRulesList.map((e, index) => (
        <Grid item xs={12} sx={styles.hourContainer} key={index}>
          <p className="TextStyles-S3">{e.day}</p>
          <RuleMessage rule={e} />
        </Grid>
      ))}
    </Grid>
  );
}

export default AvailableDays;

import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import { SupplierInformation } from "./components/SupplierInformation";
import Grid from "@mui/material/Grid";
import SectionTabs from "./components/SectionTabs";
import { SupplierService } from "./services";
import { emptySupplierSectionProducts } from "./interfaces/IProducts";
import {
  emptyISupplierRules,
  ISupplierRules,
} from "./interfaces/ISuppliersProfile";
import { Route, Routes } from "react-router-dom";
import CompleteOrder from "./Pages/CompleteOrder/CompleteOrder";
import AffiliateModal from "./components/Banner/AffiliateModal";
import SuccessModal from "./components/Banner/SuccessModal";
import { AppContext } from "./context/AppContext";
import { Types } from "./context/Reducers";
import OnboardingAIForm from "./Pages/OnboardingAI/OnboardingAIForm";
import WelcomeScreen from "./Pages/OnboardingAI/WelcomeAIScreend";
import ProcessingScreen from "./Pages/OnboardingAI/ProcessingScreen";
import {Amplify} from 'aws-amplify';
import config from "./services/amplify.service";

function App() {
  const { state, dispatch } = useContext(AppContext);
  const [products, setProducts] = useState(() =>
    emptySupplierSectionProducts()
  );

  const [supplierRules, setSupplierRules] = useState<ISupplierRules>(
    emptyISupplierRules()
  );

  const supplierId = window.location.pathname.replace(/^\/([^/]*).*$/, "$1");
  const [modalName, setModalName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const supplier = state.supplierAccount;

  const handleOpenWelcome = () => {
    setModalName("Welcome");
  };
  const handleClose = () => {
    setModalName("");
  };

  Amplify.configure(config);

  const getSupplierInformation = async () => {
    const res = await SupplierService.getProfile(supplierId);

    if (res.id) {
      dispatch({
        type: Types.SetSupplierAccount,
        payload: {
          supplierAccount: res,
        },
      });
    }
  };

  const getSupplierRules = async () => {
    const res = await SupplierService.getSupplierRules(supplierId);
    if (res.deliveryDays.dayList.length > 0) {
      setSupplierRules(res);
    }
  };

  const getProducts = async () => {
    const res = await SupplierService.getProducts(supplierId);
    if (res.sectionList) {
      setProducts(res);
    }
  };

  useEffect(() => {
    void getSupplierInformation();
    void getProducts();
    void getSupplierRules();

    handleOpenWelcome();
    getUserLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;

      dispatch({
        type: Types.SetLocation,
        payload: {
          location: {
            latitude,
            longitude,
          },
        },
      });
    });
  };

  const [showForm, setShowForm] = useState(false);

  const handleStart = () => {
    setShowForm(true);
  };

  return (
    <Grid container item xs={12}>
      <Routes>
        <Route
          path={`/${supplierId}/`}
          element={
            <>
              <SupplierInformation
                supplier={supplier}
                rules={supplierRules.formattedRulesList}
              />
              <SectionTabs
                products={products}
                rules={supplierRules}
                supplier={supplier}
                modalName={modalName}
                setModalName={setModalName}
                handleClose={handleClose}
              />
            </>
          }
        />
         <Route
        path="/onboarding/ai"
        element={
          !showForm ? (
            <WelcomeScreen onStart={handleStart} />
          ) : (
            <OnboardingAIForm />
          )
        }
      />
       <Route
        path="/onboarding/ai/completed"
        element= {<ProcessingScreen onComplete={function (): void {
          throw new Error("Function not implemented.");
        } } />}
      />
        <Route
          path={`/${supplierId}/complete`}
          element={<CompleteOrder rules={supplierRules} supplier={supplier} />}
        />
      </Routes>
      <AffiliateModal
        modalName={modalName}
        setModalName={setModalName}
        handleClose={handleClose}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        supplier={supplier}
      />
      <SuccessModal
        modalName={modalName}
        setModalName={setModalName}
        handleClose={handleClose}
        phoneNumber={phoneNumber}
      />
    </Grid>
  );
}

export default App;

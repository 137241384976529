export interface IGeoLocationServices {
  id: string;
  codeName: string;
  deliveryList: IGeoService[];
  payment: IPaymentGeoService;
  paymentsList: string[];
  membershipsList: string[];
  h3IndexesList: string[];
}

export interface IGeoService {
  code: string;
  active: boolean;
  imageKey: string;
  image: IImage;
  appServiceRuleDaysList: IRuleGeoService[];
}

interface IImage {
  original: string;
  thumbnail: string;
  regular: string;
  squared: string;
}

export interface IRuleGeoService {
  day: number;
  startHour: number;
  endHour: number;
  startMinutes: number;
  endMinutes: number;
  timeZone: string;
}

export interface IPaymentGeoService {
  teamId: string;
  ordersBaseCost: number;
  deliveryBaseCost: number;
  ordersExtraCost: number;
  deliveryExtraCost: number;
  paymentMethods: string[];
}

export function emptyGeoLocationServices(): IGeoLocationServices {
  return {
    id: "",
    codeName: "",
    payment: emptyPaymentGeoService(),
    deliveryList: [],
    paymentsList: [],
    membershipsList: [],
    h3IndexesList: [],
  };
}

export function emptyPaymentGeoService(): IPaymentGeoService {
  return {
    teamId: "",
    ordersBaseCost: 0,
    deliveryBaseCost: 0,
    ordersExtraCost: 0,
    deliveryExtraCost: 0,
    paymentMethods: [],
  };
}

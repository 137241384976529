import {
  ICost,
  IProductConfigRes,
  IProductComplementItemsRes,
} from "../../../interfaces/IProducts";
import { RenderConfig } from "./RenderConfig";

type Props = {
  configurations: IProductConfigRes[];
  cost: ICost;
  setCost: (value: ICost) => void;
  setComplementsSection: (
    options: IProductComplementItemsRes[],
    section: string
  ) => void;
  localComplements: {
    [key: string]: IProductComplementItemsRes[];
  };
  errorIndexes: number[];
  setErrorIndexes: (value: number[]) => void;
};

export function ProductConfigurations(props: Props) {
  const {
    configurations,
    cost,
    localComplements,
    errorIndexes,
    setErrorIndexes,
    setCost,
    setComplementsSection,
  } = props;

  if (configurations.length === 0) return null;

  return (
    <>
      {configurations.map((config, index) => {
        return (
          <RenderConfig
            config={config}
            key={config.id}
            listIndex={index}
            cost={cost}
            setCost={setCost}
            setComplementsSection={setComplementsSection}
            localComplements={localComplements}
            errorIndexes={errorIndexes}
            setErrorIndexes={setErrorIndexes}
          />
        );
      })}
    </>
  );
}

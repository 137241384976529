import { Card, CardContent } from "@mui/material";
import { Colors } from "../../themes";
import { ICartItem } from "../../interfaces/IShoppingCart";
import { OrderCardFooter } from "./cartOrderCard/OrderCardFooter";
import { OrderCardBody } from "./cartOrderCard/OrderCardBody";
import { OrderCardHeader } from "./cartOrderCard/OrderCardHeader";

type Props = {
  cartProduct: ICartItem;
  handleSelectCartItem: (cartItem: ICartItem) => void;
};

export function CartOrderCard(props: Props) {
  const { cartProduct, handleSelectCartItem } = props;

  return (
    <Card sx={styles.card}>
      <CardContent sx={styles.cardContent}>
        <OrderCardHeader
          cartProduct={cartProduct}
          handleSelectCartItem={handleSelectCartItem}
        />
        <OrderCardBody cartProduct={cartProduct} />
        <OrderCardFooter cartProduct={cartProduct} />
      </CardContent>
    </Card>
  );
}

const styles = {
  card: {
    background: Colors.BACKGROUND,
    padding: { xs: "1%", md: "1%" },
    marginBottom: "3%",
    borderRadius: 4,
    boxShadow: 5,
  },
  cardContent: {
    padding: "2%",
    "&:last-child": {
      paddingBottom: "2%",
    },
  },
};

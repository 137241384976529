import { emptyIDisplayNameRecommendations } from "../interfaces/ICustomer";
import { HttpService } from "./http-service";
import { ISocialService } from "./isocial.service";

const customerUrl = process.env.REACT_APP_BASE_URL;
const BASE_URL = customerUrl;

export class SocialService implements ISocialService {
  constructor(private httpServices: HttpService) {}

  async getDisplayNameRecommendations(displayName: string) {
    const url =
      BASE_URL + "/customers/recommendations?displayName=" + displayName;

    return this.httpServices.getWithToken(
      url,
      emptyIDisplayNameRecommendations,
      "getDisplayRecommendations"
    );
  }
}

// Status.tsx
import React from "react";
import { IFormatedRule } from "../../interfaces/ISuppliersProfile";
import { DaysDictionary } from "../../constants/DaysDictionary";
import { Colors } from "../../themes";

const Status = ({ formattedRule }: { formattedRule: IFormatedRule[] }) => {
  const today = DaysDictionary[new Date().getDay()];
  const rule = formattedRule.find((x) => x.day === today);
  const hour = new Date().getHours();
  let statusText = "";
  let statusColor = ""; // Agregamos una variable para el color

  if (rule !== undefined) {
    const start = +rule.startHour;
    const end = +rule.endHour;

    if (hour < start || hour >= end) {
      statusText = "Cerrado";
      statusColor = Colors.ERROR; // Color rojo si está cerrado
    } else {
      statusText = "Abierto";
      statusColor = Colors.LIGHT_GREEN; // Color verde si está abierto
    }
  }

  return (
    <p className="text-sm md:text-base" style={{ color: statusColor }}>
      {statusText}
    </p>
  );
};

export default Status;

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import { Icon } from "@mui/material";
import { Colors } from "../../../themes";

type Props = {
  children: React.ReactNode;
  modalVisible: boolean;
  onClose: () => void;
};

export function ModalWrapper(props: Props) {
  const { children, modalVisible, onClose } = props;

  const HeaderBar = () => {
    return (
      <Box sx={styles.headerBar} onClick={onClose}>
        <Icon>close</Icon>
      </Box>
    );
  };

  return (
    <Modal
      open={modalVisible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={styles.modalStyles}
    >
      <Paper elevation={0} square sx={styles.paper}>
        <Box sx={styles.modalInnerContainer} style={{ position: "relative" }}>
          <HeaderBar />
          {children}
        </Box>
      </Paper>
    </Modal>
  );
}

const styles = {
  modalStyles: {
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    height: "auto",
    width: { xs: "90%", sm: "60%", md: "40%" },
    borderRadius: 4,
  },
  modalInnerContainer: {
    background: Colors.WHITE,
    alignSelf: "center",
    padding: { xs: "3%" },
    borderRadius: 4,
  },
  headerBar: {
    position: "sticky",
    top: 0,
    height: "8%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    paddingX: "1%",
    paddingY: { xs: "0%", md: "1%" },
    justifyContent: "flex-end",
    zIndex: 9999,
  },
};

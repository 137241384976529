import {
  emptyIAnticipation,
  emptyIDeliveryDays,
  IAnticipation,
  IAvailableDates,
  IDeliveryDays,
  IProduct,
  IRule,
} from "./IProducts";

export interface ISupplierProfile {
  id: string;
  name: string;
  imageUrl: string;
  contactName: string;
  contactPhone: string;
  address: ISupplierProfileAddress;
  images: ISupplierProfilImages;
  active: boolean;
  rfc: string;
  status: string;
}

export interface ISupplierProfileAddress {
  name: string;
  latitude: number;
  longitude: number;
}

export interface ISupplierProfilImages {
  thumbnail: string;
  regular: string;
}

export function emptyISupplierProfile(): ISupplierProfile {
  return {
    id: "",
    name: "",
    imageUrl: "",
    contactName: "",
    contactPhone: "",
    address: {
      name: "",
      latitude: 0,
      longitude: 0,
    },
    images: {
      thumbnail: "",
      regular: "",
    },
    active: false,
    rfc: "",
    status: "",
  };
}

export interface ISupplierRules {
  deliveryOptionsList: string[];
  deliveryDays: IDeliveryDays;
  availableDates: IAvailableDates;
  anticipation: IAnticipation;
  formattedRulesList: IFormatedRule[];
}

export interface ISubscribe {
  name: string;
  phone: string;
  entityId: string;
  entityType: 0;
  email: string;
  isSubscribed: boolean;
}

export function emptyISubscribe(): ISubscribe {
  return {
    name: "",
    phone: "",
    entityId: "",
    entityType: 0,
    email: "",
    isSubscribed: true,
  };
}

export interface IPostSubscribeBody {
  name: string;
  phone: string;
  entityType: number;
}

export interface IFormatedRule {
  day: string;
  startHour: string;
  endHour: string;
  anticipationInMinutes: number;
}

export function emptyISupplierRules(): ISupplierRules {
  return {
    deliveryOptionsList: [],
    deliveryDays: emptyIDeliveryDays(),
    availableDates: {
      startDate: "",
      endDate: "",
    },
    anticipation: emptyIAnticipation(),
    formattedRulesList: [],
  };
}

export interface ISupplierProducts {
  subcategoriesList: string[];
  productsList: IProduct[];
}

export interface ISupplierAccountResponse {
  accountsList: ISupplierAccount[];
}

export function emptyISupplierAccountResponse() {
  return { accountsList: [] };
}

export interface INewSupplierAccountResponse {
  account: ISupplierAccount;
}

export function emptyINewSupplierAccountResponse() {
  return { account: createEmptyISupplierAccount() };
}

export interface IPostSupplierBody {
  supplierName: string;
  termsConditions: boolean;
  supplierPhone: string;
}

export interface ISupplierAccount {
  id: string;
  name: string;
  imageUrl: string;
  contactName: string;
  contactPhone: string;
  address: ISupplierProfileAddress;
  images: ISupplierProfilImages;
  active: boolean;
  rfc: string;
  status: string;
  locale: string;
  countryCode: string;
  email: string;
  categoryId: string;
  userEstablishmentId: string;
}

export interface IOnboardingStep {
  code: string;
}

export function createEmptyOnboardingStep(): IOnboardingStep {
  return {
    code: "",
  };
}

export interface INewSupplierAccount {
  id: string;
  name: string;
  imageUrl: string;
  coverUrl: string;
  contactName: string;
  contactPhone: string;
  email: string;
  description: string;
  socialMedia: {
    facebook: string;
    instagram: string;
    webpage: string;
  };
  address: ISupplierProfileAddress;
  images: ISupplierProfilImages;
  coverImages: {
    cover: string;
    complete: string;
  };
  active: false;
  rfc: string;
  status: string;
  categoryId: string;
  locale: string;
  countryCode: string;
  commission: number;
  userEstablishmentId: string;
  customerId: string;
  nextStep: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface ISupplierAccountContext {
  info: ISupplierAccount;
  customerId: string;
}

export function createEmptyISupplierAccount(): ISupplierAccount {
  return {
    id: "",
    name: "",
    imageUrl: "",
    contactName: "",
    contactPhone: "",
    address: {
      name: "",
      latitude: 0,
      longitude: 0,
    },
    images: {
      regular: "",
      thumbnail: "",
    },
    active: false,
    rfc: "",
    status: "",
    locale: "",
    countryCode: "",
    email: "",
    categoryId: "",
    userEstablishmentId: "",
  };
}
export function createEmptyINewSupplierAccount(): INewSupplierAccount {
  return {
    id: "",
    name: "",
    imageUrl: "",
    coverUrl: "",
    contactName: "",
    contactPhone: "",
    email: "",
    description: "",
    socialMedia: {
      facebook: "",
      instagram: "",
      webpage: "",
    },
    address: {
      name: "",
      latitude: 0,
      longitude: 0,
    },
    images: {
      thumbnail: "",
      regular: "",
    },
    coverImages: {
      cover: "",
      complete: "",
    },
    active: false,
    rfc: "",
    status: "",
    categoryId: "",
    locale: "",
    countryCode: "",
    commission: 0,
    userEstablishmentId: "",
    customerId: "",
    nextStep: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
  };
}
export function createEmptySupplierAccountContext(): ISupplierAccountContext {
  return {
    info: createEmptyISupplierAccount(),
    customerId: "",
  };
}

export interface IPutSupplierBody {
  name?: string;
  address?: string;
  latitude?: number;
  logitude?: number;
  contactName?: string;
  contactPhone?: string;
  email?: string;
  imageUrl?: string;
  coverUrl?: string;
  description?: string;
  facebook?: string;
  instagram?: string;
  webPage?: string;
  RFC?: string;
  status?: string;
  categoryId?: string;
  locale?: string;
  countryCode?: string;
}

export interface ISupplierCategory {
  id: string;
  name: string;
  active: boolean;
}

export interface ISupplierCategoryResponse {
  suppliercategoriesList: ISupplierCategory[];
}

export function emptyISupplierCategoryResponse(): ISupplierCategoryResponse {
  return { suppliercategoriesList: [] };
}

export interface RulesPostRes {
  supplierId: string;
  productId: string;
  rulesList: IRule[];
}

export function emptyRulesPostRes(): RulesPostRes {
  return {
    supplierId: "",
    productId: "",
    rulesList: [],
  };
}

export interface RulesGetRes {
  rulesList: IRule[];
}

export function emptyRulesGetRes(): RulesGetRes {
  return {
    rulesList: [],
  };
}

export interface IUserAddress {
  label: string;
  value: {
    description: string;
    place_id: string;
  };
}

export function emptyUserAddress(): IUserAddress {
  return {
    label: "",
    value: {
      description: "",
      place_id: "",
    },
  };
}

export interface IMapsAddress {
  label: string;
  value: {
    description: string;
  };
}
export interface IPostAffiliateResponse {
  attempt: string;
  id: string;
  request_id: string;
  status: string;
}

export interface AffiliateBody {
  contact_name: string;
  contact_phone: string;
  supplier_name: string;
  from_supplier_id: string;
  from_supplier_name: string;
}

export function emptyAffiliateResponse(): IPostAffiliateResponse {
  return {
    attempt: "",
    id: "",
    request_id: "",
    status: "",
  };
}

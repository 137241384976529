import { Button, ButtonProps, styled } from "@mui/material";
import { Colors } from "../../themes";
import { Icon } from "@mui/material";

const NewButton = styled(Button)<ButtonProps>(() => ({
  "&.MuiButton-root": {
    borderRadius: 16,
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 500,
    width: "100%",
    height: "100%",
    color: Colors.WHITE,
    paddingY: 0,
    paddingX: 1,
    backgroundColor: Colors.PRIMARYSUPPLIER,
  },
}));

type Props = {
  onClick: () => void;
  bgColor: string;
  color: string;
  text: string;
  disabled?: boolean;
};

export function PlusButton(props: Props) {
  const { onClick, bgColor, color, text, disabled } = props;

  const buttonStyles = {
    backgroundColor: `${bgColor} !important`,
    color: color,
    opacity: disabled ? 0.5 : 1,
  };

  return (
    <NewButton
      sx={{ ...styles.simpleButton, ...buttonStyles }}
      onClick={onClick}
      variant="contained"
      disabled={disabled}
    >
      <Icon>add</Icon>
      <p style={styles.text}>{text}</p>
    </NewButton>
  );
}

const styles = {
  text: {
    margin: 0,
    lineHeight: 1,
  },
  simpleButton: {
    fontSize: { xs: "0.8rem", sm: "0.9rem" },
    gap: "1rem",
  },
};

import Grid from "@mui/material/Grid";
import { Card, CardContent } from "@mui/material";
import { Box } from "@mui/system";
import { IMedia } from "../../../interfaces/IPosts";
import { Colors } from "../../../themes";
import { searchLocation, sendQuoteMessage } from "../../../utils/Functions";
import {
  PostInteraction,
  PostInteractionDescription,
} from "../../../constants/PostConstants";

type Props = {
  post: IMedia;
  setModalName: (name: string) => void;
  showLinkedProduct: (productId: string) => void;
};

export function PostCard(props: Props) {
  const { post, setModalName } = props;
  const { type, productId, supplier } = post;

  const getBannerText = () => {
    if (productId) {
      return PostInteractionDescription[PostInteraction.AddToCartNow];
    }
    return PostInteractionDescription[type];
  };

  const handleBannerClick = () => {
    if (type === PostInteraction.AddToCartNow || productId) {
      props.showLinkedProduct(productId);
    } else if (type === PostInteraction.NavigateNow) {
      handleNavigateToPlace();
    } else if (type === PostInteraction.QuoteNow) {
      handleQuoteNow();
    } else if (type === PostInteraction.ReserveNow) {
      handleReserveNow();
    }
  };

  const handleNavigateToPlace = () => {
    if (post.alternativeLatitude && post.alternativeLongitude) {
      searchLocation(post.alternativeLatitude, post.alternativeLongitude);
    }
  };

  const handleQuoteNow = () => {
    if (post.alternativePhone) {
      sendQuoteMessage(post.alternativePhone);
    }
  };

  const handleReserveNow = () => {
    if (props.post.supplierId && supplier.name && supplier.phone) {
      setModalName("reservationModal");
    }
  };

  const PostDescription = () => {
    return (
      <Box sx={styles.description}>
        <Box sx={styles.postNameContainer}>
          <Box sx={styles.postName}>{post.title}</Box>
        </Box>
        <Box sx={styles.postDescriptionContainer}>
          <Box sx={styles.postDescription}>{post.description}</Box>
        </Box>
      </Box>
    );
  };

  const ActionBanner = () => {
    if (type) {
      return (
        <Box sx={styles.banner} onClick={handleBannerClick}>
          {getBannerText()}
        </Box>
      );
    }
    return null;
  };

  const LeftContent = () => {
    return (
      <CardContent sx={styles.leftContent}>
        <PostDescription />
        <ActionBanner />
      </CardContent>
    );
  };

  const PostImage = () => {
    return (
      <Box sx={styles.imageContainer}>
        <img
          src={post.image.regular}
          alt=""
          className="product-image-container"
        />
      </Box>
    );
  };

  return (
    <Grid item container sm={12} md={6} xl={4}>
      <Card sx={styles.cardContainer}>
        <LeftContent />
        <PostImage />
      </Card>
    </Grid>
  );
}

const styles = {
  cardContainer: {
    display: "flex",
    m: 0.5,
    height: "30vh",
    width: "100%",
  },
  leftContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "50%",
  },
  description: {
    height: "70%",
  },
  postNameContainer: {
    marginBottom: "3%",
  },
  banner: {
    paddingRight: "5%",
    paddingLeft: "5%",
    height: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.SECONDARYSUPPLIER,
    color: Colors.PRIMARYSUPPLIER,
    fontWeight: 600,
    cursor: "pointer",
  },
  postName: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  postDescriptionContainer: {
    maxHeight: "60%",
    overflow: "hidden",
  },
  postDescription: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 4,
    overflow: "hidden",
    fontSize: {
      sm: "0.9rem",
      md: "1rem",
      lg: "1.1rem",
    },
    height: "100%",
  },
  imageContainer: {
    display: "flex",
    width: "50%",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

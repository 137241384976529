import { Button, ButtonProps, styled } from "@mui/material";
import { Colors } from "../../themes";

const NewButton = styled(Button)<ButtonProps>(() => ({
  "&.MuiButton-root": {
    borderRadius: 8,
    textTransform: "none",
    color: Colors.WHITE,
    background: `linear-gradient(to right, ${Colors.PRIMARYSUPPLIER}, ${Colors.SECONDARY_BUTTON})`,
    fontFamily: "Montserrat",
    fontSize: "1.2rem",
    fontWeight: 500,
    width: "100%",
  },
}));

type Props = {
  handleClick: () => void;
  text: string;
};

export function AddButton(props: Props) {
  const { text, handleClick } = props;
  return (
    <NewButton
      variant="contained"
      color="primary"
      sx={{ height: "100%" }}
      onClick={handleClick}
    >
      <p style={{ margin: 0 }}>{text}</p>
    </NewButton>
  );
}

import { Box } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ICartItem } from "../../../interfaces/IShoppingCart";
import {
  extractComplementItems,
  formatComplementItemsList,
} from "../../../utils/Functions";

type Props = {
  cartProduct: ICartItem;
};

export function ComplementsTable(props: Props) {
  const { cartProduct } = props;
  const complements = cartProduct.productComplements;
  const complementItems = extractComplementItems(complements);
  const formattedComplements = formatComplementItemsList(complementItems);

  const TableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell align="left" sx={styles.tableHead}>
            {"Descripción"}
          </TableCell>
          <TableCell align="center" sx={styles.tableHead}>
            {"Cantidad"}
          </TableCell>
          <TableCell align="left" sx={styles.tableHead}>
            {"Importe"}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const TableBodyComponent = () => {
    return (
      <TableBody>
        {formattedComplements.map((item, idx) => {
          return (
            <TableRow key={item.id + idx}>
              <TableCell align="left" sx={styles.tableCell}>
                <Box sx={styles.textCell}>{item.name}</Box>
              </TableCell>
              <TableCell align="center" sx={styles.tableCell}>
                <Box sx={styles.textCell}>{`x${item.quantity}`}</Box>
              </TableCell>
              <TableCell align="left" sx={styles.tableCell}>
                <Box sx={styles.textCell}>{`${item.total.toFixed(2)} MXN`}</Box>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const ComplementsTableComponent = () => {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 100 }} aria-label="simple table">
          <TableHeader />
          <TableBodyComponent />
        </Table>
      </TableContainer>
    );
  };

  const RenderComplements = () => {
    if (formattedComplements.length > 0) {
      return (
        <>
          <Box sx={styles.complementsTitle}>{"Complementos"}</Box>
          <ComplementsTableComponent />
        </>
      );
    }

    return <Box sx={styles.complementsTitle}>{"Sin Complementos"}</Box>;
  };

  return (
    <Box sx={styles.complementsSection}>
      <RenderComplements />
    </Box>
  );
}

const styles = {
  tableHead: {
    padding: "0.5%",
    fontFamily: "inherit",
    borderBottom: 0,
    fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
    fontWeight: 400,
  },
  tableCell: {
    padding: "0.5%",
    borderBottom: 0,
    fontFamily: "inherit",
    fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" },
  },
  textCell: {
    width: { xs: "30vw", md: "20vw" },
    wordWrap: "break-word",
  },
  complementsSection: {
    marginY: "2%",
  },
  complementsTitle: {
    fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
    fontWeight: 500,
  },
};

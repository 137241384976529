import { Box } from "@mui/material";
import { Icon } from "@mui/material";
import { Colors } from "../../../themes";

type Props = {
  checked: boolean;
  borderRadius: number;
  bgColor: "checked" | "unchecked";
  border: "primary" | "error";
};

export function CustomCheck(props: Props) {
  const { borderRadius, checked, bgColor, border } = props;

  const backgroundColor =
    bgColor === "checked" ? Colors.PRIMARYSUPPLIER : Colors.BACKGROUND;

  const borderStyle =
    border === "primary" ? styles.borderPrimary : styles.borderError;

  const CheckIcon = () =>
    checked ? <Icon sx={{ color: Colors.WHITE }}>check</Icon> : null;

  return (
    <Box
      sx={{
        borderRadius: `${borderRadius}%`,
        backgroundColor: backgroundColor,
        ...styles.borderCheck,
        ...borderStyle,
      }}
    >
      <CheckIcon />
    </Box>
  );
}

const styles = {
  borderCheck: {
    width: "1.5rem",
    height: "1.5rem",
    borderWidth: 1,
    marginRight: "1.5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  borderPrimary: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: Colors.BLACK,
  },
  borderError: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: Colors.ERROR,
  },
};

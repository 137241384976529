import { Box, Button, ButtonProps, styled } from "@mui/material";
import { Colors } from "../../themes";
import { Icon } from "@mui/material";

const StepperBtn = styled(Button)<ButtonProps>(() => ({
  "&.MuiButton-root": {
    borderRadius: 8,
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "1.2rem",
    fontWeight: 500,
    width: "100%",
    height: "100%",
    cursor: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "6px",
  },
}));

type Props = {
  counter: number;
  handleIncrease: () => void;
  handleDecrease: () => void;
  bgColor: string | "gradient";
  color: string;
  border: "primary" | "error";
};

const gradient = `linear-gradient(to right, ${Colors.PRIMARYSUPPLIER}, ${Colors.SECONDARY_BUTTON})`;

export function SpinnerButton(props: Props) {
  const { counter, color, bgColor, border, handleIncrease, handleDecrease } =
    props;

  const btnColor = bgColor === "gradient" ? gradient : bgColor;

  const getBorder = () => {
    switch (border) {
      case "primary":
        return styles.primaryBorder;
      case "error":
        return styles.errorBorder;
      default:
        return styles.primaryBorder;
    }
  };

  return (
    <StepperBtn
      variant="contained"
      sx={{
        color: color,
        background: `${btnColor} !important`,
        border: getBorder(),
      }}
    >
      <Box onClick={handleDecrease} sx={styles.qtyButton}>
        <Icon sx={styles.icons}>remove</Icon>
      </Box>
      <Box>{counter}</Box>
      <Box onClick={handleIncrease} sx={styles.qtyButton}>
        <Icon sx={styles.icons}>add</Icon>
      </Box>
    </StepperBtn>
  );
}

const styles = {
  qtyButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    width: "40%",
    height: "100%",
    justifyContent: "center",
  },
  icons: { fontSize: { xs: "1.2rem", md: "1.2rem" } },
  errorBorder: {
    borderWidth: 1,
    borderColor: Colors.ERROR,
    borderStyle: "solid",
  },
  primaryBorder: {
    borderWidth: 0,
  },
};

import React from 'react';
import logo from '../../assets/images/logo.png'; // Importa tu imagen de logo

interface WelcomeScreenProps {
    onStart: () => void;
  }

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ onStart }) => {
    return (
    <div className="welcome-container" style={styles.container}>
      <div style={styles.content}>
        <img src={logo} alt="Logo" style={styles.logo} />
        <h1 style={styles.title}>¡Bienvenido a Fuddis AI!</h1>
        <p style={styles.subtitle}>La herramienta inteligente para llevar tu restaurante al siguiente nivel. Únete a nosotros solo con un link.</p>
        <button onClick={onStart} style={styles.button}>Comenzar</button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    background: '#121214',
    fontFamily: 'Inter, Arial, sans-serif',
  },
  content: {
    textAlign: 'center' as const,
    padding: '20px',
    borderRadius: '10px',
    background: 'linear-gradient(133.07deg, rgba(255, 255, 255, .2) 0%, rgba(255, 255, 255, 0) 50.52%, rgba(255, 255, 255, .2) 100%)',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.4)',
  },
  logo: {
    width: '150px',
    height: 'auto',
    marginBottom: '20px',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#f6f6f6',
    margin: '10px 0',
  },
  subtitle: {
    fontSize: '18px',
    color: 'rgba(230, 238, 255, .45)',
    margin: '0 0 20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '18px',
    fontWeight: 'bold',
    backgroundColor: '#9B51DF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
};

export default WelcomeScreen;

export interface IPostSocialMediaUrlBody {
  url: string;
  userId: string;
}

export interface IPostSocialMediaUrlResponse {
  onboardingId: number;
}

export function emptyIPostSocialMediaUrlResponse(): IPostSocialMediaUrlResponse {
  return {
    onboardingId: 0,
  };
}

export interface IPostMenuKey {
  onboardingId: number;
  keyId: string;
  bucketId: string;
}

export interface IPostMenuKeyResponse {
  onboardingId: number;
}

export function emptyIPostMenuKeyResponse(): IPostMenuKeyResponse {
  return {
    onboardingId: 0,
  };
}

export const PostInteraction = {
  QuoteNow: "QuoteNow",
  ReserveNow: "ReserveNow",
  AddToCartNow: "AddToCartNow",
  NavigateNow: "NavigateNow",
};

export const PostInteractionDescription = {
  [PostInteraction.QuoteNow]: "Cotizar ahora",
  [PostInteraction.ReserveNow]: "Reservar ahora",
  [PostInteraction.AddToCartNow]: "Agregar al carrito",
  [PostInteraction.NavigateNow]: "Navegar al lugar",
};

export interface IPostInteractionDic {
  [name: string]: string;
}

const keys = Object.keys(PostInteraction);
const values = Object.values(PostInteraction);

export const PostInteractionDic: IPostInteractionDic = Object.assign(
  {},
  ...keys.map((x, index) => ({ [x]: values[index] }))
);

import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { AppContext } from "../../../context/AppContext";
import { Colors } from "../../../themes";
import { SupplierService } from "../../../services";
import { IServiceDate, emptyIServiceDate } from "../../../interfaces/IProducts";
import {
  getReservationDates,
  reservationMessage,
} from "../../../utils/Functions";
import { DatesSelectorInput } from "./DatesSelectorInput";
import { CustomPhoneNumberInput } from "../../inputs/CustomPhoneNumberInput";
import { SimpleButton } from "../../Buttons/SimpleButton";
import { SimpleTextInput } from "../../inputs/SimpleTextInput";
import { ReservationInfo } from "../../../interfaces/IPosts";
import { ModalWrapper } from "../../atoms/wrappers/ModalWrapper";

type Props = {
  modalName: string;
  closeModal: () => void;
  sendReservationInfo: (val: ReservationInfo) => void;
};

const getPeopleArray = () => {
  return Array.from({ length: 20 }, (_, i) => {
    if (i === 0) return `1 Persona`;
    return `${i + 1} Personas`;
  });
};

export function ReservationModal(props: Props) {
  const { modalName, closeModal, sendReservationInfo } = props;
  const { state } = useContext(AppContext);
  const [selectedDate, setSelectedDate] = useState<IServiceDate>(
    emptyIServiceDate()
  );
  const numberOfPeople = getPeopleArray();
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [reservationDates, setReservationDates] = useState<IServiceDate[]>([]);
  const [selectedNumberOfPeople, setSelectedNumberOfPeople] = useState(
    numberOfPeople[0]
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validate, setValidate] = useState(false);
  const [name, setName] = useState("");

  const isValidPhone = phoneNumber.length === 10;
  const validName = name.length > 0;
  const validDate = selectedDate.displayDate !== "";
  const validPeriod = selectedPeriod !== "" && selectedPeriod !== undefined;
  const isValidForm = isValidPhone && validName && validDate && validPeriod;

  useEffect(() => {
    const getDates = async () => {
      const res = await SupplierService.getProductDays(
        state.supplierAccount.id
      );

      if (res.supplierId) {
        const dates = getReservationDates(res);
        setReservationDates(dates);
        setSelectedDate(dates[0]);
        setSelectedPeriod(dates[0].availableHours[0]);
      }
    };

    void getDates();
  }, [state.supplierAccount.id]);

  useEffect(() => {
    setSelectedPeriod(selectedDate.availableHours[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate.displayDate]);

  const handleClose = () => {
    closeModal();
  };

  const handleSendReservation = () => {
    setValidate(true);

    if (isValidForm) {
      const reservationInfo = {
        customerName: name,
        date: selectedDate.displayDate,
        numberOfPeople: selectedNumberOfPeople,
        phoneNumber: phoneNumber,
        supplierName: state.supplierAccount.contactName,
        time: selectedPeriod,
      };

      sendReservationInfo({
        date: selectedDate.displayDate,
        numberOfPeople: selectedNumberOfPeople,
        time: selectedPeriod,
      });

      const message = reservationMessage(reservationInfo);
      const link = `https://wa.me/${state.supplierAccount.contactPhone}?text=${message}`;

      const openLink = encodeURI(link);
      window.open(openLink, "_blank", "noreferrer");
    }
  };

  const Title = () => {
    return (
      <Box sx={styles.title}>
        <Box sx={styles.titleText}>{"Datos de tu reservación"}</Box>
      </Box>
    );
  };

  const DateField = () => {
    return (
      <Box sx={styles.inputContainer}>
        <DatesSelectorInput
          title={"Fecha"}
          placeholder={"Selecciona una fecha"}
          options={reservationDates}
          value={selectedDate}
          setValue={setSelectedDate}
          isError={validate && !validDate}
          errorMessage={"Fecha requerida"}
        />
      </Box>
    );
  };

  const SendButton = () => {
    return (
      <Box sx={styles.sendButtonContainer}>
        <SimpleButton
          text={"Continuar"}
          bgColor={Colors.PRIMARYSUPPLIER}
          color={Colors.WHITE}
          onClick={handleSendReservation}
        />
      </Box>
    );
  };

  return (
    <ModalWrapper
      modalVisible={modalName === "reservationModal"}
      onClose={handleClose}
    >
      <Box sx={styles.modalMainContainer}>
        <Title />
        <Box sx={styles.body}>
          <Box sx={styles.inputContainer}>
            <CustomPhoneNumberInput
              title={"Celular - número de 10 dígitos"}
              value={phoneNumber}
              setValue={setPhoneNumber}
              isError={validate && !isValidPhone}
              errorMessage={"Teléfono requerido"}
            />
          </Box>

          <DateField />

          <Box sx={styles.inputContainer}>
            <SimpleTextInput
              title={"Nombre para la reservación"}
              value={name}
              setValue={setName}
              isError={validate && !validName}
              errorMessage={"Nombre requerido"}
            />
          </Box>
          <SendButton />
        </Box>
      </Box>
    </ModalWrapper>
  );
}

const styles = {
  modalMainContainer: {
    display: "flex",
    flexDirection: { xs: "column" },
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: { xs: "5%", md: "3%" },
  },
  titleText: {
    color: Colors.BLACK,
    fontSize: "1.5rem",
    fontWeight: 600,
    textAlign: "center",
  },
  body: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    columnGap: "5%",
    marginBottom: "5%",
    width: { xs: "90%", sm: "80%", md: "70%" },
  },
  bodyText: {
    color: Colors.BLACK,
    textAlign: "center",
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  buttonsContainer: {
    width: "100%",
    display: "flex",
    height: "100%",
    justifyContent: "space-between",
    gap: "3%",
  },
  buttonContainer: {
    width: "50%",
  },
  imageContainer: {
    width: { xs: "40%", sm: "50%" },
  },
  productInfo: {
    width: { xs: "60%", sm: "50%" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingLeft: "1rem",
  },
  productNameText: {
    color: Colors.BLACK,
    fontSize: { xs: "1rem", sm: "1.2rem" },
    textAlign: "left",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 5,
    overflow: "hidden",
    maxheight: "70%",
  },
  costText: {
    marginTop: "4%",
  },
  addButtonContainer: {
    marginTop: "3rem",
    height: "2rem",
  },
  inputContainer: {
    marginBottom: "1rem",
  },
  sendButtonContainer: {
    height: "100%",
    width: "80%",
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    marginTop: "1rem",
  },
};

import Modal from "@material-ui/core/Modal";
import { useState } from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../themes";
import CustomTextInput from "../inputs/TextInput";
import CustomNumberInput from "../inputs/NumberInput";
import CustomSmallButton from "../Buttons/CustomSmallButton";
import {
  AffiliateBody,
  INewSupplierAccount,
} from "../../interfaces/ISuppliersProfile";
import { SupplierService } from "../../services";

const AffiliateModal = (props: {
  modalName: string;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setModalName: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
  supplier: INewSupplierAccount;
}) => {
  const [name, setName] = useState("");
  const [storeName, setStoreName] = useState("");
  const [error, setError] = useState(false);
  const validPhoneNumber = props.phoneNumber.length >= 10;
  const validStoreName = storeName.length >= 3;
  const validName = name.length >= 5;

  const onClose = () => {
    return props.handleClose();
  };

  const onSendData = async () => {
    setError(true);
    if (validPhoneNumber && validStoreName && validName) {
      const body: AffiliateBody = {
        contact_name: name,
        contact_phone: "+52" + props.phoneNumber,
        supplier_name: storeName,
        from_supplier_id: props.supplier.id,
        from_supplier_name: props.supplier.name,
      };
      const res = await SupplierService.postAffiliate(body);
      if (res.status === "success") {
        props.setModalName("AffiliateSuccess");
      }
    }
  };

  const ErrorNumberMessage = () => {
    if (error && !validPhoneNumber) {
      return <p style={styles.error}>{"El número debe tener 10 dígitos"}</p>;
    }
    return null;
  };

  const ErrorStoreMessage = () => {
    if (error && !validStoreName) {
      return <p style={styles.error}>{"Ingresa nombre de tu negocio"}</p>;
    }
    return null;
  };

  const ErrorNameMessage = () => {
    if (error && !validName) {
      return <p style={styles.error}>{"Ingresa tu nombre completo"}</p>;
    }
    return null;
  };

  const Buttons = () => {
    return (
      <Box sx={styles.buttonsContainer}>
        <Box sx={styles.button}>
          <CustomSmallButton
            text="Enviar solicitud"
            bgColor={Colors.PRIMARYSUPPLIER}
            color="primary"
            onClick={onSendData}
          />
        </Box>
      </Box>
    );
  };

  const Exit = () => {
    return (
      <div style={styles.exitContainer} onClick={onClose}>
        <div style={styles.exit}>x</div>
      </div>
    );
  };

  const Title = () => {
    return (
      <div style={styles.titleContainer}>
        <Box sx={styles.title}>{`¡Afilia tu negocio de comida!`}</Box>
      </div>
    );
  };

  const Description = () => {
    return (
      <div
        style={{
          textAlign: "center",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        <Box sx={styles.description}>
          <span> {"Usa el código: "}</span>
          <span style={styles.bold}>{"ML25MX"}</span>
          <span>
            {
              " al subir tu negocio de comida y recibe 25% de descuento por afiliarte."
            }
          </span>
        </Box>
      </div>
    );
  };

  const DescriptionList = () => {
    const data = [
      { name: "Recibe todos los beneficios:" },
      { name: "• Haz tu tienda en línea" },
      { name: "• 0% de comisión" },
      { name: "• Solicita repartidores para tu negocio" },
      { name: "• Contacto directo con tu cliente" },
    ];

    const listitems = data.map((data, index) => (
      <li key={index} style={styles.listContainer}>
        {data.name}
      </li>
    ));

    return (
      <Box sx={styles.list}>
        <ul style={styles.listItem}>{listitems}</ul>
      </Box>
    );
  };

  const DescriptionBottom = () => {
    return (
      <div
        style={{
          textAlign: "center",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        <Box sx={styles.descriptionBottom}>
          {
            "Recibe también un video de presentación, sesión de fotografías gratis y un kit para vender más."
          }
        </Box>
      </div>
    );
  };

  const TopBox = () => {
    return (
      <Box>
        <Exit />
        <Title />
        <Description />
        <DescriptionList />
        <DescriptionBottom />
      </Box>
    );
  };

  return (
    <Modal
      style={styles.modal}
      open={props.modalName === "Affiliate"}
      onClose={props.handleClose}
      closeAfterTransition
    >
      <>
        <Box
          sx={styles.boxModal}
          bgcolor={Colors.BACKGROUND}
          display={"flex"}
          flexDirection={"column"}
        >
          <div
            style={{
              justifyContent: "flex-end",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            <>
              <TopBox />
              <Box sx={styles.bigBox}>
                <Box sx={styles.box}>
                  <CustomTextInput
                    title="Nombre completo"
                    value={name}
                    setValue={setName}
                  />
                  <ErrorNameMessage />
                  <CustomNumberInput
                    title="Celular - número de 10 digitos"
                    value={props.phoneNumber}
                    setValue={props.setPhoneNumber}
                  />
                  <ErrorNumberMessage />
                  <CustomTextInput
                    title="Nombre de tu negocio"
                    value={storeName}
                    setValue={setStoreName}
                  />
                  <ErrorStoreMessage />
                  <Buttons />
                </Box>
              </Box>
            </>
          </div>
        </Box>
      </>
    </Modal>
  );
};

export default AffiliateModal;

const styles = {
  error: {
    paddingLeft: "5%",
    color: Colors.ERROR,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingTop: "15%",
    justifyContent: "center",
  },
  button: {
    width: "100%",
    paddingX: "6%",
  },
  exitContainer: {
    display: "flex",
    paddingRight: "3%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  exit: {
    cursor: "pointer",
    paddingTop: "1%",
    fontSize: 25,
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  title: {
    fontSize: {
      xs: "1.5rem",
      md: "32px",
    },
    fontWeight: "bold",
    lineHeight: "50px",
    color: Colors.PRIMARYSUPPLIER,
  },
  description: {
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingTop: {
      xs: "5%",
      md: "8%",
    },
    fontSize: {
      xs: "1.2rem",
      md: "1.5rem",
    },
    fontWeight: "500",
  },
  listContainer: {
    paddingBottom: "1%",
    paddingTop: "1%",
    paddingLeft: "5%",
    paddingRight: "5%",
    fontWeight: "500",
  },
  list: {
    paddingTop: "2%",
    fontSize: {
      xs: "1.2rem",
      md: "1.5rem",
    },
    textAlign: "center",
  },
  bold: {
    fontWeight: "600",
  },
  listItem: {
    padding: 0,
    listStyleType: "none",
  },
  descriptionBottom: {
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: {
      xs: "5%",
      md: "8%",
    },
    paddingBottom: "5%",
    fontSize: {
      xs: "1.2rem",
      md: "1.5rem",
    },
    fontWeight: "700",
  },
  bigBox: {
    display: "flex",
    flexDirection: "row",
  },
  modal: {
    display: "flex",
    justifyContent: "flex-end",
  },
  box: {
    width: "100%",
    paddingX: "12%",
  },
  boxModal: {
    width: {
      xs: "100%",
      md: "48%",
    },
  },
};
